import React, { useState } from 'react';
import { Input, Button } from 'antd';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import './SearchBar.css'; // You can include minimal custom CSS for layout adjustments

const { Search } = Input;

const SearchBar = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Toggle the expanded state
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Reset the search bar
  const handleCancel = () => {
    setIsExpanded(false);
  };

  return (
    <div className="search-bar-container">
      {isExpanded ? (
        <div className="search-bar-expanded">
          <Search
            placeholder="Search"
            allowClear
            enterButton="Search"
            size="large"
            style={{ width: '100%' }}
            onSearch={(value) => console.log(value)}
            autoFocus
          />
          <Button
            type="text"
            icon={<CloseOutlined />}
            onClick={handleCancel}
            className="cancel-button"
          />
        </div>
      ) : (
        <Button
          type="primary"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={toggleExpand}
          size="large"
        />
      )}
    </div>
  );
};

export default SearchBar;
