import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header/Header';
import CertifiedPawPros from '../components/CertifiedPawPros/CertifiedPawPros';
import Footer from '../components/Footer/Footer';
import Spacer from '../components/Spacer/Spacer';
import PartnerDisplay from '../components/PartnersDisplay/PartnersDisplay';
import CaregiverDisplay from '../components/CaregiverDisplay/CaregiverDisplay';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';
import CommunityCaregivers from '../components/CommunityCaregivers/CommunityCaregivers';
import { useBookingContext } from '../context/BookingContext';
import { Button } from 'antd';

const ProviderChoicePage = () => {
  const location = useLocation();
  const { service, bookingData } = location.state || {};
  const { updateBookingDetails } = useBookingContext();
  const [selectedProvider, setSelectedProvider] = useState(null); // State for selected provider
  const navigate = useNavigate();

  useEffect(() => {
    updateBookingDetails('providerDetails', bookingData); // Update provider details
  }, [bookingData, updateBookingDetails]);

  const handleProceedToBooking = () => {
    if (selectedProvider) {
      navigate('/provider-booking-display', {
        state: { provider: selectedProvider },
      });
    }
  };

  return (
    <div>
      <Header />
      <Spacer />
      <CertifiedPawPros />
      <Spacer />
      <PartnerDisplay selectedService={service} onProviderSelect={setSelectedProvider} /> {/* Pass setSelectedProvider */}
      <Spacer />
      <CommunityCaregivers />
      <Spacer />
      <CaregiverDisplay selectedService={service} onProviderSelect={setSelectedProvider} /> {/* Pass setSelectedProvider */}
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Button type="primary" onClick={handleProceedToBooking} disabled={!selectedProvider}>
          Proceed to Booking
        </Button>
      </div>
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default ProviderChoicePage;
