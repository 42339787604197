import React from 'react';
import "./PetChoiceBanner.css"; // 
import { Button } from "antd";
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import "../../assets/styles/global.css";
const { Text, Paragraph, Title } = Typography;

const PetChoiceBanner = () => {
  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
    <section className="cta-banner cd-padding-sm">
      <div className="cta-banner__grid">
        <div className="cta-banner__content">
          <Title level={2} className="cta-banner__title">Discover Our Pet Accessories</Title>
          <Paragraph className="cta-banner__description">Find the perfect accessories for your furry friend and enhance their happiness and well-being.</Paragraph>
          <div className="cta-banner__btns">
            <Button type="primary"><Link to="/shop" >Shop Pet Accessories</Link></Button>
            <Link to="/shop" className="cd-link cd-link--contrast-higher"><Text>Other Items</Text></Link>
          </div>
        </div>
        <figure>
          <img className="cta-banner__img cd-radius-md" src="https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116068/samples/animals/reindeer.jpg" alt="" />
        </figure>
      </div>
    </section>
    </section>
  );
};

export default PetChoiceBanner;
