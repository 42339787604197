import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import './BestSellers.css';
import "../../assets/styles/global.css";

const { Meta } = Card;

const BestSellers = () => {
  const [bestSellers, setBestSellers] = useState([]);

  useEffect(() => {
    const fetchBestSellers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/products/random?limit=6`);
        const data = await response.json();

        if (Array.isArray(data)) {
          setBestSellers(data);
        } else {
          console.error('Unexpected response data:', data);
          setBestSellers([]);
        }
      } catch (error) {
        console.error('Failed to fetch best sellers:', error);
        setBestSellers([]);
      }
    };

    fetchBestSellers();
  }, []);

  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
      <div className="best-seller-grid">
        <h2 className="best-seller-title">Most Purchased Items</h2>
        <Row gutter={[16, 16]}>
          {bestSellers.map((product, index) => (
            <Col key={index} xs={24} sm={12} md={8}>
              <Link to={`/product/${product._id}`}>
                <Card
                  hoverable
                  cover={<img alt={product.title} src={product.imageUrl} />}
                >
                  <Meta title={product.title} description={`$${product.price}`} />
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
};

export default BestSellers;
