// Login.jsx
import React, { useState } from 'react';
import "./Login.css"; // Import the external CSS file
import { Button } from "antd";
import { Link } from 'react-router-dom';
import { Typography, Input } from 'antd';
import "../../assets/styles/global.css";
import { UserOutlined, KeyOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';

const { Text, Title, Paragraph } = Typography;

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    await loginWithRedirect({
      redirectUri: window.location.origin,
      login_hint: email,
    });
  };

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <div className="lq2-text-component lq2-text-center lq2-margin-bottom-sm">
        <Title level={2}>Log in</Title>
        <Text>Sign in to access your account and manage your pet's needs seamlessly:</Text>
      </div>

      <div className="lq2-margin-bottom-sm">
        <Paragraph className="lq2-form-label lq2-margin-bottom-3xs" htmlFor="input-email">Email</Paragraph>
        <Input
          placeholder="Enter your email"
          prefix={<UserOutlined />}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="lq2-margin-bottom-sm">
        <div className="lq2-flex lq2-justify-between lq2-margin-bottom-3xs">
          <Paragraph className="lq2-form-label" htmlFor="input-password">Password</Paragraph>
          <span className="lq2-text-sm"><a href="#0">Forgot?</a></span>
        </div>

        <Input
          placeholder="Enter your password"
          prefix={<KeyOutlined />}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <div className="lq2-margin-bottom-sm">
        <Button type="primary" block htmlType="submit"><Text>Login</Text></Button>
      </div>

      <div className="lq2-text-center">
        <Text className="lq2-text-sm">Don't have an account? <Link to="/signup">Get started</Link></Text>
      </div>
    </form>
  );
};

export default Login;
