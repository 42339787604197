import React from 'react';
import { Col, Row, Card, Typography} from 'antd';
import { SmileOutlined, CheckCircleOutlined, CarOutlined, CustomerServiceOutlined } from '@ant-design/icons';
import './QualityAssurance.css';
import "../../assets/styles/global.css";

const { Title, Paragraph} = Typography;

const QualityAssurance = () => {
  const features = [
    {
      icon: <SmileOutlined style={{ fontSize: '2rem', color: '#1890ff' }} />,
      title: 'High Quality, Low Prices',
      description: 'Keep your pet happy and healthy with top-notch products at unbeatable prices.',
    },
    {
      icon: <CheckCircleOutlined style={{ fontSize: '2rem', color: '#52c41a' }} />,
      title: 'Hassle-Free Returns',
      description: 'Not satisfied with an item? Let us know and we’ll handle the rest.',
    },
    {
      icon: <CarOutlined style={{ fontSize: '2rem', color: '#fa8c16' }} />,
      title: 'Free Delivery with App',
      description: 'Enjoy free delivery on all orders via the Chewy mobile app for a limited time.',
    },
    {
      icon: <CustomerServiceOutlined style={{ fontSize: '2rem', color: '#eb2f96' }} />,
      title: '24/7 Support',
      description: 'Our pet experts are available around the clock via call, email, or chat.',
    },
  ];

  return (
    <div className="quality-assurance">
      <Row gutter={[16, 16]}>
        {features.map((feature, index) => (
          <Col key={index} xs={24} sm={12} md={6}>
            <Card className="quality-assurance-card" bordered={false} style={{ boxShadow: 'none' }}>
              <div className="quality-assurance-icon">{feature.icon}</div>
              <Title level={3}>{feature.title}</Title>
              <Paragraph>{feature.description}</Paragraph>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default QualityAssurance;
