import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';
import ArticlePage from '../components/ArticlePage/ArticlePage';
const ReadNewsPage = () => {
  return (
    <div>
      <Header />
      <ArticlePage />
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default ReadNewsPage;
