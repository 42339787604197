import React from "react";
import { Link } from "react-router-dom";
import "../../assets/styles/global.css";
import { Typography, Button } from "antd";
const { Title, Paragraph } = Typography;

export interface ServiceCategoryCardProps {
  title: string;
  description: string;
  imageUrl: string;
  altText: string;
  bookingLink: string;
}

export const ServiceCategoryCard = ({
  title,
  description,
  imageUrl,
  altText,
  bookingLink,
}: ServiceCategoryCardProps) => (
  <div className="card">
    <figure className="card__img-wrapper">
      <img width="150" height="150" src={imageUrl} alt={altText} />
    </figure>
    <div className="card__content">
      <Title level={2}>{title}</Title>
      <p className="card__description">
        <Paragraph>{description}</Paragraph>
      </p>
      <footer>
        <Link to={bookingLink}>
          <Button type="primary" shape="round">
            Explore
          </Button>
        </Link>
      </footer>
    </div>
  </div>
);

export default ServiceCategoryCard;
