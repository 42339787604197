import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import HeroBAPP from '../components/HeroBAPP/HeroBAPP';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';
import BappInfoComp from '../components/BappInfoComp/BappInfoComp';
import BappTestimonial from '../components/BappTestimonial/BappTestimonial';
import BappFeatures from '../components/BappFeatures/BappFeatures';
import HeroBappOne from '../components/HeroBappOne/HeroBappOne';
import BappBenefits from '../components/BappBenefits/BappBenefits';

const BecomeAPawPro: React.FC = () => {
  return (
    <div>
      <Header />
      <HeroBAPP />
      <BappBenefits />
      <HeroBappOne />
      <BappFeatures />
      <BappInfoComp />
      <BappTestimonial />
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default BecomeAPawPro;
