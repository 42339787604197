import { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  DatePicker,
  Select,
  Upload,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import dayjs from "dayjs";
import { Pet } from "../../types";

interface Props {
  isVisible: boolean;
  closeModal: () => void;
  addNewPet: (newPet: Pet) => void;
}

export const AddNewPetModal = ({ isVisible, closeModal, addNewPet }: Props) => {
  const { user } = useAuth0();
  const [fileList, setFileList] = useState<any[]>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!isVisible) {
      form.resetFields(); // Reset form on modal close
      setFileList([]); // Clear file list on modal close
    }
  }, [isVisible, form]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields(); // Wait for form validation

      if (!fileList.length) {
        message.error("Please upload a photo!");
        return;
      }

      if (!user || !user.sub) {
        message.error("User ID is missing. Please log in.");
        console.error("User ID is not available.");
        return;
      }

      const formData = new FormData();
      for (let key in values) {
        formData.append(key, values[key]);
      }
      if (fileList[0]?.originFileObj) {
        formData.append("photo", fileList[0].originFileObj); // Add the image file
      }
      formData.append("userId", user.sub);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/api/pets/pets`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      message.success("Pet added successfully!");
      addNewPet(response.data);
      closeModal(); // Close modal after successful submission
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error("Error adding the pet. Please try again.");
        console.error("API error:", error.response?.data || error.message);
      } else {
        message.error("Validation failed. Please correct the errors.");
        console.error("Validation error:", error);
      }
    }
  };

  const handleCancel = () => {
    closeModal();
  };

  const handleUploadChange = ({ fileList }: any) => {
    setFileList(fileList);
  };

  return (
    <Modal
      title="Add New Pet"
      open={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Add"
      cancelText="Cancel"
    >
      <Form
        form={form}
        layout="vertical"
        name="add_pet_form"
        initialValues={{
          species: "dog",
          gender: "male (neutered)",
        }}
      >
        <Form.Item
          name="photo"
          label="Photo"
          rules={[{ required: true, message: "Please upload a photo!" }]}
        >
          <Upload
            name="photo"
            listType="picture"
            fileList={fileList}
            beforeUpload={() => false} // Prevent automatic upload
            onChange={handleUploadChange}
            accept="image/*"
          >
            {fileList.length === 0 && (
              <Button icon={<UploadOutlined />}>Upload Photo</Button>
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input placeholder="Enter pet's name" />
        </Form.Item>

        <Form.Item
          name="birthday"
          label="Birthday"
          rules={[{ required: true, message: "Please select the birthday!" }]}
        >
          <DatePicker
            style={{ width: "100%" }}
            disabledDate={(current) => current && current > dayjs()} // Disable future dates
          />
        </Form.Item>

        <Form.Item
          name="species"
          label="Species"
          rules={[{ required: true, message: "Please select the species!" }]}
        >
          <Select
            options={[
              { label: "Bird", value: "bird" },
              { label: "Cat", value: "cat" },
              { label: "Dog", value: "dog" },
              { label: "Other", value: "other" },
            ]}
          />
        </Form.Item>

        <Form.Item
          name="gender"
          label="Gender"
          rules={[{ required: true, message: "Please select the gender!" }]}
        >
          <Select
            options={[
              { label: "Male (Neutered)", value: "male (neutered)" },
              { label: "Male (Unneutered)", value: "male (unneutered)" },
              { label: "Female (Spayed)", value: "female (spayed)" },
              { label: "Female (Unspayed)", value: "female (unspayed)" },
            ]}
          />
        </Form.Item>

        <Form.Item
          name="breed"
          label="Breed"
          rules={[{ required: true, message: "Please input the breed!" }]}
        >
          <Input placeholder="Enter breed" />
        </Form.Item>

        <Form.Item
          name="weight"
          label="Weight (lbs)"
          rules={[{ required: true, message: "Please input the weight!" }]}
        >
          <Input type="number" placeholder="Enter weight" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
