import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './JobMap.css';

// Coordinates for Ontario cities
const cityCoordinates: { [city: string]: { lat: number; lng: number } } = {
  Toronto: { lat: 43.65107, lng: -79.347015 },
  Ottawa: { lat: 45.421532, lng: -75.697189 },
  Mississauga: { lat: 43.589045, lng: -79.64412 },
  Hamilton: { lat: 43.2557, lng: -79.8711 },
  London: { lat: 42.9849, lng: -81.2453 },
  Brampton: { lat: 43.7315, lng: -79.7624 },
  Kitchener: { lat: 43.4516, lng: -80.4925 },
  Windsor: { lat: 42.3149, lng: -83.0364 }
};

// Interface for job data
interface Job {
  jobTitle: string;
  location: {
    city: string;
    stateOrProvince: string;
    country: string;
  };
  shortDescription: string;
}

const JobMap: React.FC = () => {
  const [jobs, setJobs] = useState<Job[]>([]);

  // Fetch job opportunities from the backend
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/careers/jobs`); // Replace with your API endpoint
        const data = await response.json();
        setJobs(data);
      } catch (err) {
        console.error('Failed to fetch jobs:', err);
      }
    };
    fetchJobs();
  }, []);

  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
      <div className="job-map-container">
        <MapContainer center={[43.65107, -79.347015]} zoom={6} className="map">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />

          {jobs.map((job, index) => {
            const city = job.location.city;
            const coordinates = cityCoordinates[city];

            if (!coordinates) return null;

            return (
              <Marker key={index} position={[coordinates.lat, coordinates.lng]}>
                <Popup>
                  <strong>{job.jobTitle}</strong><br />
                  {city}, {job.location.stateOrProvince}, {job.location.country}<br />
                  {job.shortDescription}
                </Popup>
              </Marker>
            );
          })}
        </MapContainer>
      </div>
    </section>
  );
};

export default JobMap;
