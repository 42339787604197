import React from 'react';
import Header from '../components/Header/Header';
import Socials from '../components/Socials/Socials';
import Footer from '../components/Footer/Footer';
import ContactInfo from '../components/ContactInfo/ContactInfo';
import Spacer from '../components/Spacer/Spacer';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';
const ContactUs = () => {
  return (
    <div>
      <Header />
      <ContactInfo />
      <Spacer />
      <Spacer />
      <Socials />
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default ContactUs;
