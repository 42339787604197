import React from "react";
import "./Testimonials.css"; // Make sure to import your CSS file here
import "../../assets/styles/global.css";
import { Typography } from "antd";
const { Paragraph } = Typography;

const Testimonials = () => {
  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
      <div className="ta0-margin-bottom-lg">
        
      </div>

      <div className="ta0-grid ta0-gap-sm">
        {/* Testimonial 1 */}
        <div className="ta0-bg-contrast-lower ta0-bg-opacity-30% ta0-radius-md ta0-padding-md ta0-flex@md ta0-flex-column@md ta0-col-4@md">
          <svg
            className="ta0-icon ta0-icon--xl ta0-color-contrast-low ta0-margin-bottom-2xs"
            aria-hidden="true"
            width="64"
            height="64"
            viewBox="0 0 64 64"
          >
            <polygon points="2 36 17 2 26 2 15 36 26 36 26 62 2 62 2 36" />
            <polygon points="38 36 53 2 62 2 51 36 62 36 62 62 38 62 38 36" />
          </svg>
          <blockquote className="ta0-line-height-md ta0-margin-bottom-md">
            <Paragraph>
            Paw Pros is a lifesaver! I've bought everything from toys to pet beds, and their grooming service is top-notch. It's a one-stop-shop for all my pet care needs!
            </Paragraph>
          </blockquote>
          <footer className="ta0-flex ta0-flex-wrap ta0-items-center ta0-margin-top-auto@md">
            <figure
              className="ta0-flex-shrink-0 ta0-margin-right-2xs"
              aria-hidden="true"
            >
              <img
                className="ta0-block ta0-width-lg ta0-height-lg ta0-radius-50% ta0-border ta0-border-2 ta0-border-bg"
                src="https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116068/samples/animals/reindeer.jpg"
                alt="Emily Ewing"
              />
            </figure>
            <cite className="ta0-text-sm">
              <strong>Emily Ewing</strong>
              <span className="ta0-block ta0-color-contrast-medium ta0-margin-top-4xs">
                Ottawa, ON
              </span>
            </cite>
          </footer>
        </div>

        {/* Testimonial 2 */}
        <div className="ta0-bg-contrast-lower ta0-bg-opacity-30% ta0-radius-md ta0-padding-md ta0-flex@md ta0-flex-column@md ta0-col-4@md">
          <svg
            className="ta0-icon ta0-icon--xl ta0-color-contrast-low ta0-margin-bottom-2xs"
            aria-hidden="true"
            width="64"
            height="64"
            viewBox="0 0 64 64"
          >
            <polygon points="2 36 17 2 26 2 15 36 26 36 26 62 2 62 2 36" />
            <polygon points="38 36 53 2 62 2 51 36 62 36 62 62 38 62 38 36" />
          </svg>
          <blockquote className="ta0-line-height-md ta0-margin-bottom-md">
          <Paragraph>
          I love how Paw Pros not only offers a wide range of pet accessories but also provides excellent grooming services. My dog always comes back looking and feeling fantastic!
            </Paragraph>
          </blockquote>
          <footer className="ta0-flex ta0-flex-wrap ta0-items-center ta0-margin-top-auto@md">
            <figure
              className="ta0-flex-shrink-0 ta0-margin-right-2xs"
              aria-hidden="true"
            >
              <img
                className="ta0-block ta0-width-lg ta0-height-lg ta0-radius-50% ta0-border ta0-border-2 ta0-border-bg"
                src="https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116068/samples/animals/reindeer.jpg"
                alt="James Powell"
              />
            </figure>
            <cite className="ta0-text-sm">
              <strong>James Powell</strong>
              <span className="ta0-block ta0-color-contrast-medium ta0-margin-top-4xs">
                Kingston, ON
              </span>
            </cite>
          </footer>
        </div>

        {/* Testimonial 3 */}
        <div className="ta0-bg-contrast-lower ta0-bg-opacity-30% ta0-radius-md ta0-padding-md ta0-flex@md ta0-flex-column@md ta0-col-4@md">
          <svg
            className="ta0-icon ta0-icon--xl ta0-color-contrast-low ta0-margin-bottom-2xs"
            aria-hidden="true"
            width="64"
            height="64"
            viewBox="0 0 64 64"
          >
            <polygon points="2 36 17 2 26 2 15 36 26 36 26 62 2 62 2 36" />
            <polygon points="38 36 53 2 62 2 51 36 62 36 62 62 38 62 38 36" />
          </svg>
          <blockquote className="ta0-line-height-md ta0-margin-bottom-md">
          <Paragraph>
          Paw Pros is a lifesaver! I've bought everything from toys to pet beds, and their grooming service is top-notch. It's a one-stop-shop for all my pet care needs!
            </Paragraph>
          </blockquote>
          <footer className="ta0-flex ta0-flex-wrap ta0-items-center ta0-margin-top-auto@md">
            <figure
              className="ta0-flex-shrink-0 ta0-margin-right-2xs"
              aria-hidden="true"
            >
              <img
                className="ta0-block ta0-width-lg ta0-height-lg ta0-radius-50% ta0-border ta0-border-2 ta0-border-bg"
                src="https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116068/samples/animals/reindeer.jpg"
                alt="Olivia Gribben"
              />
            </figure>
            <cite className="ta0-text-sm">
              <strong>Olivia Gribben</strong>
              <span className="ta0-block ta0-color-contrast-medium ta0-margin-top-4xs">
                Toronto, ON
              </span>
            </cite>
          </footer>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
