import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from 'antd';
import { TwitterOutlined, FacebookOutlined, InstagramOutlined } from '@ant-design/icons';
import './UserBanner.css';

const { Title, Paragraph } = Typography;

const UserBanner = () => {
  const { user, isAuthenticated } = useAuth0();

  return (
    <section>
      <div className="ae2-container ae2-max-width-adaptive-sm">
        {isAuthenticated && (
          <div className="author author--featured">
            <a href="#0" className="author__img-wrapper">
              <img src={user.picture || "https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116117/Logo_rsepw8.jpg"} alt="Authorpicture" />
            </a>
            <div className="author__content ae2-text-component">
              <Title className='userName' level={2}>{user.name}</Title>
              <Paragraph className='userEmail'>{user.email}</Paragraph>
            </div>
            <ul className="ae2-flex ae2-gap-xs ae2-flex-wrap ae2-justify-center">
              <li>
                <a href="#0" className="author__social">
                  <TwitterOutlined className="ae2-icon" />
                </a>
              </li>
              <li>
                <a href="#0" className="author__social">
                  <FacebookOutlined className="ae2-icon" />
                </a>
              </li>
              <li>
                <a href="#0" className="author__social">
                  <InstagramOutlined className="ae2-icon" />
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </section>
  );
};

export default UserBanner;
