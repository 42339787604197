import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import TrainPet from '../../components/TrainPet/TrainPet';
import { useNavigate } from 'react-router-dom';
import { useBookingContext } from '../../context/BookingContext'; // Use BookingContext

const PetTrainingPage: React.FC = () => {
  const { updateBookingDetails } = useBookingContext(); // Access the booking context
  const navigate = useNavigate(); // Use navigate to go to the PetChoicePage

  const handleChoosePetClick = (bookingData: any) => {
    // Store the booking data in the context
    updateBookingDetails('trainingType', bookingData.trainingType);
    updateBookingDetails('selectedDate', bookingData.selectedDate ? bookingData.selectedDate.toDate() : null);
    updateBookingDetails('selectedTime', bookingData.selectedTime ? bookingData.selectedTime.toDate() : null);
    updateBookingDetails('startDate', bookingData.startDate ? bookingData.startDate.toDate() : null);
    updateBookingDetails('dayOfWeek', bookingData.dayOfWeek);
    updateBookingDetails('recurrence', bookingData.recurrence);
    updateBookingDetails('pickupDate', bookingData.pickupDate ? bookingData.pickupDate.toDate() : null);
    updateBookingDetails('pickupTime', bookingData.pickupTime ? bookingData.pickupTime.toDate() : null);
    updateBookingDetails('recurrenceDates', bookingData.recurrenceDates);

    // Navigate to the PetChoicePage
    navigate('/choose-pet', {
      state: { service: 'Pet Training' }, // Pass service type in navigation state
    });
  };

  return (
    <div>
      <Header />
      <div style={{ padding: '20px' }}>
        <TrainPet onChoosePetClick={handleChoosePetClick} />  
      </div>
      <Footer />
    </div>
  );
};

export default PetTrainingPage;
