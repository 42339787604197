import React from 'react';
import Header from '../components/Header/Header';
import Login from '../components/Login/Login';
import Footer from '../components/Footer/Footer';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';
const LoginPage = () => {
  return (
    <div>
      <Header />
      <Login />
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default LoginPage;
