import React, { useState } from 'react';
import { Typography, Button, Modal, Form, Input, DatePicker, Select, notification, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import './PawFindFeat.css';
import '../../assets/styles/global.css';

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;

const PawFindFeat = () => {
  const { isAuthenticated, user, loginWithRedirect } = useAuth0();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [coordinates, setCoordinates] = useState(null); // Store coordinates here
  const navigate = useNavigate();

  // Options for the three main actions
  const options = [
    {
      title: 'Report A Lost Pet',
      description: [
        'Report your lost pet quickly and easily',
        'Free 15-day trial for premium features',
        'Access to community support',
        '1 free project to create a missing poster',
      ],
      button: 'Report Now',
      popular: true,
      onClick: () => handleReportPet(),
    },
    {
      title: 'Found a Lost Pet',
      description: [
        'Help reunite lost pets with their owners',
        'Submit found pet details easily',
        'Access to all features for free',
        '10 successful reunions guaranteed',
        'E-commerce integration for pet supplies',
        '24/7 support from our team',
      ],
      button: 'Submit Found Pet',
      onClick: () => handleFoundPet(),
    },
    {
      title: 'Animal Services in your Location',
      description: [
        'Find local animal services',
        'Access to all features for free',
        'Unlimited searches',
        'E-commerce integration for pet supplies',
        '24/7 support from our team',
        'Full security for your data',
      ],
      button: 'Find Services',
      popular: true,
      onClick: () => navigate('/paw-finder-map'), // Redirect to PawFinderMap
    },
  ];

  // Handle the report lost pet button click
  const handleReportPet = () => {
    if (isAuthenticated) {
      setIsModalVisible(true);
    } else {
      loginWithRedirect(); // Redirect to login if not authenticated
    }
  };

  // Handle the found pet button click
  const handleFoundPet = () => {
    if (isAuthenticated) {
      setIsModalVisible(true); // Show the modal for found pet
    } else {
      loginWithRedirect({
        appState: { returnTo: '/paw-finder-map' } // Redirect back to PawFinderMap after login
      });
    }
  };

  // Handle image upload changes
  const handleFileChange = ({ fileList }) => {
    if (fileList.length <= 4) {
      setFileList(fileList);
    } else {
      notification.warning({
        message: 'Limit Exceeded',
        description: 'You can upload up to 4 images only.',
      });
    }
  };

  // Function to upload images to Cloudinary
  const uploadImagesToCloudinary = async (fileList) => {
    const uploadedUrls = [];
    for (const file of fileList) {
      const formData = new FormData();
      formData.append('file', file.originFileObj);
      formData.append('upload_preset', 'ml_default'); // Use your upload preset

      const response = await fetch(
        `https://api.cloudinary.com/v1_1/daq4ywu5y/image/upload`, // Use your Cloudinary cloud name
        {
          method: 'POST',
          body: formData,
        }
      );

      const data = await response.json();
      uploadedUrls.push(data.secure_url);
    }
    return uploadedUrls;
  };

  // Function to get coordinates from an address using Google Maps API
  const getCoordinatesFromAddress = async (address) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=YOUR_API_KEY`
    );
    const data = await response.json();
    if (data.results && data.results[0]) {
      const location = data.results[0].geometry.location;
      return [location.lng, location.lat]; // Return [longitude, latitude]
    } else {
      throw new Error('Unable to find location coordinates');
    }
  };

  // Function to get the current location of the user
  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates([longitude, latitude]);
        },
        (error) => {
          notification.error({
            message: 'Error',
            description: 'Unable to retrieve your location. Please try again.',
          });
        }
      );
    } else {
      notification.error({
        message: 'Error',
        description: 'Geolocation is not supported by your browser.',
      });
    }
  };

  // Handle form submission
  const onFinish = async (values) => {
    try {
      const userId = user.sub; // Retrieve the userId from Auth0's user object
      const photoUrls = await uploadImagesToCloudinary(fileList);

      // Get coordinates from address or use current location if coordinates were set
      const fullAddress = `${values.address.street}, ${values.address.city}, ${values.address.provinceOrState}, ${values.address.country}`;
      const locationCoordinates = coordinates || await getCoordinatesFromAddress(fullAddress);

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/foundPet/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...values,
          userId: userId,
          photoUrls: photoUrls, // Save the URLs in the database
          location: {
            type: 'Point',
            coordinates: locationCoordinates,
          },
          createdAt: new Date(), // Set the createdAt timestamp
        }),
      });

      if (response.ok) {
        notification.success({
          message: 'Pet Reported',
          description: 'The found pet has been successfully reported.',
        });
        setIsModalVisible(false);
        setFileList([]); // Clear the file list after submission
        setCoordinates(null); // Reset coordinates
      } else {
        throw new Error('Failed to report found pet');
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'There was an error reporting the found pet. Please try again later.',
      });
    }
  };

  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
      <div className="p-table">
        <div className="pd6-grid pd6-gap-sm">
          {options.map((option, index) => (
            <div key={index} className={`p-table__item pd6-col-4@md ${option.popular ? 'p-table__item--popular' : ''}`}>
              <div className="pd6-margin-bottom-2xs">
                <Title level={4} className="p-table__title">
                  {option.title}
                </Title>
                {option.popular && <span className="p-table__badge">Popular</span>}
              </div>
              <div className="pd6-margin-bottom-md">
                <Paragraph>
                  {option.description.map((desc, descIndex) => (
                    <Text key={descIndex}>
                      {desc}
                      <br />
                    </Text>
                  ))}
                </Paragraph>
              </div>
              <div className="pd6-margin-top-auto">
                <Button type="primary" size="large" block onClick={option.onClick}>
                  {option.button}
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal for Reporting Lost or Found Pet */}
      <Modal
        title="Submit Found Pet"
        open={isModalVisible} // Use "open" instead of "visible"
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="name"
            label="Pet Name"
          >
            <Input placeholder="Enter the pet's name (optional)" />
          </Form.Item>
          <Form.Item
            name="species"
            label="Species"
            rules={[{ required: true, message: 'Please select the species!' }]}
          >
            <Select placeholder="Select species">
              <Option value="dog">Dog</Option>
              <Option value="cat">Cat</Option>
              <Option value="bird">Bird</Option>
              <Option value="other">Other</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="breed"
            label="Breed"
          >
            <Input placeholder="Enter breed (optional)" />
          </Form.Item>
          <Form.Item
            name="weight"
            label="Weight (kg)"
          >
            <Input type="number" placeholder="Enter weight (optional)" />
          </Form.Item>
          <Form.Item
            name="birthday"
            label="Birthday"
          >
            <DatePicker style={{ width: '100%' }} placeholder="Select birthday (optional)" />
          </Form.Item>
          <Form.Item
            name="address.street"
            label="Street"
            rules={[{ required: true, message: 'Please input the street!' }]}
          >
            <Input placeholder="Enter street" />
          </Form.Item>
          <Form.Item
            name="address.city"
            label="City"
            rules={[{ required: true, message: 'Please input the city!' }]}
          >
            <Input placeholder="Enter city" />
          </Form.Item>
          <Form.Item
            name="address.provinceOrState"
            label="Province/State"
            rules={[{ required: true, message: 'Please input the province/state!' }]}
          >
            <Input placeholder="Enter province/state" />
          </Form.Item>
          <Form.Item
            name="address.postalOrZipCode"
            label="Postal/Zip Code"
            rules={[{ required: true, message: 'Please input the postal/zip code!' }]}
          >
            <Input placeholder="Enter postal/zip code" />
          </Form.Item>
          <Form.Item
            name="address.country"
            label="Country"
            rules={[{ required: true, message: 'Please input the country!' }]}
          >
            <Input placeholder="Enter country" />
          </Form.Item>
          <Form.Item>
            <Button onClick={getCurrentLocation} type="default" block>
              Use Current Location
            </Button>
          </Form.Item>
          <Form.Item
            label="Upload Images"
            name="images"
            valuePropName="fileList"
            getValueFromEvent={(e) => e && e.fileList}
          >
            <Upload
              listType="picture-card"
              fileList={fileList}
              onChange={handleFileChange}
              beforeUpload={() => false} // Prevent automatic upload
            >
              {fileList.length < 4 && (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Button type="primary" htmlType="submit" block>
            Submit
          </Button>
        </Form>
      </Modal>
    </section>
  );
};

export default PawFindFeat;
