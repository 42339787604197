import React from 'react';
import './DialogFlowMessenger.css';
const DialogflowMessenger = () => {
  return (
    <df-messenger
      location="us-central1"
      project-id="PawSentials AI"
      agent-id="9da41d1b-7b51-401c-85bd-736cfb2006ba"
      language-code="en"
      max-query-length="-1">
      <df-messenger-chat-bubble
       chat-title="Paw Pros Support ">
      </df-messenger-chat-bubble>
    </df-messenger>
  );
};

export default DialogflowMessenger;
