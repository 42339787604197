import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import NewsArticles from '../components/NewsArticles/NewsArticles';
import Spacer from '../components/Spacer/Spacer';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';
const NewsPage = () => {
  return (
    <div>
      <Header />
      <Spacer />
      <NewsArticles />
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default NewsPage;
