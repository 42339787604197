import React, { useEffect, useState } from 'react';
import { Typography, Button, Modal } from 'antd';
import { Link } from 'react-router-dom';
const { Title, Text } = Typography;

const SuppCentOptions: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentCategory, setCurrentCategory] = useState<string>('');
  const [currentSubsections, setCurrentSubsections] = useState<string[]>([]);

  useEffect(() => {
    const link = document.createElement('link');
    link.href = "https://cdn.jsdelivr.net/gh/livecanvas-team/ninjabootstrap/dist/css/bootstrap.min.css";
    link.rel = "stylesheet";
    link.id = "bootstrap-css";
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const categories = [
    {
      name: 'Orders',
      description: 'Manage your orders, track shipments, and handle returns or exchanges.',
      buttonText: 'Manage Orders',
      subsections: ['Order Issue', 'Track Shipment', 'Cancel Order', 'Return/Exchange', 'Order Status', 'Shipping Fees', 'Payment Issues']
    },
    {
      name: 'Bookings',
      description: 'Handle your bookings, reschedule appointments, and view provider details.',
      buttonText: 'Manage Bookings',
      subsections: ['Booking Issue', 'Reschedule Booking', 'Cancel Booking', 'Refund for Booking', 'Modify Booking Details', 'Provider Information', 'Booking Status']
    },
    {
      name: 'Account Management',
      description: 'Update your personal information, change passwords, and manage security settings.',
      buttonText: 'Account Settings',
      subsections: ['Forgot Password', 'Account Settings', 'Update Personal Info', 'Deactivate Account', 'Login Issues', 'Payment Methods', 'Email Preferences']
    },
    {
      name: 'Payments & Billing',
      description: 'View and manage your payments, request refunds, and resolve billing issues.',
      buttonText: 'Payments Hub',
      subsections: ['Payment Failed', 'Refund Status', 'Billing Inquiry', 'Update Payment Information', 'Payment Methods Accepted', 'Invoice Request', 'Payment Disputes']
    },
    {
      name: 'Services',
      description: 'Explore service offerings, manage service ratings, and customize your experience.',
      buttonText: 'Manage Services',
      subsections: ['Service Availability', 'Service Customization', 'Provider Ratings', 'Service Quality Issues', 'Add-On Services', 'Pricing for Services', 'Service Packages']
    },
    {
      name: 'Technical Support',
      description: 'Resolve technical issues, report bugs, and request new features.',
      buttonText: 'Get Technical Support',
      subsections: ['Website Not Loading', 'Bug Reports', 'Feature Requests', 'Mobile App Issues', 'Browser Compatibility', 'Error Messages', 'Slow Website']
    }
  ];

  const showModal = (categoryName: string, subsections: string[]) => {
    setCurrentCategory(categoryName);
    setCurrentSubsections(subsections);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="container">
      <div className="row text-center">
        <div className="col-md-12">
          <div className="lc-block">
            <Text className="small mt-4 d-block">SUPPORT CENTER</Text>
            <Title level={2} className="mb-0"><b>How can we help you today?</b></Title>
            <Text> Find solutions, manage your account, and resolve issues easily.</Text>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        {categories.map((category, index) => (
          <div key={index} className="col-md-4">
            <div className="p-lg-5 p-4 shadow">
              <div className="lc-block mb-4">
                <img alt="" className="img-fluid" src={`https://cdn.livecanvas.com/media/svg/undraw/${index % 2 === 0 ? 'selfie' : 'product-teardown'}.svg`} style={{ height: '10vh' }} />
                <Title level={4} className="my-3">{category.name}</Title>
                <Text>{category.description}</Text>
                <Button
                  type="primary"
                  ghost
                  className="mt-3"
                  onClick={() => showModal(category.name, category.subsections)}
                >
                  {category.buttonText}
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Modal title={currentCategory} visible={isModalVisible} onCancel={handleCancel} footer={null}>
        {currentSubsections.map((subsection, index) => (
          <div key={index}>
            <Link to="/support-center">{subsection}</Link>
          </div>
        ))}
      </Modal>
    </div>
  );
};

export default SuppCentOptions;
