import React, { useState } from 'react';
import { Button } from 'antd';
import './SubNavigation.css';
import '../../assets/styles/global.css';

const SubNavigation = ({ activeCategory, setActiveCategory }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSubNav = () => {
    setIsOpen(!isOpen);
  };

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  return (
    <div className="si6-bg-dark">
      <div className="si6-container si6-max-width-lg">
        <div className={`subnav js-subnav ${isOpen ? 'subnav--open' : ''}`}>
          <button
            className="si6-btn si6-btn--subtle si6-margin-y-sm subnav__control js-subnav__control"
            onClick={toggleSubNav}
            aria-expanded={isOpen ? 'true' : 'false'}
          >
            <span>Show Categories</span>
            <svg className="si6-icon si6-icon--2xs si6-margin-left-2xs" aria-hidden="true" viewBox="0 0 12 12">
              <polyline points="0.5 3.5 6 9.5 11.5 3.5" fill="none" strokeWidth="1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></polyline>
            </svg>
          </button>

          <div className="subnav__wrapper js-subnav__wrapper">
            <nav className="subnav__nav si6-justify-center">
              <button className="subnav__close-btn js-subnav__close-btn js-tab-focus" aria-label="Close navigation" onClick={toggleSubNav}>
                <svg className="si6-icon" viewBox="0 0 16 16">
                  <g strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
                    <line x1="13.5" y1="2.5" x2="2.5" y2="13.5"></line>
                    <line x1="2.5" y1="2.5" x2="13.5" y2="13.5"></line>
                  </g>
                </svg>
              </button>

              <ul className="subnav__list">
                <li className="subnav__item">
                  <a
                    href="#0"
                    className="subnav__link"
                    aria-current={activeCategory === 'food' ? 'page' : undefined}
                    onClick={() => handleCategoryClick('food')}
                  >
                    <Button type="text">Food</Button>
                  </a>
                </li>
                <li className="subnav__item">
                  <a
                    href="#0"
                    className="subnav__link"
                    aria-current={activeCategory === 'supplies' ? 'page' : undefined}
                    onClick={() => handleCategoryClick('supplies')}
                  >
                    <Button type="text">Supplies</Button>
                  </a>
                </li>
                <li className="subnav__item">
                  <a
                    href="#0"
                    className="subnav__link"
                    aria-current={activeCategory === 'accessories' ? 'page' : undefined}
                    onClick={() => handleCategoryClick('accessories')}
                  >
                    <Button type="text">Accessories</Button>
                  </a>
                </li>
                <li className="subnav__item">
                  <a
                    href="#0"
                    className="subnav__link"
                    aria-current={activeCategory === 'basic essentials' ? 'page' : undefined}
                    onClick={() => handleCategoryClick('basic essentials')}
                  >
                    <Button type="text">Basic Essentials</Button>
                  </a>
                </li>
                <li className="subnav__item">
                  <a
                    href="#0"
                    className="subnav__link"
                    aria-current={activeCategory === 'more' ? 'page' : undefined}
                    onClick={() => handleCategoryClick('more')}
                  >
                    <Button type="text">More</Button>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubNavigation;
