import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Spacer from '../components/Spacer/Spacer';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';
import PawFinderMap from '../components/PawFinderMap/PawFinderMap';
const PawFinderMapPage = () => {
  return (
    <div>
      <Header />
      <Spacer />
      <section className="ta0-container ta0-max-width-adaptive-lg">
      <PawFinderMap />
      </section>
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default PawFinderMapPage;
