// CommunityCaregivers.jsx
import React from 'react';
import './CommunityCaregivers.css'
import { Typography } from 'antd';
import { Link } from 'react-router-dom';


const { Text, Title } = Typography;

const CommunityCaregivers = () => {
  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
    <Link to="/community-caregivers" className="banner banner--invert" aria-label="Shop now">
      <div className="banner__grid">
        <div aria-hidden="true">
          <figure className="banner__figure" style={{ backgroundImage: `url(${'https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116068/samples/animals/reindeer.jpg'})` }}></figure>
        </div>

        <div className="banner__text">
          <Title level={2}>Community Caregivers</Title>
          
          <Text className="banner__link-wrapper">
            <Text strong >Dedicated individuals and non-registered pet caregivers offering services through the Paw Pros platform. These community caregivers are passionate about pets and provide personalized care directly to your doorstep. They embody the spirit of community and offer flexible and caring pet services tailored to your needs.</Text>
          </Text>
        </div>
      </div>
    </Link>
    </section>
  );
};

export default CommunityCaregivers;
