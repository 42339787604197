import React, { useEffect } from 'react';
import { initializeStackCards } from './stackCards';
import './CommunityHero.css';

const CommunityHero: React.FC = () => {
  useEffect(() => {
    initializeStackCards();
  }, []);

  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
      <ul className="stack-cards js-stack-cards">
        <li className="stack-cards__item sg0-bg sg0-radius-lg sg0-shadow-md sg0-overflow-hidden js-stack-cards__item">
          <img
            src="https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116073/samples/animals/three-dogs.jpg"
            alt="Imagedescription"
          />
        </li>
        <li className="stack-cards__item sg0-bg sg0-radius-lg sg0-shadow-md sg0-overflow-hidden js-stack-cards__item">
          <img
            src="https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587567/2_gpusbz.png"
            alt="Imagedescription"
          />
        </li>
        <li className="stack-cards__item sg0-bg sg0-radius-lg sg0-shadow-md sg0-overflow-hidden js-stack-cards__item">
          <img
            src="https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587568/5_yt1zif.png"
            alt="Imagedescription"
          />
        </li>
      </ul>
    </section>
  );
};

export default CommunityHero;
