import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import './NewsArticles.css';
import '../../assets/styles/global.css';
import { Typography, Pagination } from 'antd';
import axios from 'axios';

const { Text, Title, Paragraph } = Typography;

const NewsArticles = () => {
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(3); // Number of articles per page
  const [totalArticles, setTotalArticles] = useState(0);

  const fetchArticles = useCallback(async () => {
    try {
      console.log('Fetching articles');
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/api/news`);
      console.log('Articles fetched:', response.data);

      if (response.data.length > 0) {
        const paginatedArticles = [];
        for (let i = 0; i < response.data.length; i += pageSize) {
          paginatedArticles.push(response.data.slice(i, i + pageSize));
        }
        setArticles(paginatedArticles);
        setTotalArticles(response.data.length);
      } else {
        console.warn('No articles found in the response:', response.data);
        setArticles([]);
        setTotalArticles(0);
      }
    } catch (error) {
      console.error('There was an error fetching the articles!', error);
      setArticles([]);
      setTotalArticles(0);
    }
  }, [pageSize]);

  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <section className="articles-v3">
      <div className="cd-container cd-max-width-adaptive-lg">
        <ul className="articles-v3__list-grid">
          {articles.length > 0 ? (
            articles[currentPage - 1].map((article) => (
              <li key={article._id} className="articles-v3__item-grid">
                <Link to={`/read-news/${article._id}`} className="articles-v3__img-wrapper">
                  <img src={article.image1Link} alt="Imagedescription" />
                </Link>
                <div>
                  <Title level={2} className="articles-v3__headline">
                    <Link to={`/read-news/${article._id}`}>{article.mainTitle}</Link>
                  </Title>
                  <Paragraph className="articles-v3__description">{article.text1}</Paragraph>
                  <div className="articles-v3__author">
                    <a href="#0" className="articles-v3__author-img">
                      <img src={article.authorImg} alt="Authorpicture" />
                    </a>
                    <div className="articles-v3__author-info">
                      <Text strong>
                        <a href="#0" className="articles-v3__author-name" rel="author">
                          {article.authorName}
                        </a>
                      </Text>
                      <p className="articles-v3__date">
                        <Text>
                          <time>{new Date(article.publishingDate).toLocaleDateString()}</time>, &mdash; 5 min read
                        </Text>
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <li>No articles found</li>
          )}
        </ul>
        <div className="pagination-container">
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalArticles}
            onChange={onPageChange}
            className="articles-pagination"
          />
        </div>
      </div>
    </section>
  );
};

export default NewsArticles;
