import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Card, Button, Modal, Form, Input, Select, DatePicker, notification, Typography } from 'antd';

const { Option } = Select;
const { Text } = Typography;

const UserInfo = () => {
  const { user, isAuthenticated } = useAuth0();
  const [userInfo, setUserInfo] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (isAuthenticated && user) {
      const fetchUserInfo = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/users/${user.sub}`);
          if (response.ok) {
            const data = await response.json();
            setUserInfo(data);
          } else if (response.status === 404) {
            setUserInfo(null); // No user info found, this is normal for new users
          } else {
            throw new Error('Unexpected response');
          }
        } catch (error) {
          console.error('Error fetching user info:', error);
          notification.error({ message: 'Error', description: 'Unable to fetch user information.' });
        }
      };

      fetchUserInfo();
    }
  }, [isAuthenticated, user]);

  const handleButtonClick = () => {
    setIsEditing(!!userInfo); // Set to true if editing existing info, false if adding new info
    setIsModalVisible(true);
  };

  const handleFormSubmit = async (values) => {
    const url = userInfo ? `${process.env.REACT_APP_BACKEND_URI}/api/users/${user.sub}` : `${process.env.REACT_APP_BACKEND_URI}/api/users`;
    const method = userInfo ? 'PUT' : 'POST';

    try {
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...values, userId: user.sub }),
      });

      if (response.ok) {
        notification.success({
          message: 'Success',
          description: `User information ${userInfo ? 'updated' : 'added'} successfully.`,
        });
        setIsModalVisible(false);

        // Refetch the user info after update
        const fetchUpdatedUserInfo = async () => {
          try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/users/${user.sub}`);
            if (response.ok) {
              const data = await response.json();
              setUserInfo(data);
            } else {
              setUserInfo(null); // No user info found
            }
          } catch (error) {
            console.error('Error fetching user info:', error);
            notification.error({ message: 'Error', description: 'Unable to fetch user information.' });
          }
        };
        fetchUpdatedUserInfo();
      } else {
        throw new Error('Failed to save user information');
      }
    } catch (error) {
      console.error('Error saving user info:', error);
      notification.error({ message: 'Error', description: 'Failed to save user information.' });
    }
  };

  return (
    <Card title="User Info">
      {isAuthenticated && user ? (
        <>
          <p>Name: {user.name}</p>
          <p>Email: {user.email}</p>

          {userInfo ? (
            <>
              <p>----------------------</p>
              <p>Detailed Information</p>
              <p>----------------------</p>
              <p><Text strong>Title:</Text> {userInfo.title}</p>
              <p><Text strong >Gender:</Text> {userInfo.gender}</p>
              <p><Text strong>First Name:</Text> {userInfo.firstName}</p>
              <p><Text strong>Last Name:</Text> {userInfo.lastName}</p>
              <p><Text strong>Country:</Text> {userInfo.country}</p>
              <p>Date of Birth: {new Date(userInfo.dateOfBirth).toLocaleDateString()}</p>
              <p>----------------------</p>
              <Button type="primary" onClick={handleButtonClick}>
                Edit User Information
              </Button>
              
            </>
          ) : (
            <Button type="primary" onClick={handleButtonClick}>
              Enter User Information
            </Button>
          )}
        </>
      ) : (
        <p>Loading...</p>
      )}

      {/* Modal for Adding/Editing User Information */}
      <Modal
        title={isEditing ? 'Edit User Information' : 'Enter User Information'}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form layout="vertical" onFinish={handleFormSubmit} initialValues={userInfo || {}}>
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: 'Please select a title!' }]}
          >
            <Select placeholder="Select title">
              <Option value="Mr">Mr</Option>
              <Option value="Mrs">Mrs</Option>
              <Option value="Miss">Miss</Option>
              <Option value="Ms">Ms</Option>
              <Option value="Dr">Dr</Option>
              <Option value="Other">Other</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="gender"
            label="Gender"
            rules={[{ required: true, message: 'Please select a gender!' }]}
          >
            <Select placeholder="Select gender">
              <Option value="male">Male</Option>
              <Option value="female">Female</Option>
              <Option value="other">Other</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: 'Please enter your first name!' }]}
          >
            <Input placeholder="Enter first name" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: 'Please enter your last name!' }]}
          >
            <Input placeholder="Enter last name" />
          </Form.Item>
          <Form.Item
            name="country"
            label="Country"
            rules={[{ required: true, message: 'Please enter your country!' }]}
          >
            <Input placeholder="Enter country" />
          </Form.Item>
          <Form.Item
            name="dateOfBirth"
            label="Date of Birth"
            rules={[{ required: true, message: 'Please select your date of birth!' }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Button type="primary" htmlType="submit" block>
            {isEditing ? 'Update' : 'Submit'}
          </Button>
        </Form>
      </Modal>
    </Card>
  );
};

export default UserInfo;
