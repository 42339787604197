import React, { useContext, useEffect, useState } from "react";
import "./CartItems.css";

import { ShopContext } from "../../context/ShopContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from 'react-router-dom';
import {Button, Typography} from 'antd';

const { Title, Text  } = Typography;
const CartItems = () => {
  const { isAuthenticated, user } = useAuth0();
  const { removeFromCart } = useContext(ShopContext);
  const [cartProducts, setCartProducts] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const calculateSubtotal = () => {
      let total = 0;
      cartProducts.forEach((product) => {
        if (product.cartItem) {
          total += product.cartItem.price * product.quantity;
        }
      });
      setSubtotal(total);
    };

    calculateSubtotal();
  }, [cartProducts]);


  const fetchCartItems = async (userId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/api/cart/find/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to fetch cart");
      }
  
      const cartData = await response.json();
  
      // Check if products array exists in the cartData
      if (cartData && cartData.products && Array.isArray(cartData.products)) {
        return cartData.products;  // Return the products array
      } else {
        throw new Error("Cart data does not contain products array");
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
      return [];  // Return empty array in case of error
    }
  };
  

  const fetchProductDetails = async (productId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/api/products/${productId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch product details");
      }
      const productDetails = await response.json();
      return productDetails;
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  useEffect(() => {
    const fetchCartData = async () => {
      if (isAuthenticated && user) {
        const userId = user.sub;
        const cartData = await fetchCartItems(userId);
  
        if (cartData && cartData.length > 0) {
          const products = await Promise.all(
            cartData.map(async (product) => {
              if (product.cartItem) {
                const productDetails = await fetchProductDetails(
                  product.cartItem._id
                );
                return {
                  ...product,
                  cartItem: productDetails,
                };
              }
              return product;
            })
          );
          setCartProducts(products);
  
          const lineItems = products.map((product) => ({
            price: product.cartItem ? product.cartItem.stripe_price_id : null,
            quantity: product.quantity,
          }));
  
          storeLineItemsToServer(userId, lineItems);
        }
      }
    };
  
    fetchCartData();
  }, [isAuthenticated, user]);
  
  
  
  const storeLineItemsToServer = async (userId, lineItems) => {
    if (!userId || !lineItems.length) {
      console.error("UserId or LineItems missing");
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/lineItems/${userId}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("auth-token"),
        },
        body: JSON.stringify({ userId, lineItems }),
      });
  
      if (!response.ok) {
        throw new Error("Failed to store line items");
      }
    } catch (error) {
      console.error("Error storing line items:", error);
    }
  };
  

  const handleProceedToShipping = () => {
    window.location.href = "/shipping";
  };

  const renderProceedToShippingButton = () => {
    if (location.pathname === "/cart") {
      return <Button onClick={handleProceedToShipping}>PROCEED TO SHIPPING</Button>;
    }
    return null;
  };

  return (
    <div className="cartitems">
      <div className="cartitems-format-main">
        <Text strong>Products</Text>
        <Text strong>Title</Text>
        <Text strong>Price</Text>
        <Text strong>Quantity</Text>
        <Text strong>Total</Text>
        <Text strong>Remove</Text>
      </div>
      <hr />
      {cartProducts.map((product) => (
        <div key={product._id}>
          <div className="cartitems-format-main cartitems-format">
            {product.cartItem && (
              <>
                <img
                  className="cartitems-product-icon"
                  src={product.cartItem.imageUrl}
                  alt=""
                />
                <p className="cartitems-product-title">
                  {product.cartItem.title}
                </p>
                <p>${product.cartItem.price}</p>
              </>
            )}
            <button className="cartitems-quantity">{product.quantity}</button>
            {product.cartItem && (
              <p>${product.cartItem.price * product.quantity}</p>
            )}
            <img
              onClick={() => removeFromCart(product._id)}
              className="cartitems-remove-icon"
              src='https://img.icons8.com/ios-filled/100/multiply.png'
              alt=""
            />
          </div>
          <hr />
        </div>
      ))}
      <div className="cartitems-down">
        <div className="cartitems-total">
          <Title>Cart Totals</Title>
          <div>
            <div className="cartitems-total-item">
              <Title level={5}>Subtotal</Title>
              <p>${subtotal.toFixed(2)}</p>
            </div>
            <hr />
            <div className="cartitems-total-item">
              <Title level={5}>Shipping Fee</Title>
              <p>TBD</p>
            </div>
            <hr />
            <div className="cartitems-total-item">
              <Title level={3}>Total</Title>
              <h3>${subtotal.toFixed(2)}</h3>
            </div>
          </div>
          {renderProceedToShippingButton()}
        </div>
        <div className="cartitems-promocode">
          <p>If you have a promo code, Enter it here</p>
          <div className="cartitems-promobox">
            <input type="text" placeholder="promo code" />
            <Button>Submit</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItems;
