import React, { useEffect } from 'react';

import './PawFinderHero.css'; // Make sure this CSS file is correctly set up in your project
import '../../assets/styles/global.css'

const PawFinderHero = () => {
  useEffect(() => {
    class ParallaxImg {
      constructor(element, rotationLevel) {
        this.element = element;
        this.figure = this.element.getElementsByClassName('js-parallax__wrapper')[0];
        this.imgs = this.element.getElementsByClassName('js-parallax__item');
        this.maxRotation = rotationLevel || 2;
        this.scale = 1;
        this.animating = false;
        this.initParallax();
        this.initParallaxEvents();
      }

      initParallax() {
        this.count = 0;
        window.requestAnimationFrame(this.checkImageLoaded.bind(this));
        for (let i = 0; i < this.imgs.length; i++) {
          let loaded = false;
          if (this.imgs[i].tagName.toLowerCase() !== 'img') {
            this.count++;
            continue;
          }
          this.imgs[i].addEventListener('load', () => {
            if (loaded) return;
            this.count++;
          });
          if (this.imgs[i].complete && !loaded) {
            loaded = true;
            this.count++;
          }
        }
        for (let i = 0; i < this.imgs.length; i++) {
          if (this.imgs[i].getAttribute('data-parallax-distance')) {
            this.imgs[i].style.transform = 'translateZ(' + parseInt(this.imgs[i].getAttribute('data-parallax-distance')) + 'px)';
          }
        }
      }

      checkImageLoaded() {
        if (this.count >= this.imgs.length) {
          this.initScale();
          if (this.loaded) {
            window.cancelAnimationFrame(this.loaded);
            this.loaded = false;
          }
        } else {
          this.loaded = window.requestAnimationFrame(this.checkImageLoaded.bind(this));
        }
      }

      initScale() {
        const maxImgResize = this.getMaxScale();
        this.scale = maxImgResize / (Math.sin(Math.PI / 2 - this.maxRotation * Math.PI / 180));
        this.figure.style.transform = 'scale(' + this.scale + ')';
        this.element.classList.add('parallax--loaded');
      }

      getMaxScale() {
        let minWidth = 0;
        let maxWidth = 0;
        for (let i = 0; i < this.imgs.length; i++) {
          const width = this.imgs[i].getBoundingClientRect().width;
          if (width < minWidth || i === 0) minWidth = width;
          if (width > maxWidth || i === 0) maxWidth = width;
        }
        let scale = Math.ceil((10 * maxWidth) / minWidth) / 10;
        if (scale < 1.1) scale = 1.1;
        return scale;
      }

      initParallaxEvents() {
        this.element.addEventListener('mousemove', (event) => {
          if (this.animating) return;
          this.animating = true;
          window.requestAnimationFrame(this.moveImage.bind(this, event));
        });
      }

      moveImage(event) {
        const wrapperPosition = this.element.getBoundingClientRect();
        let rotateY = (2 * this.maxRotation / wrapperPosition.width) * (wrapperPosition.left - event.clientX + wrapperPosition.width / 2);
        let rotateX = (2 * this.maxRotation / wrapperPosition.height) * (event.clientY - wrapperPosition.top - wrapperPosition.height / 2);

        if (rotateY > this.maxRotation) rotateY = this.maxRotation;
        if (rotateY < -this.maxRotation) rotateY = -this.maxRotation;
        if (rotateX > this.maxRotation) rotateX = this.maxRotation;
        if (rotateX < -this.maxRotation) rotateX = -this.maxRotation;
        this.figure.style.transform = 'scale(' + this.scale + ') rotateX(' + rotateX + 'deg) rotateY(' + rotateY + 'deg)';
        this.animating = false;
      }
    }

    const parallaxImgs = document.getElementsByClassName('js-parallax');
    if (parallaxImgs.length > 0) {
      for (let i = 0; i < parallaxImgs.length; i++) {
        const rotationLevel = parallaxImgs[i].getAttribute('data-perspective');
        new ParallaxImg(parallaxImgs[i], rotationLevel);
      }
    }
  }, []);

  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
    <div className="parallax js-parallax">
      
      <figure className="parallax__wrapper js-parallax__wrapper">
        <img
          className="parallax__item js-parallax__item"
          src="https://d3ae4wrz2ljkbq.cloudfront.net/components/parallax-img-1.svg"
          alt="Imagedescription"
        />
        <img
          className="parallax__item js-parallax__item"
          src="https://d3ae4wrz2ljkbq.cloudfront.net/components/parallax-img-2.svg"
          data-parallax-distance="100"
          aria-hidden="true"
          alt="Imagedescription"
        />
        <img
          className="parallax__item js-parallax__item"
          src="https://d3ae4wrz2ljkbq.cloudfront.net/components/parallax-img-3.svg"
          data-parallax-distance="200"
          aria-hidden="true"
          alt="Imagedescription"
        />
        <img
          className="parallax__item js-parallax__item"
          src="https://d3ae4wrz2ljkbq.cloudfront.net/components/parallax-img-4.svg"
          data-parallax-distance="300"
          aria-hidden="true"
          alt="Imagedescription"
        />
      </figure>
    </div>
    </section>
  );
};

export default PawFinderHero;
