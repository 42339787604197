import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Typography, Row, Col, Card, Pagination, Modal, Select, Button, Carousel } from 'antd';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './PawFinderMap.css';
import Spacer from '../Spacer/Spacer';

const { Title, Text, Link } = Typography;
const { Option } = Select;

const countryStateData = {
  Canada: ['Ontario', 'Quebec', 'British Columbia', 'Alberta'],
  USA: ['California', 'New York', 'Texas', 'Florida'],
  // Add more countries and states/provinces as needed
};

const PawFinderMap = () => {
  const [shelters, setShelters] = useState([]);
  const [pets, setPets] = useState([]);
  const [currentShelterPage, setCurrentShelterPage] = useState(1);
  const [currentPetPage, setCurrentPetPage] = useState(1);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const sheltersPerPage = 9;
  const petsPerPage = 9;
  
  const mapRef = useRef(null);

  useEffect(() => {
    const fetchShelters = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/shelter/shelters`);
        const data = await response.json();
        setShelters(data);
      } catch (error) {
        console.error('Failed to fetch shelters:', error);
      }
    };

    const fetchPets = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/lostPet/lostPets`);
        const data = await response.json();
        setPets(data);
      } catch (error) {
        console.error('Failed to fetch pets:', error);
      }
    };

    fetchShelters();
    fetchPets();
  }, []);

  const shelterIcon = new L.Icon({
    iconUrl: 'https://img.icons8.com/ios-filled/50/228BE6/marker.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    shadowSize: [41, 41],
  });

  const petIcon = new L.Icon({
    iconUrl: 'https://img.icons8.com/color/48/marker--v1.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    shadowSize: [41, 41],
  });

  const handleCardClick = (location, data) => {
    if (mapRef.current) {
      mapRef.current.setView(location, 15);
    }
    setSelectedLocation(data);
    setIsModalVisible(true);
  };

  const handleCountryChange = (value) => {
    setCountry(value);
    setState(''); // Reset state when country changes
  };

  const handleFilter = () => {
    const filteredPets = pets.filter(pet => 
      (country === '' || pet.address.country === country) &&
      (state === '' || pet.address.provinceOrState === state)
    );
    const filteredShelters = shelters.filter(shelter =>
      (country === '' || shelter.address.country === country) &&
      (state === '' || shelter.address.provinceOrState === state)
    );
    setPets(filteredPets);
    setShelters(filteredShelters);
  };

  return (
    <div className="pawfinder-container">
      <div className="filter-bar" style={{ marginBottom: '20px' }}>
        <Select
          style={{ width: 200, marginRight: '10px' }}
          placeholder="Select Country"
          onChange={handleCountryChange}
          value={country}
        >
          {Object.keys(countryStateData).map((country) => (
            <Option key={country} value={country}>{country}</Option>
          ))}
        </Select>
        <Select
          style={{ width: 200, marginRight: '10px' }}
          placeholder="Select State/Province"
          onChange={value => setState(value)}
          value={state}
          disabled={!country}
        >
          {country && countryStateData[country].map((state) => (
            <Option key={state} value={state}>{state}</Option>
          ))}
        </Select>
        <Button type="primary" onClick={handleFilter}>Filter</Button>
      </div>

      <div className="map-container">
        <MapContainer
          center={[45.4215, -75.6972]}
          zoom={7}
          style={{ height: '400px', width: '100%' }}
          ref={mapRef}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {pets.map((pet) => (
            <Marker
              key={pet._id}
              position={pet.location.coordinates}
              icon={petIcon}
            >
              <Popup>
                <strong>{pet.name}</strong><br />
                Species: {pet.species}<br />
                Breed: {pet.breed}<br />
                Weight: {pet.weight} kg<br />
                Last Seen: {pet.address.street}, {pet.address.city}, {pet.address.provinceOrState}
              </Popup>
            </Marker>
          ))}
          {shelters.map((shelter) => (
            <Marker
              key={shelter._id}
              position={shelter.location.coordinates}
              icon={shelterIcon}
            >
              <Popup>
                <strong>{shelter.name}</strong><br />
                Contact: {shelter.contactPerson}<br />
                Email: <Link href={`mailto:${shelter.contactPersonEmail}`} target="_blank">{shelter.contactPersonEmail}</Link><br />
                Phone: {shelter.generalPhoneNumber}<br />
                Address: {shelter.address.street}, {shelter.address.city}, {shelter.address.provinceOrState}
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
      <div className="map-legend" style={{ padding: '10px', background: '#f0f0f0', border: '1px solid #d9d9d9', borderRadius: '4px', margin: '10px 0' }}>
        <p style={{ margin: 0 }}><Text strong>Legend:</Text> <Text style={{ color: 'blue' }}>Blue Pins</Text> <Text>- Shelters,</Text> <Text style={{ color: 'red' }}>Red Pins</Text><Text> - Lost Pets</Text> </p>
      </div>
      <Spacer />
      <div className="info-container">
        <Title level={4}>Lost Pets Reported</Title>
        <Row gutter={[16, 16]}>
          {pets.map((pet) => (
            <Col span={8} key={pet._id}>
              <Card
                title={pet.name}
                onClick={() => handleCardClick(pet.location.coordinates, pet)}
                hoverable
                style={{ cursor: 'pointer', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}
              >
                <p><Text strong>Species:</Text> {pet.species}</p>
                <p><Text strong>Breed:</Text> {pet.breed}</p>
                <p><Text strong>Weight:</Text> {pet.weight} kg</p>
                <p><Text strong>Seen Location:</Text> {pet.address.street}, {pet.address.city}, {pet.address.provinceOrState}</p>
              </Card>
            </Col>
          ))}
        </Row>
        <Pagination
          current={currentPetPage}
          pageSize={petsPerPage}
          total={pets.length}
          onChange={setCurrentPetPage}
          style={{ marginTop: '16px', textAlign: 'center' }}
        />

        <Title level={4} style={{ marginTop: '32px' }}>Shelters and Lost Pet Homes</Title>
        <Row gutter={[16, 16]}>
          {shelters.map((shelter) => (
            <Col span={8} key={shelter._id}>
              <Card
                title={shelter.name}
                onClick={() => handleCardClick(shelter.location.coordinates, shelter)}
                hoverable
                style={{ cursor: 'pointer', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}
              >
                <p><Text strong>Contact:</Text> {shelter.contactPerson}</p>
                <p><Text strong>Email:</Text> <Link href={`mailto:${shelter.contactPersonEmail}`} target="_blank">{shelter.contactPersonEmail}</Link></p>
                <p><Text strong>Phone:</Text> {shelter.generalPhoneNumber}</p>
                <p><Text strong>Website:</Text> <Link href={shelter.mainWebsite} target="_blank">{shelter.mainWebsite}</Link></p>
                <p><Text strong>Address:</Text> {shelter.address.street}, {shelter.address.city}, {shelter.address.provinceOrState}</p>
              </Card>
            </Col>
          ))}
        </Row>
        <Pagination
          current={currentShelterPage}
          pageSize={sheltersPerPage}
          total={shelters.length}
          onChange={setCurrentShelterPage}
          style={{ marginTop: '16px', textAlign: 'center' }}
        />
      </div>

      <Modal
        title={selectedLocation.name}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        {selectedLocation.photoUrls && (
          <Carousel autoplay>
            {selectedLocation.photoUrls.map((url, index) => (
              <div key={index}>
                <img src={url} alt={`Photo${index + 1}`} style={{ width: '100%', maxHeight: '300px', objectFit: 'cover' }} />
              </div>
            ))}
          </Carousel>
        )}
        {selectedLocation.species && (
          <>
            <p><Text strong>Species:</Text> {selectedLocation.species}</p>
            <p><Text strong>Breed:</Text> {selectedLocation.breed}</p>
            <p><Text strong>Weight:</Text> {selectedLocation.weight} kg</p>
            {selectedLocation.birthday && (
              <p><Text strong>Birthday:</Text> {new Date(selectedLocation.birthday).toLocaleDateString()}</p>
            )}
            <p><Text strong>Last Seen:</Text> {selectedLocation.address.street}, {selectedLocation.address.city}, {selectedLocation.address.provinceOrState}, {selectedLocation.address.country}</p>
          </>
        )}
        {selectedLocation.contactPerson && (
          <>
            <p><Text strong>Contact:</Text> {selectedLocation.contactPerson}</p>
            <p><Text strong>Email:</Text> <Link href={`mailto:${selectedLocation.contactPersonEmail}`} target="_blank">{selectedLocation.contactPersonEmail}</Link></p>
            <p><Text strong>Phone:</Text> {selectedLocation.generalPhoneNumber}</p>
            <p><Text strong>Website:</Text> <Link href={selectedLocation.mainWebsite} target="_blank">{selectedLocation.mainWebsite}</Link></p>
            <p><Text strong>Species Catered For:</Text> {selectedLocation.speciesCateredFor.join(', ')}</p>
            <p><Text strong>Hours of Operation:</Text> {selectedLocation.hoursOfOperation}</p>
            {selectedLocation.socialMediaLinks && (
              <>
                <p><Text strong>Social Media:</Text></p>
                {selectedLocation.socialMediaLinks.facebook && (
                  <p><Link href={selectedLocation.socialMediaLinks.facebook} target="_blank">Facebook</Link></p>
                )}
                {selectedLocation.socialMediaLinks.instagram && (
                  <p><Link href={selectedLocation.socialMediaLinks.instagram} target="_blank">Instagram</Link></p>
                )}
                {selectedLocation.socialMediaLinks.x && (
                  <p><Link href={selectedLocation.socialMediaLinks.x} target="_blank">X (formerly Twitter)</Link></p>
                )}
              </>
            )}
            <p><Text strong>Address:</Text> {selectedLocation.address.street}, {selectedLocation.address.city}, {selectedLocation.address.provinceOrState}, {selectedLocation.address.country}</p>
          </>
        )}
      </Modal>
    </div>
  );
};

export default PawFinderMap;
