import React, { useRef, useState } from 'react';
import { Row, Col, Button, Input, Form, Space, message } from 'antd';
import { LoadScript, StandaloneSearchBox, GoogleMap } from '@react-google-maps/api';
import { useBookingContext } from '../../context/BookingContext'; // Import the BookingContext
import './AdoptPet.css'; // Custom styles for the component

const libraries: ('places')[] = ['places'];

interface AdoptPetProps {
  onChoosePetClick: () => void;  // No need to pass bookingData now since we use context
}

const AdoptPet: React.FC<AdoptPetProps> = ({ onChoosePetClick }) => {
  const { updateBookingDetails, bookingDetails } = useBookingContext();  // Use BookingContext
  const [showMap, setShowMap] = useState(false); // Control map visibility
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>({ lat: 45.4215, lng: -75.6972 }); // Default center (Ottawa)

  const addressInputRef = useRef<google.maps.places.SearchBox | null>(null);

  const handlePlacesChanged = () => {
    const places = addressInputRef.current?.getPlaces();
    if (places && places.length > 0) {
      const location = places[0].geometry?.location;
      if (location) {
        updateBookingDetails('address', places[0].formatted_address || '');
        setMapCenter({ lat: location.lat(), lng: location.lng() });
        setShowMap(true); // Show map after selecting address
      }
    }
  };

  const handleAnimalTypeChange = (value: string) => {
    updateBookingDetails('animalType', value);  // Update animal type in the context
  };

  const handleRangeChange = (value: number) => {
    if (value > 150) {
      message.error('Please enter a valid range (Max: 150km).');
    } else {
      updateBookingDetails('range', value);  // Update range in the context
    }
  };

  const handleSubmit = () => {
    const { animalType, address, range } = bookingDetails;

    if (!animalType) {
      message.error('Please select an animal type.');
      return;
    }
    if (!address) {
      message.error('Please enter your address.');
      return;
    }
    if (!range || range > 150) {
      message.error('Please enter a valid range (Max: 150km).');
      return;
    }

    // Call parent function to continue flow (navigate to the next page)
    onChoosePetClick();
  };

  return (
    <div className="adopt-pet-container">
      <h2>Find a Loyal Companion</h2>

      <Form layout="vertical" className="adopt-pet-form" onFinish={handleSubmit}>
        <div className="animal-type-section">
          <Row gutter={[16, 16]} justify="center">
            <Col span={8}>
              <Button
                type={bookingDetails.animalType === 'dog' ? 'primary' : 'default'}
                className="animal-type-button"
                onClick={() => handleAnimalTypeChange('dog')}
              >
                <img src="https://res.cloudinary.com/daq4ywu5y/image/upload/v1725934559/2F1BE094-A82C-464E-A35C-F0F704F2413F_p0rpnf.jpg" alt="Dog" />
                <p>Dog</p>
              </Button>
            </Col>
            <Col span={8}>
              <Button
                type={bookingDetails.animalType === 'cat' ? 'primary' : 'default'}
                className="animal-type-button"
                onClick={() => handleAnimalTypeChange('cat')}
              >
                <img src="https://res.cloudinary.com/daq4ywu5y/image/upload/v1725934559/DBA8A828-DDC0-40A1-973E-8E8855172242_hn5leb.jpg" alt="Cat" />
                <p>Cat</p>
              </Button>
            </Col>
            <Col span={8}>
              <Button
                type={bookingDetails.animalType === 'other' ? 'primary' : 'default'}
                className="animal-type-button"
                onClick={() => handleAnimalTypeChange('other')}
              >
                <img src="https://res.cloudinary.com/daq4ywu5y/image/upload/v1725934559/9F6BF632-609D-463D-81DA-8BCFE49C4C1F_zdtxdc_dgzisg.jpg" alt="Other" />
                <p>Other</p>
              </Button>
            </Col>
          </Row>
        </div>

        <Space direction="vertical" size="large" className="address-range-section">
          <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string} libraries={libraries}>
            <StandaloneSearchBox onLoad={(ref) => (addressInputRef.current = ref)} onPlacesChanged={handlePlacesChanged}>
              <Input
                placeholder="Enter your address"
                value={bookingDetails.address || ''}
                onChange={(e) => updateBookingDetails('address', e.target.value)}  // Update address in the context
              />
            </StandaloneSearchBox>
          </LoadScript>

          <Form.Item label="Enter Range (Max 150km)" required>
            <Input
              type="number"
              placeholder="Max of 150km"
              value={bookingDetails.range !== undefined ? bookingDetails.range : ''}
              onChange={(e) => handleRangeChange(Number(e.target.value))}
            />
          </Form.Item>

          {showMap && (
            <div style={{ width: '100%', height: '400px' }}>
              <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string} libraries={libraries}>
                <GoogleMap
                  mapContainerStyle={{ width: '100%', height: '100%' }}
                  center={mapCenter}
                  zoom={13}
                />
              </LoadScript>
            </div>
          )}

          <Button type="primary" size="large" block htmlType="submit">
            View Available Pets
          </Button>
        </Space>
      </Form>
    </div>
  );
};

export default AdoptPet;
