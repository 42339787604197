import React, { useState, useRef } from "react";
import {
  Button,
  Form,
  Typography,
  Row,
  Col,
  Space,
  Radio,
  DatePicker,
  TimePicker,
  message,
} from "antd";
import {
  StandaloneSearchBox,
  DirectionsRenderer,
  GoogleMap,
} from "@react-google-maps/api";
import { Dayjs } from "dayjs";
import "./PetTransport.css"; // Custom styles

const { Title, Text } = Typography;

const PetTransport: React.FC = () => {
  const [pickupLocation, setPickupLocation] = useState<string>("");
  const [dropLocation, setDropLocation] = useState<string>("");
  const [distance, setDistance] = useState<number | null>(null);
  const [showMap, setShowMap] = useState(false); // State to show the map after calculating distance
  const [directionsResponse, setDirectionsResponse] =
    useState<google.maps.DirectionsResult | null>(null);
  const [scheduleType, setScheduleType] = useState<string>("now"); // "now" or "later"
  const [scheduledDate, setScheduledDate] = useState<Dayjs | null>(null);
  const [scheduledTime, setScheduledTime] = useState<Dayjs | null>(null);
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>({
    lat: 45.4215,
    lng: -75.6972,
  }); // Default center

  const pickupLocationRef = useRef<google.maps.places.SearchBox | null>(null);
  const dropLocationRef = useRef<google.maps.places.SearchBox | null>(null);

  const handlePlacesChanged = (
    ref: React.MutableRefObject<google.maps.places.SearchBox | null>,
    setLocation: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const places = ref.current?.getPlaces();
    if (places && places.length > 0) {
      setLocation(places[0].formatted_address || "");
    }
  };

  const handleCalculateDistance = () => {
    if (!pickupLocation || !dropLocation) {
      message.error("Please enter both pickup and drop locations.");
      return;
    }

    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: pickupLocation,
        destination: dropLocation,
        travelMode: google.maps.TravelMode.DRIVING, // Use driving mode for car distance
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK && result) {
          setDirectionsResponse(result);
          setDistance(result.routes[0].legs[0].distance?.value || 0);
          setShowMap(true);
          setMapCenter({
            lat: result.routes[0].legs[0].start_location.lat(),
            lng: result.routes[0].legs[0].start_location.lng(),
          });
        } else {
          message.error(
            "Could not calculate driving distance. Please try again."
          );
        }
      }
    );
  };

  const handleScheduleTypeChange = (e: any) => {
    setScheduleType(e.target.value);
  };

  const handleSubmit = () => {
    console.log("Pickup Location:", pickupLocation);
    console.log("Drop Location:", dropLocation);
    if (scheduleType === "later" && (!scheduledDate || !scheduledTime)) {
      message.error("Please select both date and time for scheduling.");
      return;
    }
    handleCalculateDistance();
  };

  return (
    <div className="pet-transport-container">
      <Title level={2}>Pet Transport Service</Title>

      <Form
        layout="vertical"
        className="pet-transport-form"
        onFinish={handleSubmit}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="Pickup Location">
              <StandaloneSearchBox
                onLoad={(ref) => (pickupLocationRef.current = ref)}
                onPlacesChanged={() =>
                  handlePlacesChanged(pickupLocationRef, setPickupLocation)
                }
              >
                <input
                  type="text"
                  placeholder="Enter pickup location"
                  style={{ width: "100%", padding: "8px" }}
                />
              </StandaloneSearchBox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Drop off Location">
              <StandaloneSearchBox
                onLoad={(ref) => (dropLocationRef.current = ref)}
                onPlacesChanged={() =>
                  handlePlacesChanged(dropLocationRef, setDropLocation)
                }
              >
                <input
                  type="text"
                  placeholder="Enter drop off location"
                  style={{ width: "100%", padding: "8px" }}
                />
              </StandaloneSearchBox>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Schedule">
          <Radio.Group onChange={handleScheduleTypeChange} value={scheduleType}>
            <Radio value="now">Now</Radio>
            <Radio value="later">Schedule Later</Radio>
          </Radio.Group>
        </Form.Item>

        {scheduleType === "later" && (
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item label="Scheduled Date">
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={(date) => setScheduledDate(date)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Scheduled Time">
                <TimePicker
                  use12Hours
                  format="h:mm a"
                  style={{ width: "100%" }}
                  onChange={setScheduledTime}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Button type="primary" size="large" block onClick={handleSubmit}>
            Calculate Distance
          </Button>

          {distance !== null && (
            <>
              <Text type="secondary">
                Distance: {(distance / 1000).toFixed(2)} km
              </Text>
            </>
          )}

          {showMap && (
            <div style={{ width: "100%", height: "400px" }}>
              <GoogleMap
                mapContainerStyle={{ width: "100%", height: "100%" }}
                center={mapCenter}
                zoom={13}
              >
                {directionsResponse && (
                  <DirectionsRenderer directions={directionsResponse} />
                )}
              </GoogleMap>
            </div>
          )}

          <Button type="primary" size="large" block htmlType="submit">
            Choose Pet
          </Button>
        </Space>
      </Form>
    </div>
  );
};

export default PetTransport;
