import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import AdoptPet from '../../components/AdoptPet/AdoptPet';

const PetAdoptionServicesPage: React.FC = () => {
  const navigate = useNavigate();

  const handleChoosePetClick = () => {
    // Proceed to the next step (PetChoicePage)
    navigate('/pet-choice', { state: { service: 'Pet Adoption' } });
  };

  return (
    <div className="pet-adoption-page">
      <Header />
      <div className="pet-adoption-content">
        <AdoptPet onChoosePetClick={handleChoosePetClick} />
      </div>
      <Footer />
    </div>
  );
};

export default PetAdoptionServicesPage;
