import React from "react";
import { Link } from "react-router-dom";
import "./HeroSection.css";
import "../../assets/styles/global.css";
import { Button, ConfigProvider } from "antd";
import { TinyColor } from '@ctrl/tinycolor';
import { Typography } from "antd";
const { Title } = Typography;
const colors1 = ['#6253E1', '#04BEFE'];

const getHoverColors = (colors) =>
  colors.map((color) => new TinyColor(color).lighten(5).toString());

const getActiveColors = (colors) =>
  colors.map((color) => new TinyColor(color).darken(5).toString());

const HeroSection = () => {
  // Define the URL for the background image
  const backgroundImageUrl =
    "https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587569/8_o0o2ae.png";

  return (
    <section
      className="diagonal-section"
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
    >
      <div className="cd-container cd-max-width-sm cd-padding-y-3xl">
        <div className="cd-text-center">
          <h1 className="cd-margin-bottom-sm"><Title>Pet Care Simplified</Title></h1>
          <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: `linear-gradient(135deg, ${colors1.join(', ')})`,
            colorPrimaryHover: `linear-gradient(135deg, ${getHoverColors(colors1).join(', ')})`,
            colorPrimaryActive: `linear-gradient(135deg, ${getActiveColors(colors1).join(', ')})`,
            lineWidth: 0,
          },
        },
      }}
    >
      <Link to="/become-a-paw-pro" className="cd-btn cd-btn--outline cd-btn--xl">
      <Button type="primary" size="large">
        Become A Paw Pro 
      </Button>
      </Link>
    </ConfigProvider>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
