import React from 'react';
import "./Stories.css"; // Make sure to import your CSS file here
import "../../assets/styles/global.css";
import { Link } from 'react-router-dom';
import { Typography } from "antd";
const { Text, Title } = Typography;

const Stories = () => {
  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
    <div className="stories">

      <h3 className="stories__legend">
        <Title level={4}>
        Popular stories
        </Title>
      </h3>
      
      <ol className="stories__list">
        <li className="stories__story">
          <Link to="/" className="stories__img-wrapper">
            <figure>
              <img src="https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587569/13_e5agpf.png" alt="" />
            </figure>
          </Link>
  
          <div className="stories__text sz3-text-component sz3-line-height-md sz3-text-gap-2xs">
            <h4 className="stories__headline"><Link to="/">
                <Text strong>
                A Companion for Life
                </Text>
                </Link></h4>
            <p className="stories__metadata"><time dateTime="2020-05-10">May 10, 2020</time> <span className="stories__separator" role="separator"></span> <a href="#0">Personal Experience</a></p>
          </div>
        </li>
  
        <li className="stories__story">
          <Link to="/" className="stories__img-wrapper">
            <figure>
              <img src="https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587569/12_rnsjyk.png" alt="" />
            </figure>
          </Link>
  
          <div className="stories__text sz3-text-component sz3-line-height-md sz3-text-gap-2xs">
            <h4 className="stories__headline"><Link to="/">
            <Text strong>
            The Calming Presence in the Storm
                </Text>
                </Link></h4>
            <p className="stories__metadata"><time dateTime="2020-04-08">April 8, 2020</time> <span className="stories__separator" role="separator"></span> <a href="#0">Pet Care Tips</a></p>
          </div>
        </li>
  
        <li className="stories__story">
          <Link to="/" className="stories__img-wrapper">
            <figure>
              <img src="https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587568/6_ncqvyb.png" alt="" />
            </figure>
          </Link>
  
          <div className="stories__text sz3-text-component sz3-line-height-md sz3-text-gap-2xs">
            <h4 className="stories__headline"><Link to="/">
            <Text strong>
            Moonlit Adventures with My Pet
                </Text></Link></h4>
            <p className="stories__metadata"><time dateTime="2020-03-18">March 18, 2020</time> <span className="stories__separator" role="separator"></span> <a href="#0">Pet Adventures</a></p>
          </div>
        </li>
  
        <li className="stories__story">
          <Link to="/" className="stories__img-wrapper">
            <figure>
              <img src="https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587569/9_ky6dpf.png" alt="" />
            </figure>
          </Link>
  
          <div className="stories__text sz3-text-component sz3-line-height-md sz3-text-gap-2xs">
            <h4 className="stories__headline"><Link to="/">
            <Text strong>
            Two Natures, One Unforgettable Bond
                </Text></Link></h4>
            <p className="stories__metadata"><time dateTime="2020-03-05">March 5, 2020</time> <span className="stories__separator" role="separator"></span> <a href="#0">Pet Owner Reflection</a></p>
          </div>
        </li>
      </ol>
    </div>
    </section>
  );
};

export default Stories;
