import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import SuppCentVideo from '../components/SuppCentVideo/SuppCentVideo';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';
import SuppCentInfo from '../components/SuppCentInfo/SuppCentInfo';
import SuppCentOptions from '../components/SuppCentOptions/SuppCentOptions';

const SupportCenterPage: React.FC = () => {
  return (
    <div>
      <Header />
      <SuppCentVideo />
      <SuppCentInfo />
      <SuppCentOptions />
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default SupportCenterPage;
