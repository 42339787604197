import React, { useState, useEffect } from "react";
import { useLocation, BrowserRouter as Router } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { ConfigProvider, message, App as AppWrapper } from "antd";
import PasswordProtection from "./components/PasswordProtect/PasswordProtect";
import AppRoutes from "./routes";
import ShopContextProvider from "./context/ShopContext";
import BookingContextProvider from "./context/BookingContext";
import { lightTheme, darkTheme } from "./themeConfig.ts";

import "./assets/styles/global.css";
import { LoadScript } from "@react-google-maps/api";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const libraries = ["places"];

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    // Load dark mode preference from localStorage
    const darkModePreference = localStorage.getItem("dark-mode") === "true";
    setIsDarkMode(darkModePreference);
    document.body.classList.toggle("dark-mode", darkModePreference);
  }, []);

  return (
    <PasswordProtection>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{ redirect_uri: window.location.origin }}
      >
        
        <ShopContextProvider>
        <BookingContextProvider>
          <ConfigProvider theme={isDarkMode ? darkTheme : lightTheme}>
            <AppWrapper>
              <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                libraries={libraries}
                onLoad={() => {
                  setMapLoaded(true);
                }} // Set googleApiLoaded to true when script loads
                onError={() => {
                  message.error("Failed to load Google Maps API", 5);
                }}
              >
                {mapLoaded && (
                  <Router>
                    <ScrollToTop />
                    <div className="App">
                      <main>
                        <AppRoutes />
                      </main>
                    </div>
                  </Router>
                )}
              </LoadScript>
            </AppWrapper>
          </ConfigProvider>
          </BookingContextProvider>
        </ShopContextProvider>
        
      </Auth0Provider>
    </PasswordProtection>
  );
}

export default App;
