import React from "react";
import "./Footer.css"; // Import the external CSS file
import { Button } from "antd";
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import "../../assets/styles/global.css";
import { InstagramFilled, FacebookFilled, LinkedinFilled, XOutlined, YoutubeFilled} from '@ant-design/icons';
const { Text } = Typography;



const cloudinaryImageUrl =
  "https://res.cloudinary.com/daq4ywu5y/image/upload/v1725825092/1_lecdga.png";

const Footer = () => {
  return (
    <footer className="main-footer me6-position-relative me6-z-index-1 me6-padding-top-xl">
      <div className="me6-container me6-max-width-lg">
        <div className="me6-grid me6-gap-y-lg me6-gap-lg@lg">
          <div className="me6-col-3@lg me6-order-2@lg me6-text-right@lg">
            <Link to={"/"} className="main-footer__logo" href="#0">
              <img
                height={"auto"}
                width={"auto"}
                src={cloudinaryImageUrl}
                alt="Logo"
              />
            </Link>
          </div>

          <nav className="me6-col-9@lg me6-order-1@lg">
            <ul className="me6-grid me6-gap-y-lg me6-gap-lg@xs">
              <li className="me6-col-6@xs me6-col-3@md">
                <h4 className="me6-margin-bottom-sm me6-text-base@md">
                <Button type="text"><Text strong>Company</Text></Button>
                </h4>
                <ul className="me6-grid me6-gap-xs me6-text-sm@md">
                  <li>
                    <Link to="/careers" className="main-footer__link">
                    <Button type="text">Careers</Button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us" className="main-footer__link">
                    <Button type="text">About Us</Button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/news" className="main-footer__link">
                    <Button type="text">News</Button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" className="main-footer__link">
                    <Button type="text">Blog</Button>
                    </Link>
                  </li>
                  
                </ul>
              </li>

              <li className="me6-col-6@xs me6-col-3@md">
                <h4 className="me6-margin-bottom-sm me6-text-base@md">
                <Button type="text"><Text strong>Explore</Text></Button>
                </h4>
                <ul className="me6-grid me6-gap-xs me6-text-sm@md">
                  <li>
                    <Link to="/paw-finder" className="main-footer__link">
                    <Button type="text">Paw Finder</Button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/become-a-paw-pro" className="main-footer__link">
                    <Button type="text">Become A Paw Pro</Button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/services" className="main-footer__link">
                    <Button type="text">Services</Button>
                    </Link>
                  </li>
                  
                  <li>
                    <Link to="/legal" className="main-footer__link">
                    <Button type="text">Legal</Button>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="me6-col-6@xs me6-col-3@md">
                <h4 className="me6-margin-bottom-sm me6-text-base@md">
                <Button type="text"><Text strong>More</Text></Button>
                </h4>
                <ul className="me6-grid me6-gap-xs me6-text-sm@md">
                  <li>
                    <Link to="/support-center" className="main-footer__link">
                  <Button type="text">Support Center</Button>
                  </Link>
                  </li>
                  <li>
                    <Link to="/faqs" className="main-footer__link">
                    <Button type="text">FAQ's</Button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/community" className="main-footer__link">
                    <Button type="text">Community</Button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" className="main-footer__link">
                    <Button type="text">Contact Us</Button>
                    </Link>
                  </li>
                  
                </ul>
              </li>

              <li className="me6-col-6@xs me6-col-3@md">
                
              </li>
            </ul>
          </nav>
        </div>

        <div className="me6-flex me6-flex-column me6-border-top me6-padding-y-xs me6-margin-top-lg me6-flex-row@md me6-justify-between@md me6-items-center@md">
          <div className="me6-margin-bottom-sm me6-margin-bottom-0@md">
            <div className="me6-text-sm me6-text-xs@md me6-color-contrast-medium me6-flex me6-flex-wrap me6-gap-xs">
              <span>&copy; Paw Pros</span>
              <Link to="/" className="me6-color-contrast-high">
                Terms
              </Link>
              <Link to="/" className="me6-color-contrast-high">
                Privacy
              </Link>
            </div>
          </div>

          <div className="me6-flex me6-items-center me6-gap-xs">
            <Link to="/" className="main-footer__social">
              <FacebookFilled />
            </Link>
            <Link to="https://www.instagram.com/pawsandpros" className="main-footer__social">
              <InstagramFilled />
            </Link>
            <Link to="/" className="main-footer__social">
              <LinkedinFilled />
            </Link>
            <Link to="/" className="main-footer__social">
              <XOutlined />
            </Link>
            <Link to="/" className="main-footer__social">
              <YoutubeFilled />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
