import React from 'react';
import { Card, Typography, Button } from 'antd';

const { Title } = Typography;

interface InsuranceProps {
  price: string;
  coverage: string;
  reimbursement: number;
  support: string;
}

const TrupanionInsurance: React.FC<InsuranceProps> = ({ price, coverage, reimbursement, support }) => {
  return (
    <Card className="quote-card" title="Trupanion Insurance">
      <div className="insurance-details">
        <Title level={4}>Price: {price} / month</Title>
        <ul className="insurance-features">
          <li>Coverage up to {coverage}</li>
          <li>{reimbursement}% Reimbursement</li>
          <li>{support}</li>
        </ul>
        <Button type="primary" className="quote-button">
          Sign Up
        </Button>
      </div>
    </Card>
  );
};

export default TrupanionInsurance;
