import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import FuneralServe from '../../components/FuneralServe/FuneralServe';
import { useNavigate } from 'react-router-dom';
import { useBookingContext } from '../../context/BookingContext'; // Import the BookingContext

const PetFuneralServicesPage: React.FC = () => {
  const { updateBookingDetails } = useBookingContext();  // Access updateBookingDetails from the context
  const navigate = useNavigate();  // Use navigate for redirection

  // Handle when the user selects a service, date, time, and addons in the FuneralServe component
  const handleChoosePetClick = (
    date: any,
    time: any,
    service: string,
    addons: string[]
  ) => {
    // Store the booking data in the context
    updateBookingDetails('date', date ? date.toDate() : null);
    updateBookingDetails('time', time ? time.toDate() : null);
    updateBookingDetails('service', service);
    updateBookingDetails('addons', addons);

    // Navigate to the PetChoicePage
    navigate('/choose-pet', {
      state: { service: 'Pet Funeral Services' },  // Passing the service type
    });
  };

  return (
    <div className="pet-funeral-services-page">
      <Header />
      <div className="pet-funeral-services-content">
        <FuneralServe onChoosePetClick={handleChoosePetClick} />  
      </div>
      <Footer />
    </div>
  );
};

export default PetFuneralServicesPage;
