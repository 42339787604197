import React, { useState, useRef } from "react";
import {
  DatePicker,
  TimePicker,
  Button,
  Row,
  Col,
  Typography,
  message,
} from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Dayjs } from "dayjs";
import {
  StandaloneSearchBox,
  DirectionsRenderer,
  GoogleMap,
} from "@react-google-maps/api";
import { useBookingContext } from '../../context/BookingContext';  // Import BookingContext

const { Title } = Typography;

interface DogWalkingComponentProps {
  onChoosePetClick: () => void;
}

const DogWalkingComponent: React.FC<DogWalkingComponentProps> = ({
  onChoosePetClick,
}) => {
  const [date, setDate] = useState<Dayjs | null>(null);
  const [time, setTime] = useState<Dayjs | null>(null);
  const [startLocation, setStartLocation] = useState<string>("");
  const [endLocation, setEndLocation] = useState<string>("");
  const [distanceInfo, setDistanceInfo] = useState<{
    distanceText: string;
    durationText: string;
  } | null>(null);
  const [showMap, setShowMap] = useState(false);
  const [directionsResponse, setDirectionsResponse] =
    useState<google.maps.DirectionsResult | null>(null);
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>({
    lat: 45.4215,
    lng: -75.6972,
  });

  const startLocationRef = useRef<google.maps.places.SearchBox | null>(null);
  const endLocationRef = useRef<google.maps.places.SearchBox | null>(null);
  const { updateBookingDetails } = useBookingContext();  // Access updateBookingDetails from the context

  const handleDateChange = (date: Dayjs | null) => {
    setDate(date);
    updateBookingDetails('date', date ? date.toDate() : null);  // Update booking date in context
  };

  const handleTimeChange = (time: Dayjs | null) => {
    setTime(time);
    updateBookingDetails('time', time ? time.toDate() : null);  // Update booking time in context
  };

  const handlePlacesChanged = (
    ref: React.MutableRefObject<google.maps.places.SearchBox | null>,
    setLocation: React.Dispatch<React.SetStateAction<string>>,
    fieldName: string
  ) => {
    const places = ref.current?.getPlaces();
    if (places && places.length > 0) {
      const address = places[0].formatted_address || "";
      setLocation(address);
      updateBookingDetails(fieldName, address);  // Update booking location in context
    }
  };

  const handleCheckDistanceClick = () => {
    if (!startLocation || !endLocation) {
      message.error("Please enter both start and end locations.");
      return;
    }

    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: startLocation,
        destination: endLocation,
        travelMode: google.maps.TravelMode.WALKING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK && result) {
          setDirectionsResponse(result);
          const distanceText = result.routes[0].legs[0].distance?.text || "";
          const durationText = result.routes[0].legs[0].duration?.text || "";
          setDistanceInfo({
            distanceText,
            durationText,
          });
          setShowMap(true);
          setMapCenter({
            lat: result.routes[0].legs[0].start_location.lat(),
            lng: result.routes[0].legs[0].start_location.lng(),
          });
          updateBookingDetails('distance', distanceText);  // Update booking distance in context
          updateBookingDetails('duration', durationText);  // Update booking duration in context
        } else {
          message.error(
            "Could not calculate walking distance. Please try again."
          );
        }
      }
    );
  };

  const handleChoosePetClick = () => {
    if (!distanceInfo || !date || !time) {
      message.error(
        "Please check the distance and select a date and time first!"
      );
      return;
    }

    onChoosePetClick();  // Continue to PetChoicePage
  };

  return (
    <>
      <div
        style={{
          maxWidth: "600px",
          margin: "0 auto",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <Title level={3} style={{ fontWeight: "bold", marginBottom: "20px" }}>
          BOOK DOG WALKING SESSION
        </Title>

        <Row gutter={16} style={{ marginBottom: "20px" }}>
          <Col span={12}>
            <DatePicker
              placeholder="Date"
              style={{ width: "100%" }}
              onChange={handleDateChange}
              disabledDate={(current: any) =>
                !current || current.isBefore(new Date(), "day")
              }
            />
          </Col>
          <Col span={12}>
            <TimePicker
              placeholder="Time"
              use12Hours
              format="h:mm a"
              style={{ width: "100%" }}
              onChange={handleTimeChange}
            />
          </Col>
        </Row>

        <Row gutter={16} align="middle" style={{ marginBottom: "20px" }}>
          <Col span={11}>
            <StandaloneSearchBox
              onLoad={(ref) => (startLocationRef.current = ref)}
              onPlacesChanged={() =>
                handlePlacesChanged(startLocationRef, setStartLocation, 'startLocation')
              }
            >
              <input
                type="text"
                placeholder="Starting Location"
                style={{ width: "100%", padding: "8px" }}
              />
            </StandaloneSearchBox>
          </Col>
          <Col span={2} style={{ textAlign: "center" }}>
            <ArrowRightOutlined style={{ fontSize: "24px" }} />
          </Col>
          <Col span={11}>
            <StandaloneSearchBox
              onLoad={(ref) => (endLocationRef.current = ref)}
              onPlacesChanged={() =>
                handlePlacesChanged(endLocationRef, setEndLocation, 'endLocation')
              }
            >
              <input
                type="text"
                placeholder="TurnAround Location"
                style={{ width: "100%", padding: "8px" }}
              />
            </StandaloneSearchBox>
          </Col>
        </Row>

        <Button
          type="primary"
          style={{
            width: "100%",
            height: "50px",
            fontSize: "18px",
            marginBottom: "20px",
          }}
          onClick={handleCheckDistanceClick}
          disabled={!startLocation || !endLocation}
        >
          Check Distance
        </Button>

        {showMap && (
          <>
            <div style={{ marginBottom: "20px" }}>
              <p>
                <strong>Distance:</strong> {distanceInfo?.distanceText}
              </p>
              <p>
                <strong>Estimated Time:</strong> {distanceInfo?.durationText}
              </p>
            </div>

            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "300px" }}
              center={mapCenter}
              zoom={13}
            >
              {directionsResponse && (
                <DirectionsRenderer directions={directionsResponse} />
              )}
            </GoogleMap>

            <Button
              type="primary"
              style={{
                width: "100%",
                height: "50px",
                fontSize: "18px",
                marginTop: "20px",
              }}
              onClick={handleChoosePetClick}
            >
              Choose Pet
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default DogWalkingComponent;
