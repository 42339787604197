import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography } from 'antd';
import axios from 'axios';

const { Title, Paragraph, Text } = Typography;

// Helper function to format service names
const toCamelCase = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase()
    )
    .replace(/\s+/g, '');
};

const CaregiverDisplay = ({ selectedService, onProviderSelect }) => {
  const [caregivers, setCaregivers] = useState([]);
  const [selectedCaregiver, setSelectedCaregiver] = useState(null);

  useEffect(() => {
    const fetchCaregivers = async () => {
      try {
        const serviceKey = toCamelCase(selectedService); // Convert service to camelCase
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/api/communityCaregiver/service/${serviceKey}`);
        setCaregivers(response.data);
      } catch (error) {
        console.error('Error fetching community caregivers:', error.message);
      }
    };

    if (selectedService) {
      fetchCaregivers();
    }
  }, [selectedService]);

  const handleSelectCaregiver = (caregiver) => {
    setSelectedCaregiver(caregiver);
    onProviderSelect(caregiver); // Notify parent component of the selected caregiver
  };

  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
      <Row gutter={[16, 16]}>
        {caregivers.map((caregiver, index) => (
          <Col key={index} xs={24} sm={12} md={8} lg={6}>
            <Card
              hoverable
              className={selectedCaregiver && selectedCaregiver._id === caregiver._id ? 'selected-card' : ''}
              onClick={() => handleSelectCaregiver(caregiver)}
            >
              <img src={caregiver.profilePictureUrl} alt={caregiver.firstName} style={{ width: '100%' }} />
              <div>
                <Title level={3}>{`${caregiver.firstName} ${caregiver.lastName}`}</Title>
                <Paragraph>Email: {caregiver.email}</Paragraph>
                <Text strong>Experience Level:</Text>
                <Paragraph>{caregiver.experienceLevel}</Paragraph>
                <Text strong>Starting at Price:</Text>
                <Paragraph>${caregiver.startingPrice || 'N/A'}</Paragraph>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </section>
  );
};

export default CaregiverDisplay;
