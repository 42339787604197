import React, { useState } from 'react';
import { DatePicker, TimePicker, Button, Form, Typography, Row, Col } from 'antd';
import { Dayjs } from 'dayjs'; // Use Dayjs for date and time handling
import './BookVet.css';

const { Title } = Typography;

interface BookVetProps {
  onChoosePetClick: (date: Dayjs | null, time: Dayjs | null) => void;  // Define callback prop to pass booking data (date and time)
}

const BookVet: React.FC<BookVetProps> = ({ onChoosePetClick }) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(null);

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time: Dayjs | null) => {
    setSelectedTime(time);
  };

  const handleSearchVets = () => {
    if (selectedDate && selectedTime) {
      onChoosePetClick(selectedDate, selectedTime);  // Pass selected date and time to the parent
    } else {
      console.log("Please select both date and time.");
    }
  };

  return (
    <div className="book-vet-container">
      <Title level={2} className="book-vet-title">Book Veterinary Appointment</Title>
      <Form layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="Date">
              <DatePicker 
                onChange={handleDateChange} 
                format="YYYY-MM-DD"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Time">
              <TimePicker 
                onChange={handleTimeChange} 
                use12Hours 
                format="h:mm a" 
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button 
            type="primary" 
            size="large" 
            block 
            onClick={handleSearchVets}
            disabled={!selectedDate || !selectedTime}
          >
            Choose Pet
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default BookVet;
