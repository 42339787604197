import React, { useState } from 'react';
import { Collapse, Typography, Button, Modal } from 'antd';
import './FAQs.css';

const { Panel } = Collapse;
const { Title, Paragraph } = Typography;

const FAQComponent: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState<string>('');
  const [currentAnswer, setCurrentAnswer] = useState<string>('');

  const showModal = (question: string, answer: string) => {
    setCurrentQuestion(question);
    setCurrentAnswer(answer);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const faqData = [
    {
      category: 'Orders',
      questions: [
        { question: 'Order Issue', answer: 'If you experience an issue with your order, please contact customer service for assistance.' },
        { question: 'Track Shipment', answer: 'To track your shipment, log in to your account and visit the orders section.' },
        { question: 'Cancel Order', answer: 'You can cancel your order if it hasn’t been processed by visiting your orders page.' },
        { question: 'Modify Order', answer: 'To modify an order, please contact support if the order is not yet shipped.' },
        { question: 'Return/Exchange', answer: 'Visit the returns section to initiate a return or exchange.' },
        { question: 'Missing Items', answer: 'If items are missing from your order, report the issue through the help center.' },
        { question: 'Incorrect Items', answer: 'If you received incorrect items, contact support to resolve the issue.' },
        { question: 'Order Status', answer: 'Check your order status by logging into your account.' },
        { question: 'Invoice/Receipt Request', answer: 'You can request an invoice or receipt from your order history.' },
        { question: 'Pre-order Information', answer: 'For pre-orders, check your email for updates on availability.' },
        { question: 'Bulk Orders', answer: 'For bulk orders, contact sales for custom pricing and information.' },
        { question: 'Shipping Fees and Policies', answer: 'Shipping fees are calculated based on your location and the weight of your order.' },
        { question: 'International Shipping', answer: 'We offer international shipping to select countries. Check our shipping policies.' },
        { question: 'Payment Issues', answer: 'If you experience payment issues, verify your payment details or contact support.' },
        { question: 'Gift Orders', answer: 'You can send orders as gifts by selecting the gift option during checkout.' },
      ],
    },
    {
      category: 'Bookings',
      questions: [
        { question: 'Booking Issue', answer: 'If you experience any issues with booking, reach out to support.' },
        { question: 'Reschedule Booking', answer: 'You can reschedule your booking by visiting your booking details page.' },
        { question: 'Cancel Booking', answer: 'To cancel a booking, go to your bookings and click cancel.' },
        { question: 'Booking Confirmation', answer: 'Once your booking is confirmed, you’ll receive an email confirmation.' },
        { question: 'Refund for Booking', answer: 'Refunds for bookings can be requested through your account.' },
        { question: 'Modify Booking Details', answer: 'To modify booking details, contact the service provider directly.' },
        { question: 'Booking Status', answer: 'Check the status of your booking by logging into your account.' },
        { question: 'Last-Minute Booking', answer: 'Last-minute bookings are available depending on provider availability.' },
        { question: 'Provider Information', answer: 'View provider information directly from the booking page.' },
        { question: 'Payment for Booking', answer: 'Payment for bookings can be made through various accepted methods at checkout.' },
        { question: 'Booking Policies', answer: 'Review the booking policies before confirming your appointment.' },
        { question: 'Bookings for Multiple Pets', answer: 'You can add multiple pets to your booking by selecting the appropriate option.' },
        { question: 'Virtual Appointments', answer: 'We offer virtual appointments for select services.' },
        { question: 'Booking Notifications', answer: 'Enable booking notifications in your account settings to receive reminders.' },
        { question: 'Booking Feedback', answer: 'Leave feedback after your booking is completed to help improve our services.' },
      ],
    },
    // Repeat similar structures for other categories like Account Management, Payments & Billing, Services, Technical Support
  ];

  return (
    <section className="faq-container">
      <Title className="faq-title">Frequently Asked Questions</Title>
      <Collapse accordion>
        {faqData.map((section) => (
          <Panel header={section.category} key={section.category}>
            {section.questions.map((item) => (
              <div key={item.question} className="faq-item">
                <Paragraph>{item.question}</Paragraph>
                <Button type="link" onClick={() => showModal(item.question, item.answer)}>
                  View Answer
                </Button>
              </div>
            ))}
          </Panel>
        ))}
      </Collapse>

      {/* Modal for displaying the answer */}
      <Modal
        title={currentQuestion}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Paragraph>{currentAnswer}</Paragraph>
      </Modal>
    </section>
  );
};

export default FAQComponent;
