// themeConfig.ts
export const lightTheme = {
    '@primary-color': '#ff4da6', // Electric Pink
    '@link-color': '#4d9fff', // Neon Blue
    '@success-color': '#4caf50', // Green for success states
    '@warning-color': '#ff9800', // Orange for warning
    '@error-color': '#f5222d', // Red for error states
    '@font-family': '"Poppins", "Roboto", sans-serif',
    '@heading-color': '#1a1a2e', // Dark navy for headings
    '@text-color': '#333', // Default text color
    '@text-color-secondary': '#555', // Secondary text color
    '@border-radius-base': '10px', // Rounded corners for components
    '@border-color-base': '#d9d9d9', // Light gray borders
    '@box-shadow-base': '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle box-shadow
  };
  
  export const darkTheme = {
    '@primary-color': '#ff4da6', // Keep brand color the same
    '@link-color': '#4d9fff',
    '@success-color': '#4caf50',
    '@warning-color': '#ff9800',
    '@error-color': '#f5222d',
    '@font-family': '"Poppins", "Roboto", sans-serif',
    '@heading-color': '#f5f5f5', // White for dark backgrounds
    '@text-color': '#d9d9d9', // Light gray text
    '@text-color-secondary': '#bfbfbf', // Even lighter for secondary text
    '@border-radius-base': '10px',
    '@border-color-base': '#444', // Darker border colors
    '@box-shadow-base': '0 4px 12px rgba(0, 0, 0, 0.2)', // Stronger shadow for dark mode
    '@background-color-base': '#1a1a2e', // Dark navy for background
  };
  