import React from 'react';
import './CommunityOptionsGrid.css';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

interface GridItem {
  imgSrc: string;
  title: string;
  description: string;
  link: string;
}

const CommunityOptionsGrid: React.FC = () => {
  const gridData: GridItem[] = [
    {
      imgSrc: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1726153672/0304C33F-7ED0-4030-9D96-80086AA4DDCF_uzhxho.jpg',
      title: 'Videos',
      description: 'Explore our Video Gallery, where adorable pets and their unforgettable moments come to life. Watch heartwarming clips, playful interactions, and helpful pet care tips that capture the essence of our pet-loving community.',
      link: '#0',
    },
    {
      imgSrc: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1726153672/B64D3E3F-5864-4D9C-B363-4446DDD1E04A_yynv5d.jpg',
      title: 'Pictures',
      description: 'Discover heartwarming and captivating moments in our Picture Gallery, showcasing the special bond between pets and their owners. From playful antics to peaceful cuddles, explore a collection of images that celebrate the joy pets bring to our lives.',
      link: '#0',
    },
    {
      imgSrc: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1726153672/F2F72A29-CFBE-4CB9-8072-D76B77FEF210_n6jx9b.jpg',
      title: 'Reach Map',
      description: 'Check out our Reach Map to explore the areas where our services are available. Discover local pet care providers near you, and see how we are expanding to bring trusted, high-quality pet care to communities across the region.',
      link: '#0',
    },
    {
      imgSrc: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1726153672/8D41921B-BEA4-482C-93A9-0F0C002B78FA_tmwhr2.jpg',
      title: 'Social Media',
      description: 'Connect with us on our social media platforms to stay up-to-date with the latest pet care tips, heartwarming stories, and community highlights. Join a vibrant community of pet lovers, share your own experiences, and engage with fellow pet owners and providers.',
      link: '#0',
    },
  ];

  return (
    <section className="feature-v6 cd-padding-y-xl">
      <div className="cd-container cd-max-width-adaptive-lg">
        <div className="feature-v6__grid">
          {gridData.map((item, index) => (
            <div key={index}>
              <img className="feature-v6__img" src={item.imgSrc} alt="Imagedescription" />
              <Title level={4} className="cd-margin-bottom-2xs">{item.title}</Title>
              <Paragraph className="feature-v6__description cd-margin-bottom-xs">{item.description}</Paragraph>
              <p><a href={item.link} className="cd-link">View more</a></p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CommunityOptionsGrid;
