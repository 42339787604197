import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import HeroSection from '../components/HeroSection/HeroSection';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';
import Spacer from '../components/Spacer/Spacer';
import PPTeam from '../components/PPTeam/PPTeam';

const AboutUs: React.FC = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <Spacer />
      <Spacer />
      <PPTeam />
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default AboutUs;
