import React, { useState } from "react";
import { Form, Input, Button, Select, Row, Col, Typography, DatePicker } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import "./styles/PetInsurancePage.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import TrupanionInsurance from "../../components/PetInsurancePage/TrupanionInsurance";
import SpotInsurance from "../../components/PetInsurancePage/SpotInsurance";
import FetchInsurance from "../../components/PetInsurancePage/FetchInsurance";

const { Option } = Select;
const { Title, Text } = Typography;

interface Quote {
  provider: string;
  price: string;
  coverage: string;
  reimbursement: number;
  support: string;
}

const veterinarians = [
  { id: 1, name: "Dr. Smith" },
  { id: 2, name: "Dr. Brown" },
  { id: 3, name: "Dr. Adams" },
  // Add more veterinarians as needed
];

const PetInsurancePage: React.FC = () => {
  const [pets, setPets] = useState([{ id: 1 }]);
  const [discount, setDiscount] = useState(0);
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [loading, setLoading] = useState(false);

  const addPet = () => {
    if (pets.length < 5) {
      setPets([...pets, { id: pets.length + 1 }]);
      updateDiscount(pets.length + 1);
    }
  };

  const removePet = (index: number) => {
    const newPets = pets.filter((_, i) => i !== index);
    setPets(newPets);
    updateDiscount(newPets.length);
  };

  const updateDiscount = (numPets: number) => {
    if (numPets > 1) {
      setDiscount(10); // Group discount up to 10% for more than one pet
    } else {
      setDiscount(0);
    }
  };

  const handleSubmit = async (values: any) => {
    console.log("Form Submitted: ", values);

    const petDetails = pets.map((pet, index) => ({
      name: values[`petName${pet.id}`],
      breed: values[`petBreed${pet.id}`],
      type: values[`petType${pet.id}`],
      gender: values[`petGender${pet.id}`],
      neutered: values[`petNeutered${pet.id}`],
      therapyAnimal: values[`petTherapyAnimal${pet.id}`],
      birthYear: values[`petBirthYear${pet.id}`].year(),
      birthMonth: values[`petBirthMonth${pet.id}`].month() + 1,
      vet: values[`petVet${pet.id}`],
      microchip: values[`petMicrochip${pet.id}`],
    }));

    setLoading(true);

    try {
      // Simulate FetchPet API call
      const response = await fetch("https://api.fetchpet.com/get-quote", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pets: petDetails,
          email: values.email,
          phone: values.phone || null, // Phone number is optional
        }),
      });

      const data = await response.json();
      setQuotes(data);
    } catch (error) {
      console.error("Error fetching quotes:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="pet-insurance-page">
      <Header />

      <div className="insurance-form-container">
        <Title level={2}>Compare Pet Insurance Quotes</Title>
        <Text>
          Get the best quotes for your pets from providers like Fetch, Trupanion, and Spot.
        </Text>

        <Form onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please enter your email" },
                  { type: "email", message: "Please enter a valid email" },
                ]}
              >
                <Input placeholder="Enter your email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Phone Number (Optional)" name="phone">
                <Input placeholder="Enter your phone number" />
              </Form.Item>
            </Col>
          </Row>

          {pets.map((pet, index) => (
            <div key={pet.id} className="pet-section">
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label="Pet Name"
                    name={`petName${pet.id}`}
                    rules={[
                      { required: true, message: "Please enter your pet name" },
                    ]}
                  >
                    <Input placeholder="Enter pet name" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Pet Breed"
                    name={`petBreed${pet.id}`}
                    rules={[
                      { required: true, message: "Please enter pet breed" },
                    ]}
                  >
                    <Input placeholder="Enter pet breed" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Pet Type"
                    name={`petType${pet.id}`}
                    rules={[
                      { required: true, message: "Please select pet type" },
                    ]}
                  >
                    <Select placeholder="Select pet type">
                      <Option value="dog">Dog</Option>
                      <Option value="cat">Cat</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label="Gender"
                    name={`petGender${pet.id}`}
                    rules={[{ required: true, message: "Please select gender" }]}
                  >
                    <Select placeholder="Select gender">
                      <Option value="male">Male</Option>
                      <Option value="female">Female</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Neutered/Spayed"
                    name={`petNeutered${pet.id}`}
                    rules={[
                      { required: true, message: "Please select neuter status" },
                    ]}
                  >
                    <Select placeholder="Select neuter status">
                      <Option value="yes">Yes</Option>
                      <Option value="no">No</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Therapy/Assistance Animal"
                    name={`petTherapyAnimal${pet.id}`}
                    rules={[
                      { required: true, message: "Please select status" },
                    ]}
                  >
                    <Select placeholder="Select status">
                      <Option value="yes">Yes</Option>
                      <Option value="no">No</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label="Birth Year"
                    name={`petBirthYear${pet.id}`}
                    rules={[
                      { required: true, message: "Please select birth year" },
                    ]}
                  >
                    <DatePicker picker="year" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Birth Month"
                    name={`petBirthMonth${pet.id}`}
                    rules={[
                      { required: true, message: "Please select birth month" },
                    ]}
                  >
                    <DatePicker picker="month" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Primary Veterinarian"
                    name={`petVet${pet.id}`}
                    rules={[
                      { required: true, message: "Please select a veterinarian" },
                    ]}
                  >
                    <Select placeholder="Select veterinarian">
                      {veterinarians.map((vet) => (
                        <Option key={vet.id} value={vet.name}>
                          {vet.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label="Microchip Number (Optional)"
                    name={`petMicrochip${pet.id}`}
                  >
                    <Input placeholder="Enter microchip number" />
                  </Form.Item>
                </Col>
              </Row>

              {pets.length > 1 && (
                <Button
                  className="remove-pet-button"
                  onClick={() => removePet(index)}
                  icon={<MinusCircleOutlined />}
                  danger
                >
                  Remove Pet
                </Button>
              )}
            </div>
          ))}

          <Button
            type="dashed"
            className="add-pet-button"
            onClick={addPet}
            icon={<PlusOutlined />}
            block
          >
            Add Another Pet
          </Button>

          {discount > 0 && (
            <p className="discount-info">
              You qualify for a group discount of {discount}%!
            </p>
          )}

          <Button
            type="primary"
            htmlType="submit"
            className="submit-button"
            loading={loading}
          >
            {loading ? "Fetching Quotes..." : "Compare Quotes"}
          </Button>
        </Form>

        <div className="quote-cards">
          {quotes.length > 0 ? (
            quotes.map((quote, index) => {
              switch (quote.provider) {
                case "Fetch Insurance":
                  return (
                    <FetchInsurance
                      key={index}
                      price={quote.price}
                      coverage={quote.coverage}
                      reimbursement={quote.reimbursement}
                      support={quote.support}
                    />
                  );
                case "Spot Insurance":
                  return (
                    <SpotInsurance
                      key={index}
                      price={quote.price}
                      coverage={quote.coverage}
                      reimbursement={quote.reimbursement}
                      support={quote.support}
                    />
                  );
                case "Trupanion Insurance":
                  return (
                    <TrupanionInsurance
                      key={index}
                      price={quote.price}
                      coverage={quote.coverage}
                      reimbursement={quote.reimbursement}
                      support={quote.support}
                    />
                  );
                default:
                  return null;
              }
            })
          ) : (
            <p>No quotes available</p>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PetInsurancePage;
