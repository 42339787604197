import React from "react";
import { Typography, Button } from "antd";
import "./SuppCentInfo.css"; // Assuming you have corresponding CSS

const { Title, Paragraph } = Typography;

const SuppCentInfo = () => {
  return (
    <section className="feature-v2 cd-position-relative cd-z-index-1 cd-margin-y-2xl">
      <div className="cd-container cd-max-width-adaptive-lg">
        <p className="feature-v2__label">Welcome to the Support Center</p>

        <div className="feature-v2__grid">
          <div className="feature-v2__grid-item-1">
            <Title className="text-capitalize">
              Empowering Pet Care Professionals and Pet Owners
            </Title>
          </div>

          <div className="feature-v2__grid-item-2">
            <div className="feature-v2__content">
              

              <Paragraph>
                At Paw Pros, we're dedicated to providing you with the resources
                and support you need for a seamless experience. Whether you're a
                pet care professional or a pet owner, our Support Center is here
                to assist you every step of the way. From resolving issues
                promptly to ensuring clarity on our policies, we strive to make
                your journey with us as smooth and rewarding as possible.
              </Paragraph>

              <p>
                <Button type="primary" href="#0">
                  Learn more →
                </Button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SuppCentInfo;
