import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import  CustomerCommunication  from '../components/CustomerCommunication/CustomerCommunication';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';
import Spacer from '../components/Spacer/Spacer';

const MessagesPage = () => {
  return (
    <div>
      <Header />
      
      <Spacer />
      <CustomerCommunication />
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default MessagesPage;
