import React, { useState } from 'react';
import "./ContactInfo.css";
import "../../assets/styles/global.css";
import { Modal, Button, Form, Input, notification } from 'antd';
import LeafletMap from '../LeafletMap/LeafletMap';
import DetailsList from '../DetailsList/DetailsList';
import { Typography } from 'antd';
import Spacer from '../Spacer/Spacer';

const { Title } = Typography;

const ContactInfo: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/contact/addcontactus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: values.name,
          email: values.email,
          phone: values.phoneNumber || '',
          message: values.message,
        }),
      });

      if (response.ok) {
        notification.success({
          message: 'Success',
          description: 'Your message has been submitted successfully!',
        });
        setIsModalVisible(false);
      } else {
        notification.error({
          message: 'Error',
          description: 'There was an issue submitting your message. Please try again later.',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred. Please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="contact co1-position-relative co1-z-index-1">
      <div className="co1-container co1-max-width-lg">
        <div className="co1-margin-bottom-lg">
          <Title className="co1-text-center">Contact Us</Title>
        </div>

        <div className="co1-grid co1-gap-y-lg co1-gap-lg@md">
          <div className="co1-col-6@md">
            <DetailsList />
          </div>

          <div className="co1-radius-md co1-col-6@md co1-height-auto@md co1-padding-bottom-0@md">
            <LeafletMap latitude={45.4215} longitude={-75.6972} /> {/* Ottawa, ON */}
          </div>
        </div>
        <Spacer />
        <Spacer />
        <div className="co1-text-center co1-margin-top-md">
          <Button type="primary" onClick={showModal}>
            Send us a message
          </Button>
        </div>

        {/* Modal for contact form */}
        <Modal
          title="Contact Us"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            layout="vertical"
            name="contact_form"
            onFinish={handleSubmit}
            initialValues={{ remember: true }}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please input your name!' }]}
            >
              <Input placeholder="Your name" />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input placeholder="Your email" />
            </Form.Item>

            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[{ required: false, message: 'Enter phone number (optional)' }]}
            >
              <Input placeholder="Your phone number" />
            </Form.Item>

            <Form.Item
              label="Message"
              name="message"
              rules={[{ required: true, message: 'Please input your message!' }]}
            >
              <Input.TextArea rows={4} placeholder="Your message" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </section>
  );
};

export default ContactInfo;
