import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import CommunityHero from '../components/CommunityHero/CommunityHero';
import Spacer from '../components/Spacer/Spacer';
import CommunityMessage from '../components/CommunityMessage/CommunityMessage';
import CommunityOptionsGrid from '../components/CommunityOptionsGrid/CommunityOptionsGrid';
import Newsletter from '../components/Newsletter/Newsletter';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';

const BookingPage: React.FC = () => {
  return (
    <div>
      <Header />
      <Spacer />
      <CommunityHero />
      <CommunityMessage />
      <CommunityOptionsGrid />
      <Newsletter />
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default BookingPage;
