import React, { useState, useEffect } from 'react';
import { Card, Table, Button, Modal, Form, Input, message } from 'antd';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const UserAddress = () => {
  const { user, isAuthenticated } = useAuth0();
  const [addressData, setAddressData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchAddress = async () => {
      if (isAuthenticated && user) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/api/address/find/${user.sub}`);
          if (response.data) {
            const formattedData = [response.data].map((address, index) => ({
              key: index,
              name: address.name,
              street: address.street1,
              aptNumber: address.aptNumber || 'N/A',
              city: address.city,
              state: address.state,
              zipCode: address.zip,
              country: address.country,
            }));
            setAddressData(formattedData);
            form.setFieldsValue(response.data); // Populate the form with existing data
          }
        } catch (error) {
          message.error('Failed to fetch address data');
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchAddress();
  }, [isAuthenticated, user, form]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const url = addressData.length > 0 
        ? `${process.env.REACT_APP_BACKEND_URI}/api/address/update/${user.sub}` 
        : `${process.env.REACT_APP_BACKEND_URI}/api/address/`;

      const method = addressData.length > 0 ? 'PUT' : 'POST';

      const response = await axios({
        method,
        url,
        data: { ...values, userId: user.sub },
      });

      if (response.status === 200 || response.status === 201) {
        message.success(`Address ${addressData.length > 0 ? 'updated' : 'created'} successfully`);
        setAddressData([{ ...values, key: 0 }]); // Update the table data
        setIsModalVisible(false);
      }
    } catch (error) {
      message.error('Failed to save address');
      console.error(error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Street',
      dataIndex: 'street',
      key: 'street',
    },
    {
      title: 'Apt Number',
      dataIndex: 'aptNumber',
      key: 'aptNumber',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'Zip Code',
      dataIndex: 'zipCode',
      key: 'zipCode',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
  ];

  return (
    <Card
      title="User Address"
      extra={
        <Button type="primary" onClick={showModal}>
          {addressData.length > 0 ? 'Update Address' : 'Create Address'}
        </Button>
      }
    >
      <Table
        dataSource={addressData}
        columns={columns}
        pagination={false}
        loading={loading}
        className="address-table"
      />

      <Modal
        title={addressData.length > 0 ? 'Update Address' : 'Create Address'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={addressData.length > 0 ? addressData[0] : {}}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please enter the name!' }]}
          >
            <Input placeholder="Enter name" />
          </Form.Item>
          <Form.Item
            name="street1"
            label="Street"
            rules={[{ required: true, message: 'Please enter the street!' }]}
          >
            <Input placeholder="Enter street" />
          </Form.Item>
          <Form.Item
            name="aptNumber"
            label="Apt Number"
          >
            <Input placeholder="Enter apt number (optional)" />
          </Form.Item>
          <Form.Item
            name="city"
            label="City"
            rules={[{ required: true, message: 'Please enter the city!' }]}
          >
            <Input placeholder="Enter city" />
          </Form.Item>
          <Form.Item
            name="state"
            label="State"
            rules={[{ required: true, message: 'Please enter the state!' }]}
          >
            <Input placeholder="Enter state" />
          </Form.Item>
          <Form.Item
            name="zip"
            label="Zip Code"
            rules={[{ required: true, message: 'Please enter the zip code!' }]}
          >
            <Input placeholder="Enter zip code" />
          </Form.Item>
          <Form.Item
            name="country"
            label="Country"
            rules={[{ required: true, message: 'Please enter the country!' }]}
          >
            <Input placeholder="Enter country" />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default UserAddress;
