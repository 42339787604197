// SignUp.jsx
import React, { useState } from 'react';
import "./SignUp.css"; // Import the external CSS file
import { Button } from "antd";
import { Link } from 'react-router-dom';
import { Typography, Input } from 'antd';
import "../../assets/styles/global.css";
import { UserOutlined, KeyOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';

const { Text, Title, Paragraph } = Typography;

const SignUp = () => {
  const { loginWithRedirect } = useAuth0();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    await loginWithRedirect({
      screen_hint: 'signup',
      redirectUri: window.location.origin,
      login_hint: email,
    });
  };

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <div className="lq2-text-component lq2-text-center lq2-margin-bottom-sm">
        <Title level={2}>Sign Up</Title>
        <Text>Start your journey with Paw Pros now and give your pets the care they deserve!</Text>
      </div>
    
      <div className="lq2-margin-bottom-sm">
        <Paragraph className="lq2-form-label lq2-margin-bottom-3xs">First Name</Paragraph>
        <Input
          placeholder="Enter your first name"
          prefix={<UserOutlined />}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>

      <div className="lq2-margin-bottom-sm">
        <Paragraph className="lq2-form-label lq2-margin-bottom-3xs">Last Name</Paragraph>
        <Input
          placeholder="Enter your last name"
          prefix={<UserOutlined />}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>

      <div className="lq2-margin-bottom-sm">
        <Paragraph className="lq2-form-label lq2-margin-bottom-3xs" htmlFor="input-email">Email</Paragraph>
        <Input
          placeholder="Enter your email"
          prefix={<MailOutlined />}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="lq2-margin-bottom-sm">
        <Paragraph className="lq2-form-label lq2-margin-bottom-3xs" htmlFor="input-phone">Phone Number</Paragraph>
        <Input
          placeholder="Enter your phone number"
          prefix={<PhoneOutlined />}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </div>

      <div className="lq2-margin-bottom-sm">
        <div className="lq2-flex lq2-justify-between lq2-margin-bottom-3xs">
          <Paragraph className="lq2-form-label" htmlFor="input-password">Password</Paragraph>
        </div>

        <Input
          placeholder="Enter your password"
          prefix={<KeyOutlined />}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <div className="lq2-margin-bottom-sm">
        <Button type="primary" block htmlType="submit"><Text>Sign Up</Text></Button>
      </div>

      <div className="lq2-text-center">
        <Text className="lq2-text-sm">Already have an account? <Link to="/login">Log In</Link></Text>
      </div>
    </form>
  );
};

export default SignUp;
