import React from "react";
import Header from "../components/Header/Header";
import PageNotFound from "../components/PageNotFound/PageNotFound";
import Footer from "../components/Footer/Footer";
import DialogflowMessenger from "../components/DialogFlowMessenger/DialogFlowMessenger";

const PageNotFoundPage = () => {
  return (
    <div>
      <Header />
      <PageNotFound />
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default PageNotFoundPage;
