import React from "react";
import { Typography, Button } from "antd";
import "./HeroBappOne.css"; // Assuming you have corresponding CSS

const { Title, Paragraph } = Typography;

const HeroBappOne = () => {
  return (
    <section className="feature feature--center-x cd-position-relative cd-z-index-1 cd-margin-y-2xl">
      <div className="cd-container cd-max-width-adaptive-lg">
        <div className="feature__grid">
          <div>
            <div className="feature__content cd-text-center">
              <p className="feature__label">Ready to earn steady income?</p>
              <Title level={1}>Become a Paw Pro</Title>
              <Paragraph className="cd-line-height-lg">
              At Paw Pros, discover flexible opportunities that match your expertise and availability. We empower you to take charge of your schedule, offering the freedom and backing to run your own business.
              </Paragraph>
              <div className="feature__actions">
                <Button type="primary" >
                  Get Started
                </Button>
                <Button type="text" href="#0" >
                  Learn more
                </Button>
              </div>
            </div>
          </div>
          <div>
            <figure>
              <img
                className="feature__img"
                data-img-light
                src="https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587567/2_gpusbz.png"
                alt="Imagedescription"
              />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroBappOne;
