import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import './RelatedProducts.css';
import "../../assets/styles/global.css";
import Spacer from '../Spacer/Spacer';

const { Title } = Typography; 
const { Meta } = Card;

const RelatedProducts = ({ category }) => {
  const [relatedProducts, setRelatedProducts] = useState([]);

  useEffect(() => {
    const fetchRelatedProducts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/products/category/${category}?limit=4`);
        const data = await response.json();
        setRelatedProducts(data);
      } catch (error) {
        console.error('Failed to fetch related products:', error);
      }
    };

    if (category) {
      fetchRelatedProducts();
    }
  }, [category]);

  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
      <div className="related-products">
        <Title level={3}>Related Products</Title>
        <Spacer />
        <Row gutter={[16, 16]}>
          {relatedProducts.map((product, index) => (
            <Col key={index} xs={24} sm={12} md={12} lg={6}>
              <Link to={`/product/${product._id}`}>
                <Card
                  hoverable
                  cover={<img alt={product.title} src={product.imageUrl} />}
                >
                  <Meta title={product.title} description={product.description} />
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
};

export default RelatedProducts;
