import React, { useState } from 'react';
import { Button } from 'antd';
import './UserNav.css';
import '../../assets/styles/global.css';
import { UserOutlined, EnvironmentOutlined, AlignCenterOutlined, QqOutlined, CreditCardOutlined } from '@ant-design/icons';  

const UserNav = ({ activeCategory, setActiveCategory }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSubNav = () => {
    setIsOpen(!isOpen);
  };

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    setIsOpen(false); // Close the navigation after selecting an item
  };

  return (
    <div className="si6-bg-dark">
      <div className="si6-container si6-max-width-lg">
        <div className={`subnav js-subnav ${isOpen ? 'subnav--open' : ''}`}>
          <button
            className="si6-btn si6-btn--subtle si6-margin-y-sm subnav__control js-subnav__control"
            onClick={toggleSubNav}
            aria-expanded={isOpen ? 'true' : 'false'}
          >
            <span>Show Categories</span>
            <svg className="si6-icon si6-icon--2xs si6-margin-left-2xs" aria-hidden="true" viewBox="0 0 12 12">
              <polyline points="0.5 3.5 6 9.5 11.5 3.5" fill="none" strokeWidth="1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></polyline>
            </svg>
          </button>

          <div className="subnav__wrapper js-subnav__wrapper">
            <nav className="subnav__nav si6-justify-center">
              <button className="subnav__close-btn js-subnav__close-btn js-tab-focus" aria-label="Close navigation" onClick={toggleSubNav}>
                <svg className="si6-icon" viewBox="0 0 16 16">
                  <g strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
                    <line x1="13.5" y1="2.5" x2="2.5" y2="13.5"></line>
                    <line x1="2.5" y1="2.5" x2="13.5" y2="13.5"></line>
                  </g>
                </svg>
              </button>

              <ul className="subnav__list">
                <li className="subnav__item">
                  <a
                    href="#0"
                    className="subnav__link"
                    aria-current={activeCategory === 'user-info' ? 'page' : undefined}
                    onClick={() => handleCategoryClick('user-info')}
                  >
                    <Button type="text"><UserOutlined /> User Info</Button>
                  </a>
                </li>
                <li className="subnav__item">
                  <a
                    href="#0"
                    className="subnav__link"
                    aria-current={activeCategory === 'address' ? 'page' : undefined}
                    onClick={() => handleCategoryClick('address')}
                  >
                    <Button type="text"><EnvironmentOutlined /> Address</Button>
                  </a>
                </li>
                <li className="subnav__item">
                  <a
                    href="#0"
                    className="subnav__link"
                    aria-current={activeCategory === 'pets' ? 'page' : undefined}
                    onClick={() => handleCategoryClick('pets')}
                  >
                    <Button type="text"><QqOutlined /> Pets</Button>
                  </a>
                </li>
                <li className="subnav__item">
                  <a
                    href="#0"
                    className="subnav__link"
                    aria-current={activeCategory === 'history' ? 'page' : undefined}
                    onClick={() => handleCategoryClick('history')}
                  >
                    <Button type="text"><CreditCardOutlined /> History</Button>
                  </a>
                </li>
                <li className="subnav__item">
                  <a
                    href="#0"
                    className="subnav__link"
                    aria-current={activeCategory === 'more' ? 'page' : undefined}
                    onClick={() => handleCategoryClick('more')}
                  >
                    <Button type="text"><AlignCenterOutlined /> More</Button>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserNav;
