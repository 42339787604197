import React from 'react';
import Header from '../components/Header/Header';
import SignUp from '../components/SignUp/SignUp';
import Footer from '../components/Footer/Footer';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';
const SignUpPage = () => {
  return (
    <div>
      <Header />
      <SignUp />
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default SignUpPage;
