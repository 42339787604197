import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import './ShopProductGrid.css';
import '../../assets/styles/global.css';

const { Meta } = Card;

const ShopProductGrid = ({ activeCategory }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [products, setProducts] = useState([]);
  const pageSize = 8;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/products/category/${activeCategory}`);
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Failed to fetch products:', error);
      }
    };

    fetchProducts();
  }, [activeCategory]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const currentProducts = products.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
      <div className="shop-product-grid">
        <Row gutter={[16, 16]}>
          {currentProducts.map((product, index) => (
            <Col key={index} xs={24} sm={12} md={8} lg={6}>
              <Link to={`/product/${product._id}`}>
                <Card hoverable cover={<img alt={product.title} src={product.imageUrl} />}>
                  <Meta title={product.title} description={`$${product.price}`} />
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
        <div className="pagination-container">
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={products.length}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </section>
  );
};

export default ShopProductGrid;
