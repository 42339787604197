import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import BookVet from '../../components/BookVet/BookVet';
import { useBookingContext } from '../../context/BookingContext'; // Import BookingContext
import { useNavigate } from 'react-router-dom'; // Import navigation hook
import { Dayjs } from 'dayjs'; // Import Dayjs for date and time handling

const VetinaryServicesPage: React.FC = () => {
  const { updateBookingDetails } = useBookingContext(); // Use BookingContext
  const navigate = useNavigate(); // Use the navigate hook

  const handleChoosePetClick = (date: Dayjs | null, time: Dayjs | null) => {
    // Store the booking data in the BookingContext
    updateBookingDetails('date', date);
    updateBookingDetails('time', time);

    // Navigate to the PetChoicePage
    navigate('/choose-pet', { state: { service: 'Vetinary Services' } });
  };

  return (
    <div className="vetinary-services-page">
      <Header />
      <div className="vetinary-services-content">
        <BookVet onChoosePetClick={handleChoosePetClick} />  {/* Pass the handler to BookVet */}
      </div>
      <Footer />
    </div>
  );
};

export default VetinaryServicesPage;
