import React from 'react';
import { Typography, Row, Col, Card } from 'antd';
import './PPTeam.css';

const { Title, Paragraph } = Typography;

const teamData = [
  {
    section: 'Management',
    members: [
      { name: 'Michael Nwokobia', title: 'Chief Executive Officer (CEO) / Head of Frontend Development', picture: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116117/Logo_rsepw8.jpg' },
      { name: 'Emmanuel Nwokobia', title: 'Chief Operations Officer (COO)', picture: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116117/Logo_rsepw8.jpg' },
      { name: 'Anne Duret', title: 'Chief Marketing Officer (CMO) / Public Relations Director', picture: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116117/Logo_rsepw8.jpg' },
    ],
  },
  {
    section: 'Frontend Team',
    members: [
      { name: 'Michael Nwokobia', title: 'Lead Frontend Developer / Chief Executive Officer (CEO)', picture: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116117/Logo_rsepw8.jpg' },
      { name: 'Temitayo Oyelowo', title: 'Senior Frontend Developer', picture: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116117/Logo_rsepw8.jpg' },
    ],
  },
  {
    section: 'Backend Team',
    members: [
      { name: 'Temitayo Oyelowo', title: 'Lead Backend Developer / Senior Frontend Developer', picture: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116117/Logo_rsepw8.jpg' },
      { name: 'Ajayi Emmanuel', title: 'Backend Developer', picture: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116117/Logo_rsepw8.jpg' },
      { name: 'Michael Nwokobia', title: 'Senior Backend Developer / Chief Executive Officer (CEO)', picture: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116117/Logo_rsepw8.jpg' },
    ],
  },
  {
    section: 'Individual Contributors',
    members: [
      { name: 'Samson Osadolor', title: 'Database Administrator (DBA)', picture: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116117/Logo_rsepw8.jpg' },
      { name: 'Fumnanya Nwokobia', title: 'Chief Financial Officer (CFO)', picture: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116117/Logo_rsepw8.jpg' },
      { name: 'Joseph Agbogu', title: 'Marketing Strategist / Business Development Analyst', picture: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116117/Logo_rsepw8.jpg' },
    ],
  },
  {
    section: 'Business & Sales',
    members: [
      { name: 'Anne Duret', title: 'Chief Marketing Officer (CMO) / Director of Business Development', picture: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116117/Logo_rsepw8.jpg' },
    ],
  },
  {
    section: 'Public Relations',
    members: [
      { name: 'Anne Duret', title: 'Chief Marketing Officer (CMO) / Public Relations Director', picture: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116117/Logo_rsepw8.jpg' },
      { name: 'Mine Harit', title: 'Senior Public Relations Officer', picture: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1720116117/Logo_rsepw8.jpg' },
 
    ],
  },
];

const PPTeam = () => {
  return (
    <div className="ppteam-container">
      <Title level={2} className="ppteam-title">Our Team</Title>
      {teamData.map((section, index) => (
        <div key={index} className="ppteam-section">
          <Title level={3} className="ppteam-section-title">{section.section}</Title>
          <Row gutter={[16, 16]} justify="center">
            {section.members.map((member, idx) => (
              <Col key={idx} xs={24} sm={12} md={8} lg={6}>
                <Card className="ppteam-card">
                  <div className="ppteam-card-content">
                    <img src={member.picture} alt={member.name} className="ppteam-member-picture" />
                    <Paragraph className="ppteam-member-name"><strong>{member.name}</strong></Paragraph>
                    <Paragraph className="ppteam-member-title">{member.title}</Paragraph>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </div>
  );
};

export default PPTeam;
