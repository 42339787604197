import React from 'react';
import { Typography, Button } from 'antd';
import './PawFindHero.css'; // Ensure this CSS file is correctly set up in your project
import '../../assets/styles/global.css'; // Importing global styles
import { Link } from 'react-router-dom';

const { Title, Text, Paragraph } = Typography;

const PawFindHero = () => {
  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
    <section className="cd-position-relative cd-z-index-1">
      <div className="cd-position-relative cd-z-index-2">
        <div style={{ height: '600px', padding: '100px 20px', textAlign: 'left' }}>
          <Text type="secondary">Lost and Found Pet Service</Text>
          <Title level={1}>Paw Finder</Title>
          <Paragraph>
          PawFinder is a free, comprehensive lost and found pet service provided by Paw Pros. Our mission is to reunite lost pets with their families as quickly and efficiently as possible. With easy reporting, community support, and access to local animal services, PawFinder is your trusted partner in ensuring the safety and well-being of your beloved pets. Whether you’ve lost a pet or found one, PawFinder is here to help bring pets home where they belong.
          </Paragraph>
          <div style={{ marginTop: '20px' }}>
            <Link  to="/paw-finder-map">
            <Button type="primary" size="large" style={{ marginRight: '10px' }}>
              Paw Finder Map
            </Button>
            </Link>
            <Button type="link" size="large">
              About Us
            </Button>
          </div>
        </div>
      </div>
      <figure className="bg-decoration cd-z-index-1" aria-hidden="true">
        <svg className="bg-decoration__svg" viewBox="0 0 1920 450" fill="none">
          <rect
            opacity="0.5"
            x="1410.61"
            y="42.6123"
            width="246.643"
            height="304.604"
            transform="rotate(-45 1410.61 42.6123)"
            fill="url(#bg-decoration-v1-fx-1-linear-1)"
          />
          <rect
            opacity="0.5"
            x="1176"
            y="-20.5969"
            width="246.643"
            height="304.604"
            transform="rotate(-45 1176 -20.5969)"
            fill="url(#bg-decoration-v1-fx-1-linear-2)"
          />
          <circle
            opacity="0.5"
            cx="1572"
            cy="205"
            r="46"
            fill="url(#bg-decoration-v1-fx-1-radial-1)"
          />
          <g opacity="0.5" filter="url(#bg-decoration-v1-fx-1-blur-1)">
            <circle cx="1680" cy="324" r="26" fill="var(--bg-decoration-color-3)" />
          </g>
          <defs>
            <filter id="bg-decoration-v1-fx-1-blur-1" x="1644" y="288" width="72" height="72" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur" />
            </filter>
            <linearGradient id="bg-decoration-v1-fx-1-linear-1" x1="1533.93" y1="42.6123" x2="1533.93" y2="347.217" gradientUnits="userSpaceOnUse">
              <stop stopColor="var(--bg-decoration-color-1)" />
              <stop offset="1" stopColor="var(--bg-decoration-color-1)" stopOpacity="0" />
            </linearGradient>
            <linearGradient id="bg-decoration-v1-fx-1-linear-2" x1="1299.32" y1="-20.5969" x2="1299.32" y2="284.007" gradientUnits="userSpaceOnUse">
              <stop stopColor="var(--bg-decoration-color-1)" />
              <stop offset="1" stopColor="var(--bg-decoration-color-1)" stopOpacity="0" />
            </linearGradient>
            <radialGradient id="bg-decoration-v1-fx-1-radial-1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1572 205) rotate(90) scale(46)">
              <stop stopColor="var(--bg-decoration-color-3)" stopOpacity="0" />
              <stop offset="1" stopColor="var(--bg-decoration-color-3)" />
            </radialGradient>
          </defs>
        </svg>
      </figure>
    </section>
    </section>
  );
};

export default PawFindHero;
