import React, { useState } from 'react';
import { DatePicker, TimePicker, Button, Radio, Row, Col, Typography, Form } from 'antd';
import { Dayjs } from 'dayjs'; // Use Dayjs for date handling
import './PetShoot.css'; // Custom styles for PetShoot

const { Title } = Typography;

interface PetShootProps {
  onChoosePetClick: (date: Dayjs | null, time: Dayjs | null, location: string | null) => void; // Callback prop for "Choose Pet" click
}

const PetShoot: React.FC<PetShootProps> = ({ onChoosePetClick }) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(null);
  const [locationChoice, setLocationChoice] = useState<string | null>(null);

  // DatePicker onChange handler
  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date); 
  };

  // TimePicker onChange handler
  const handleTimeChange = (time: Dayjs | null) => {
    setSelectedTime(time); 
  };

  const handleLocationChange = (e: any) => {
    setLocationChoice(e.target.value);
  };

  const isButtonDisabled = !selectedDate || !selectedTime || !locationChoice;

  const handleChoosePetClick = () => {
    // Pass the selected date, time, and location to the parent component
    onChoosePetClick(selectedDate, selectedTime, locationChoice); 
  };

  return (
    <div className="pet-shoot-container">
      <Title level={2} className="pet-shoot-title">Schedule a Pet Photography Session</Title>
      <Form layout="vertical">
        <Row gutter={16} style={{ marginBottom: '20px' }}>
          <Col span={12}>
            <Form.Item>
              <DatePicker
                placeholder="Select date"
                onChange={handleDateChange} 
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <TimePicker
                placeholder="Select time"
                onChange={handleTimeChange} 
                use12Hours
                format="h:mm a"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Title level={4} className="location-title">Choose Location</Title>
        <Radio.Group onChange={handleLocationChange} value={locationChoice}>
          <Radio value="special-location">Special Location</Radio>
          <Radio value="in-studio">In-Studio</Radio>
        </Radio.Group>

        {/* Placeholder for pet shoot image */}
        <div className="shoot-image-placeholder">
          <img
            src="https://res.cloudinary.com/daq4ywu5y/image/upload/v1725938061/53E3FA86-79E0-4DA0-8F05-B319D9E68E16_gdeakg.jpg"
            alt="Pet Photography"
            style={{ width: '100%', height: '300px', marginBottom: '20px' }}
          />
        </div>

        <Button
          type="primary"
          block
          size="large"
          disabled={isButtonDisabled}
          onClick={handleChoosePetClick} 
          style={{ height: '50px', fontSize: '18px' }}
        >
          Choose Pet
        </Button>
      </Form>
    </div>
  );
};

export default PetShoot;
