import React, { useState } from 'react';
import { DatePicker, TimePicker, Button, Form, Row, Col, message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { useBookingContext } from '../../context/BookingContext'; // Import the BookingContext

const { RangePicker } = DatePicker;

const PetBoardingPage: React.FC = () => {
  const [form] = Form.useForm();
  const [selectedDates, setSelectedDates] = useState<[Dayjs, Dayjs] | null>(null);
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(null);
  const { updateBookingDetails } = useBookingContext(); // Access updateBookingDetails from the context
  const navigate = useNavigate(); // Use navigate for redirection

  const handleDateChange = (dates: [Dayjs, Dayjs] | null) => {
    setSelectedDates(dates);
  };

  const handleTimeChange = (time: Dayjs | null) => {
    setSelectedTime(time);
  };

  const handleChoosePet = () => {
    if (!selectedDates || !selectedTime) {
      message.error('Please select both a date range and a time!');
      return;
    }

    // Store the booking data in the context
    updateBookingDetails('selectedDates', selectedDates);
    updateBookingDetails('selectedTime', selectedTime);

    // Navigate to the PetChoicePage
    navigate('/choose-pet', {
      state: { service: 'boarding' }, // Pass the service type in the state
    });
  };

  return (
    <div className="pet-boarding-page">
      <Header />

      <section className="ta0-container ta0-max-width-adaptive-lg">
        <h2>Book a Pet Boarding Service</h2>
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Select Date Range"
                rules={[{ required: true, message: 'Please select a date range!' }]}
              >
                <RangePicker
                  onChange={(dates) => handleDateChange(dates as [Dayjs, Dayjs])}
                  format="YYYY-MM-DD"
                  disabledDate={(current) => {
                    const today = dayjs();
                    const threeMonthsFromNow = dayjs().add(3, 'months');
                    return current && (current < today || current > threeMonthsFromNow);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Select Time"
                rules={[{ required: true, message: 'Please select a time!' }]}
              >
                <TimePicker
                  use12Hours
                  format="h:mm a"
                  onChange={handleTimeChange}
                  value={selectedTime}
                />
              </Form.Item>
            </Col>
          </Row>

          <Button
            type="primary"
            onClick={handleChoosePet}
            disabled={!selectedDates || !selectedTime}
            block
          >
            Choose Pet
          </Button>
        </Form>
      </section>

      <Footer />
    </div>
  );
};

export default PetBoardingPage;
