import React, { useState } from 'react';
import { Layout } from 'antd';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';
import UserInfo from '../components/UserInfo/UserInfo';
import Pets from '../components/UserPets/UserPets';
import Address from '../components/UserAddress/UserAddress';
import UserBanner from '../components/UserBanner/UserBanner';
import Spacer from '../components/Spacer/Spacer';
import './styles/UserAccountPage.css';
import UserNav from '../components/UserNav/UserNav';

const { Content } = Layout;

// Define a type for the menu items
type MenuItem = 'user-info' | 'pets' | 'address' | 'history' | 'more';

const UserAccountPage: React.FC = () => {
  // State to manage the selected menu item
  const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItem>('user-info');

  // Function to render content based on the selected menu item
  const renderContent = () => {
    switch (selectedMenuItem) {
      case 'user-info':
        return <UserInfo />;
      case 'pets':
        return <Pets />;
      case 'address':
        return <Address />;
      case 'history':
        return <div>History Component</div>;
      case 'more':
        return <div>More Component</div>;
      default:
        return <UserInfo />;
    }
  };

  return (
    <div>
      <Header />
      <Spacer />
      <UserBanner />
      <Spacer />
      <UserNav activeCategory={selectedMenuItem} setActiveCategory={setSelectedMenuItem} />
      <Spacer />
      <section className="ta0-container ta0-max-width-adaptive-lg">
        <Layout>
          <Content className="site-layout">
            <div className="content-inner">
              {renderContent()}
            </div>
          </Content>
        </Layout>
      </section>
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default UserAccountPage;
