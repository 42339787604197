import React from 'react';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import CartItems from '../components/CartItems/CartItems';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';

const CartPage: React.FC = () => {
  return (
    <div>
      <Header />
      <CartItems />
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default CartPage;
