import React from 'react';
import { Typography } from 'antd'; 
import { SignatureOutlined, CarOutlined, IdcardOutlined, DollarOutlined, FileDoneOutlined, UserAddOutlined } from '@ant-design/icons';
import './BappFeatures.css'; // Assuming you have corresponding CSS

const {Title, Paragraph} = Typography;

const FeatureItem = ({ icon, title, description }) => (
  <li className="feature-v7__grid-item">
    <div className="feature-v7__sub-grid cd-items-center">
      <figure className="feature-v7__img">
        {icon}
      </figure>
      <Title level={4}>{title}</Title>
    </div>
    <div className="feature-v7__sub-grid">
      <div className="feature-v7__img-clone" aria-hidden="true"></div>
      <Paragraph className="feature-v7__description">{description}</Paragraph>
    </div>
  </li>
);

const BappFeatures = () => {
  return (
    <section className="feature-v7 cd-position-relative cd-z-index-1 cd-padding-y-2xl">
      <section maxWidth="lg" className="cd-container cd-max-width-adaptive-lg">
        <div className="cd-margin-bottom-xl">
          <Title level={2} className="cd-text-center">How To Get Started</Title>
        </div>

        <ul className="feature-v7__grid">
          <FeatureItem
            icon={<SignatureOutlined />}
            title="Sign Up"
            description="Create your account and begin your journey by downloading the Paw Pros app to complete your registration."
          />

          <FeatureItem
            icon={<IdcardOutlined />}
            title="Register Your Information"
            description="Choose from our range of pet services and specify your service areas."
          />

          <FeatureItem
            icon={<FileDoneOutlined />}
            title="Eligibilty Verification"
            description="Submit necessary business verifications and background check documents to ensure a safe platform for all pet owners."
          />

          <FeatureItem
            icon={<DollarOutlined />}
            title="Pay Sign Up Fees"
            description="In eligible cities, a $35 registration fee applies to help us deliver top-notch service."
          />

          <FeatureItem
            icon={<CarOutlined />}
            title="Set Area Coverage & Availabilty"
            description="Define your weekly availability and opt-in for same-day job notifications."
          />

          <FeatureItem
            icon={<UserAddOutlined />}
            title="Become a Paw Pro "
            description="Start growing your pet care business on your terms with Paw Pros."
          />
        </ul>
      </section>
    </section>
  );
};

export default BappFeatures;
