import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import PawFinderHero from '../components/PawFinderHero/PawFinderHero';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';
import PawFindFeat from '../components/PawFindFeat/PawFindFeat';
import Spacer from '../components/Spacer/Spacer';
import PawFindHero from '../components/PawFindHero/PawFindHero';

const PawFinderPage = () => {
  return (
    <div>
      <Header />
      <PawFindHero />
      
      <PawFindFeat />
      <Spacer />
      <PawFinderHero />
      <Spacer />
   
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default PawFinderPage;
