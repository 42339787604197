import React, { useState } from 'react';
import { Input, Button, Typography, Form, message } from 'antd';
import axios from 'axios';
import './Newsletter.css';

const { Title, Paragraph } = Typography;

const Newsletter: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  // Check if the email is already subscribed
  const checkSubscriber = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URI}/api/subscriber/checksubscriber`, { email });
      return response.data.message === 'Email is already registered';
    } catch (error) {
      console.error('Error checking subscriber:', error);
      return false;
    }
  };

  // Add a new subscriber
  const addSubscriber = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URI}/api/subscriber/addsubscriber`, { name, email });
      message.success(`Thank you, ${name}! You have successfully subscribed to our newsletter.`);
    } catch (error) {
      message.error('Failed to subscribe. Please try again later.');
      console.error('Error adding subscriber:', error);
    }
  };

  const handleSubscribe = async () => {
    if (!name || !email) {
      message.error('Please enter both your name and email!');
      return;
    }

    setLoading(true);

    // Check if email is already registered
    const alreadySubscribed = await checkSubscriber();
    if (alreadySubscribed) {
      message.error('This email is already subscribed.');
      setLoading(false);
      return;
    }

    // Add subscriber if email is not already registered
    await addSubscriber();

    setLoading(false);
    setName('');
    setEmail('');
  };

  return (
    <div className="newsletter-container">
      <div className="newsletter-content">
        <Title level={2} className="newsletter-title">Subscribe to Our Newsletter</Title>
        <Paragraph className="newsletter-paragraph">
          Stay updated with the latest news, offers, and trends from our community. Enter your name and email below and never miss out!
        </Paragraph>
        <Form className="newsletter-form" onFinish={handleSubscribe}>
          <Form.Item className="newsletter-form-item">
            <Input 
              placeholder="Your Name" 
              value={name} 
              onChange={e => setName(e.target.value)} 
              size="large" 
              className="newsletter-input"
            />
          </Form.Item>
          <Form.Item className="newsletter-form-item">
            <Input 
              placeholder="Your Email" 
              value={email} 
              onChange={e => setEmail(e.target.value)} 
              size="large" 
              className="newsletter-input"
            />
          </Form.Item>
          <Form.Item className="newsletter-form-item-button">
            <Button type="primary" size="large" loading={loading} className="subscribe-button" onClick={handleSubscribe}>
              Subscribe
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Newsletter;
