import React from 'react';
import DogWalkingComponent from '../../components/DogWalkingComponent/DogWalkingComponent';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import { useBookingContext } from '../../context/BookingContext';  // Import BookingContext

const DogWalkingPage: React.FC = () => {
  const { bookingDetails } = useBookingContext();  // Access bookingDetails from the context
  const navigate = useNavigate();  // Use navigate for redirection

  // Handle when the user chooses to proceed after selecting date/time/locations in the DogWalkingComponent
  const handleChoosePetClick = () => {
    // Navigate to the PetChoicePage when booking details are complete
    navigate('/choose-pet', {
      state: { service: 'Dog Walking', bookingData: bookingDetails },
    });
  };

  return (
    <div>
      <Header />
      <div style={{ padding: '20px' }}>
        <DogWalkingComponent onChoosePetClick={handleChoosePetClick} />  
      </div>
      <Footer />
    </div>
  );
};

export default DogWalkingPage;
