import React from 'react';
import { Typography } from 'antd';
import { TwitterCircleFilled, FacebookFilled, YoutubeFilled, SlackCircleFilled, InstagramFilled } from '@ant-design/icons';
import './Socials.css';
import "../../assets/styles/global.css";
import {Link} from "react-router-dom";
const { Title } = Typography;

const Socials = () => {
  return (
    <section className="socials sk4-text-center">
      <div className="sk4-container sk4-max-width-md">
        <div className="sk4-margin-bottom-lg">
          <Title level={3}>Contact us on our social media platforms</Title>
        </div>
        <ul className="socials__btns sk4-flex sk4-flex-center sk4-gap-sm sk4-flex-wrap">
          <li>
            <Link to="/">
            <TwitterCircleFilled className="sk4-icon" />
            </Link>
          </li>
          <li>
          <Link to="https://www.instagram.com/pawsandpros">
            <InstagramFilled className="sk4-icon" />
            </Link>
          </li>
          <li>
          <Link to="/">
            <SlackCircleFilled className="sk4-icon" />
            </Link>
          </li>
          <li>
          <Link to="/">
            <YoutubeFilled className="sk4-icon" />
            </Link>
          </li>
          <li>
          <Link to="/">
            <FacebookFilled className="sk4-icon" />
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Socials;
