import React from 'react';
import { Typography } from 'antd';
import './CommunityMessage.css';
import '../../assets/styles/global.css';

const { Text } = Typography;

const CommunityMessage: React.FC = () => {
  return (
    <section className="testimonial tf8-position-relative tf8-z-index-1 tf8-padding-y-2xl">
      <div className="tf8-container tf8-max-width-adaptive-sm">
        <figure className="tf8-flex tf8-justify-center tf8-margin-bottom-md reveal-fx reveal-fx--scale">
          <img
            className="tf8-block tf8-width-2xl tf8-height-2xl tf8-radius-50% tf8-border tf8-border-bg tf8-border-2 tf8-shadow-sm"
            src="https://res.cloudinary.com/daq4ywu5y/image/upload/v1725825092/1_lecdga.png"
            alt="Testimonialpicture"
          />
        </figure>

        <div className="testimonial__block-wrapper tf8-margin-bottom-lg">
          <blockquote className="tf8-text-lg tf8-text-center tf8-line-height-md">
            Our community is built on a shared love for pets, where we connect passionate pet owners with trusted providers. Together, we create a supportive environment where every pet can thrive, and every owner feels confident in the care their furry friend receives. A strong, pet-loving community means happier pets, better care, and lasting relationships.
          </blockquote>

          <svg className="tf8-icon tf8-icon--2xl tf8-color-contrast-higher tf8-opacity-10%" aria-hidden="true" viewBox="0 0 64 64">
            <polygon fill="currentColor" points="2 36 17 2 26 2 15 36 26 36 26 62 2 62 2 36" />
            <polygon fill="currentColor" points="38 36 53 2 62 2 51 36 62 36 62 62 38 62 38 36" />
          </svg>
        </div>

        <div className="tf8-text-center">
          <Text className="tf8-text-uppercase tf8-letter-spacing-md"><strong>John Thomas</strong></Text>
          <Text className="tf8-color-contrast-medium tf8-margin-top-4xs">Pet Care Enthusiast</Text>
        </div>
      </div>
    </section>
  );
};

export default CommunityMessage;
