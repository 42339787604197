import React, { useState } from 'react';
import { DatePicker, TimePicker, Button, Form, Typography, Row, Col, Card } from 'antd';
import { Dayjs } from 'dayjs'; // Use Dayjs instead of Moment
import './FuneralServe.css'; // Add custom CSS if needed

const { Title } = Typography;

interface FuneralServeProps {
  onChoosePetClick: (date: Dayjs | null, time: Dayjs | null, service: string, addons: string[]) => void;  // Pass date, time, service, and addons
}

const FuneralServe: React.FC<FuneralServeProps> = ({ onChoosePetClick }) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(null); 
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [selectedAddons, setSelectedAddons] = useState<string[]>([]);

  const services = [
    { name: 'Burial', imgUrl: 'https://via.placeholder.com/100x100?text=Burial' },
    { name: 'Cremation', imgUrl: 'https://via.placeholder.com/100x100?text=Cremation' },
    { name: 'Aquamation', imgUrl: 'https://via.placeholder.com/100x100?text=Aquamation' },
    { name: 'Preservation', imgUrl: 'https://via.placeholder.com/100x100?text=Preservation' }
  ];

  const addons = [
    { name: 'Memoriam Ad (7 Days)', imgUrl: 'https://via.placeholder.com/100x100?text=Memoriam+Ad' },
    { name: 'Memoriam (In-Person)', imgUrl: 'https://via.placeholder.com/100x100?text=Memoriam+In-Person' },
    { name: 'Custom Urn', imgUrl: 'https://via.placeholder.com/100x100?text=Custom+Urn' },
    { name: 'Plant a Tree', imgUrl: 'https://via.placeholder.com/100x100?text=Plant+a+Tree' }
  ];

  const handleServiceClick = (service: string) => {
    setSelectedService(service);
  };

  const handleAddonClick = (addon: string) => {
    if (selectedAddons.includes(addon)) {
      setSelectedAddons(selectedAddons.filter(a => a !== addon));
    } else {
      setSelectedAddons([...selectedAddons, addon]);
    }
  };

  const handleChoosePet = () => {
    if (onChoosePetClick && selectedService && selectedDate && selectedTime) {
      onChoosePetClick(selectedDate, selectedTime, selectedService, selectedAddons); // Pass booking data to parent
    }
  };

  return (
    <div className="funeral-serve-container">
      <Title level={2}>Honor Your Pet the Right Way</Title>

      <Form layout="vertical" className="funeral-form">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Date">
              <DatePicker 
                onChange={(date) => setSelectedDate(date)} 
                format="YYYY-MM-DD"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Time">
              <TimePicker 
                onChange={(time) => setSelectedTime(time)}  
                use12Hours 
                format="h:mm a" 
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Title level={4} className="service-title">Choose Service Type</Title>
        <Row gutter={[16, 16]}>
          {services.map(service => (
            <Col span={6} key={service.name}>
              <Card 
                className={`service-card ${selectedService === service.name ? 'selected' : ''}`}
                onClick={() => handleServiceClick(service.name)}
              >
                <img src={service.imgUrl} alt={service.name} className="service-image" />
                <p>{service.name}</p>
              </Card>
            </Col>
          ))}
        </Row>

        <Title level={4} className="addon-title">Add-ons</Title>
        <Row gutter={[16, 16]}>
          {addons.map(addon => (
            <Col span={6} key={addon.name}>
              <Card 
                className={`addon-card ${selectedAddons.includes(addon.name) ? 'selected' : ''}`}
                onClick={() => handleAddonClick(addon.name)}
              >
                <img src={addon.imgUrl} alt={addon.name} className="addon-image" />
                <p>{addon.name}</p>
              </Card>
            </Col>
          ))}
        </Row>

        <Form.Item>
          <Button 
            type="primary" 
            size="large" 
            block 
            onClick={handleChoosePet}
            disabled={!selectedDate || !selectedTime || !selectedService}
          >
            Choose Pet
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FuneralServe;
