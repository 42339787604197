import React, { useState } from 'react';
import { DatePicker, TimePicker, Button, Row, Col, Typography, Form } from 'antd';
import { Dayjs } from 'dayjs'; // Import Dayjs for date and time handling
import './GroomPet.css'; // Custom styles for GroomPet

const { Title } = Typography;

interface GroomPetProps {
  onChoosePetClick: (date: Dayjs | null, time: Dayjs | null) => void; // Pass date and time to the parent
}

const GroomPet: React.FC<GroomPetProps> = ({ onChoosePetClick }) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(null);

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time: Dayjs | null) => {
    setSelectedTime(time);
  };

  const handleButtonClick = () => {
    // Pass the selected date and time to the parent component
    onChoosePetClick(selectedDate, selectedTime);
  };

  const isButtonDisabled = !selectedDate || !selectedTime;

  return (
    <div className="groom-pet-container">
      <Title level={2} className="groom-pet-title">Schedule a Grooming Session</Title>
      <Form layout="vertical">
        <Row gutter={16} style={{ marginBottom: '20px' }}>
          <Col span={12}>
            <Form.Item>
              <DatePicker
                placeholder="Select date"
                onChange={handleDateChange}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <TimePicker
                placeholder="Select time"
                onChange={handleTimeChange}
                use12Hours
                format="h:mm a"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Placeholder for grooming image */}
        <div className="grooming-image-placeholder">
          <img
            src="https://res.cloudinary.com/daq4ywu5y/image/upload/v1725936536/70D82DAB-A86A-449C-9416-000D715A9BC4_gkrn9e.jpg"
            alt="Grooming"
            style={{ width: '100%', height: '300px', marginBottom: '20px' }}
          />
        </div>

        <Button
          type="primary"
          block
          size="large"
          disabled={isButtonDisabled}
          onClick={handleButtonClick} // Call the parent callback when clicked
          style={{ height: '50px', fontSize: '18px' }}
        >
          Choose Pet 
        </Button>
      </Form>
    </div>
  );
};

export default GroomPet;
