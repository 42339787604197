import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ServicePage from "./pages/ServicesPage";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import ReadNewsPage from "./pages/ReadNewsPage";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import PetChoicePage from "./pages/PetChoicePage";
import ProviderChoicePage from "./pages/ProviderChoicePage";
import PageNotFoundPage from "./pages/PageNotFoundPage";

import FAQsPage from "./pages/FAQsPage";
import NewsPage from "./pages/NewsPage";
import BlogPage from "./pages/BlogPage";
import ProductDisplayPage from "./pages/ProductDisplayPage";
import MainShopPage from "./pages/MainShopPage";
import AuthorBioPage from "./pages/AuthorBioPage";
import CommunityPage from "./pages/CommunityPage";
import UserAccountPage from "./pages/UserAccountPage";
import SupportCenterPage from "./pages/SupportCenterPage";
import BecomeAPawPro from "./pages/BecomeAPawPro";
import PawFinderPage from "./pages/PawFinderPage";
import CareersPage from "./pages/CareersPage";
import PawFinderMapPage from "./pages/PawFinderMapPage";
import CartPage from "./pages/CartPage";
import { AuthenticationGuard } from "./components/authentication-guard";
import MessagesPage from "./pages/MessagesPage";
import PetSittingPage from "./pages/services/PetSittingPage.tsx";
import PetInsurancePage from "./pages/services/PetInsurancePage.tsx";
import DogWalkingPage from "./pages/services/DogWalkingPage";
import PetBoardingPage from "./pages/services/PetBoardingPage.tsx";
import VetinaryServicesPage from "./pages/services/VetinaryServicesPage";
import PetFuneralServicesPage from "./pages/services/PetFuneralServicesPage";
import PetAdoptionServicesPage from "./pages/services/PetAdoptionServicesPage";
import PetTransportationPage from "./pages/services/PetTransportationPage";
import GroomingPage from "./pages/services/GroomingPage";
import PetPhotographyPage from "./pages/services/PetPhotographyPage";
import PetTrainingPage from "./pages/services/PetTraningPage";

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/services" element={<ServicePage />} />
    <Route path="/home" element={<HomePage />} />
    <Route path="/contact" element={<ContactUs />} />
    <Route path="/about-us" element={<AboutUs />} />
    <Route path="/log-in" element={<LoginPage />} />
    <Route path="/signup" element={<SignUpPage />} />
    <Route path="/faqs" element={<FAQsPage />} />
    <Route path="/news" element={<NewsPage />} />
    <Route path="/blog" element={<BlogPage />} />
    <Route path="/product/:productId" element={<ProductDisplayPage />} />
    <Route path="/shop" element={<MainShopPage />} />
    <Route path="/read-news/:articleId" element={<ReadNewsPage />} />
    <Route path="/author-bio" element={<AuthorBioPage />} />
    <Route path="/community" element={<CommunityPage />} />
    <Route path="/careers" element={<CareersPage />} />
    <Route path="/paw-finder-map" element={<PawFinderMapPage />} />
    <Route path="/cart" element={<CartPage />} />
    <Route path="/pet-sitting" element={<PetSittingPage />} />
    <Route path="/pet-insurance" element={<PetInsurancePage />} />
    <Route path="/dog-walking" element={<DogWalkingPage/>}/>
    <Route path="/pet-boarding" element={<PetBoardingPage />} />
    <Route path="/vet-services" element={<VetinaryServicesPage/>} />
    <Route path="/funeral-services" element={<PetFuneralServicesPage/>} />
    <Route path="/adopt-a-pet" element={<PetAdoptionServicesPage/>} />
    <Route path="/transport-pet" element={<PetTransportationPage/>} />
    <Route path="/groom-pet" element={<GroomingPage/>} />  
    <Route path="/pet-photo" element={<PetPhotographyPage/>} /> 
    <Route path="/train-pet" element={<PetTrainingPage/>} />  


    {/* Protected Routes */}
    <Route
      path="/choose-pet"
      element={<AuthenticationGuard component={PetChoicePage} />}
    />
    <Route
      path="/messages"
      element={<AuthenticationGuard component={MessagesPage} />}
    />
    <Route
      path="/choose-provider"
      element={<AuthenticationGuard component={ProviderChoicePage} />}
    />
    
    <Route
      path="/user-account"
      element={<AuthenticationGuard component={UserAccountPage} />}
    />
    <Route
      path="/support-center"
      element={<AuthenticationGuard component={SupportCenterPage} />}
    />
    <Route path="/become-a-paw-pro" element={<BecomeAPawPro />} />
    <Route path="/paw-finder" element={<PawFinderPage />} />

    <Route path="*" element={<PageNotFoundPage />} />
  </Routes>
);

export default AppRoutes;
