import React from "react";
import Header from "../components/Header/Header";
import HeroSection from "../components/HeroSection/HeroSection";
import DialogflowMessenger from "../components/DialogFlowMessenger/DialogFlowMessenger";
import Footer from "../components/Footer/Footer";
import SearchBar from "../components/SearchBar/SearchBar";
import AnimalIcons from "../components/AnimalIcons/AnimalIcons";
import SecondHero from "../components/SecondHero/SecondHero";
import Testimonials from "../components/Testimonials/Testimonials";
import Stories from "../components/Stories/Stories";
import { ServiceCategories } from "../components/ServiceCategories/ServiceCategories";

const HomePage = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <SearchBar />
      <AnimalIcons />
      <SecondHero />
      <ServiceCategories limit={6} title="Top Services" />
      <Stories />
      <Testimonials />
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default HomePage;
