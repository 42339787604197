import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import CareersHero from '../components/CareersHero/CareersHero';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';
import CareerBnPs from '../components/CareerBnPs/CareerBnPs';
import CareerStatement from '../components/CareerStatement/CareerStatement';
import Spacer from '../components/Spacer/Spacer';
import JobOpportunities from '../components/JobOpportunites/JobOpportunities';
import JobMap from '../components/JobMap/JobMap';

const CareersPage: React.FC = () => {
  return (
    <div>
      <Header />
      <CareersHero />
      <Spacer />
      <CareerStatement />
      <Spacer />
      <JobMap />
      <Spacer />
      <CareerBnPs />
      <Spacer />
      <JobOpportunities />
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default CareersPage;
