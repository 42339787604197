import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import './CareerStatement.css';
import Spacer from '../Spacer/Spacer';

const { Title, Paragraph } = Typography;

const CareerStatement: React.FC = () => {
  return (
    <div className="cd-container cd-max-width-adaptive-lg">
      <div className="career-statement-container">
        <Title level={2} className="main-title">Guided by Our Core Principles</Title>
        <Paragraph className="main-subtitle">
          Our principles guide everything we do, from recruitment and company culture to our platform and product development.
        </Paragraph>
        <Spacer />
        <Row gutter={[16, 16]} align="middle">
          
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <Card className="career-card">
                  <Title level={4}>Show Genuine Care</Title>
                  <Paragraph>
                    We are committed to supporting our colleagues, communities, customers, and Taskers wholeheartedly.
                  </Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12}>
                <Card className="career-card">
                  <Title level={4}>Pursue Growth</Title>
                  <Paragraph>
                    We foster curiosity, embrace learning through experience, and strive to overcome challenges with determination.
                  </Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12}>
                <Card className="career-card">
                  <Title level={4}>Foster Community Spirit</Title>
                  <Paragraph>
                    We value every voice, generously share our skills, and work to create a diverse and sustainable community.
                  </Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12}>
                <Card className="career-card">
                  <Title level={4}>Shape the Future Together</Title>
                  <Paragraph>
                    We promote openness and accessibility, cherish entrepreneurial spirit, and seek to inspire through our actions and advocacy.
                  </Paragraph>
                </Card>
              </Col>
            </Row>
          
        </Row>
      </div>
    </div>
  );
};

export default CareerStatement;
