import React, { useState } from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import SubNavigation from '../components/SubNavigation/SubNavigation';
import AnimalIcons from '../components/AnimalIcons/AnimalIcons';
import FeatureV2 from '../components/FeatureV2/FeatureV2';
import ShopProductGrid from '../components/ShopProductGrid/ShopProductGrid';
import BestSellers from '../components/BestSellers/BestSellers';
import QualityAssurance from '../components/QualityAssurance/QualityAssurance';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';

const MainShopPage = () => {
  const [activeCategory, setActiveCategory] = useState('food');

  return (
    <div>
      <Header />
      <AnimalIcons />
      <SubNavigation activeCategory={activeCategory} setActiveCategory={setActiveCategory} />
      <ShopProductGrid activeCategory={activeCategory} />
      <FeatureV2 />
      <BestSellers />
      <QualityAssurance />  
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default MainShopPage;
