import React from "react";
import { Typography } from "antd";
import "./BappInfoComp.css"; // Assuming you have corresponding CSS

const { Title, Paragraph } = Typography;

const BappInfoComp = () => {
  return (
    <section className="feature feature--invert cd-position-relative cd-z-index-1 cd-margin-y-2xl">
      <div className="cd-container cd-max-width-adaptive-lg">
        <div className="feature__grid">
          <div>
            <div className="feature__content">
              <p className="feature__label">About the platform</p>
              <Title level={1}>Paw Pros</Title>
              <Paragraph className="cd-line-height-lg">
                Paw Pros connects pet owners with certified local service
                providers in their area for all their pet service needs. As a
                provider, you have the opportunity to earn doing what you love,
                on your schedule and preferred location, while making a
                difference for pet owners in your community.
              </Paragraph>
            </div>
          </div>
          <div>
            <figure>
              <img
                className="feature__img"
                data-img-light
                src="https://res.cloudinary.com/daq4ywu5y/image/upload/v1723587567/4_woaivd.png"
                alt="Imagedescription"
              />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BappInfoComp;
