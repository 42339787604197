import React, { useState, useEffect } from "react";
import { Input, Button, message } from "antd";

import "antd/dist/reset.css";
import "./PasswordProtect.css";

interface PasswordProtectionProps {
  children: React.ReactNode;
}

const PasswordProtection: React.FC<PasswordProtectionProps> = ({
  children,
}) => {
  const [password, setPassword] = useState<string>("");
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  useEffect(() => {
    setIsAuthorized(
      JSON.parse(sessionStorage.getItem("isAuthorized") || "false")
    );
  }, []);

  const handlePasswordSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const correctPassword = process.env.REACT_APP_PASSWORD ?? ""; // Use nullish coalescing for fallback
    const isPasswordCorrect = password === correctPassword;

    if (isPasswordCorrect) {
      setIsAuthorized(true);
      sessionStorage.setItem("isAuthorized", "true"); // Store the authorized state in sessionStorage
    } else {
      message.error("Incorrect password");
    }
  };

  if (isAuthorized) {
    return <>{children}</>; // Render the website content if the password is correct
  }

  return (
    <div className="password-container">
      <div className="logo-container">
        <img
          src="https://res.cloudinary.com/daq4ywu5y/image/upload/v1725825092/1_lecdga.png" // Replace with your logo image link
          alt="Your Logo"
          className="logo"
        />
      </div>
      <form className="password-form" onSubmit={handlePasswordSubmit}>
        <Input.Password
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ width: 250, marginBottom: 10 }}
        />
        <Button type="primary" htmlType="submit">
          Paws and Pros Beta Test
        </Button>
      </form>
    </div>
  );
};

export default PasswordProtection;
