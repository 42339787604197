import React, { useState, useEffect } from "react";
import {
  Button,
  Dropdown,
  Space,
  message,
  Modal,
  Badge,
  Switch,
  Menu,
} from "antd";
import {
  MenuOutlined,
  CloseOutlined,
  DownOutlined,
  EnvironmentFilled,
  UserOutlined,
  ShoppingCartOutlined,
  MailOutlined,
  BulbOutlined,
  MoonOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useShopContext } from "../../context/ShopContext";
import "./Header.css";
import "../../assets/styles/global.css";

const cloudinaryImageUrl =
  "https://res.cloudinary.com/daq4ywu5y/image/upload/v1725825092/1_lecdga.png";

const items = [
  { key: "dog-walking", label: "Dog Walking" },
  { key: "pet-sitting", label: "Pet Sitting" },
  { key: "groom-pet", label: "Grooming" },
  { key: "train-pet", label: "Training" },
  { key: "transport-pet", label: "Pet Transportation" },
  { key: "vet-services", label: "Veterinary Services" },
  { key: "pet-photo", label: "Pet Photography" },
  { key: "funeral-services", label: "Pet Funeral Services" },
  { key: "pet-boarding", label: "Pet Boarding" },
  { key: "adopt-a-pet", label: "Pet Adoption Services" },
  { key: "pet-insurance", label: "Pet Insurance" },
];

const Header = () => {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const { getTotalCartItems } = useShopContext();
  const [location, setLocation] = useState("Location");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const response = await fetch(
            `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
          );
          const data = await response.json();
          if (data.city && data.principalSubdivision) {
            setLocation(`${data.city}, ${data.principalSubdivisionCode}`);
          } else {
            setLocation("Location not found");
          }
        },
        (error) => {
          message.error(
            "Failed to get location. Please enable location services."
          );
          console.error(error);
        }
      );
    } else {
      message.error("Geolocation is not supported by your browser.");
    }
  }, []);

  useEffect(() => {
    const fetchTotalItems = async () => {
      const total = await getTotalCartItems();
      setTotalItems(total);
    };

    fetchTotalItems();
  }, [getTotalCartItems]);

  // Load dark mode preference from localStorage
  useEffect(() => {
    const darkModePreference = localStorage.getItem("dark-mode") === "true";
    setIsDarkMode(darkModePreference);
    document.body.classList.toggle("dark-mode", darkModePreference);
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleLogin = () => {
    loginWithRedirect();
  };

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  // Toggle Dark Mode
  const toggleDarkMode = () => {
    const newDarkMode = !isDarkMode;
    setIsDarkMode(newDarkMode);
    localStorage.setItem("dark-mode", newDarkMode.toString());
    document.body.classList.toggle("dark-mode", newDarkMode);
  };

  const updatedItems = items.map((item) => {
    return {
      key: item.key,
      label: <Link to={`/${item.key}`}>{item.label}</Link>,
    };
  });

  return (
    <header className="header mf1-position-relative js-header">
      <div className="header__container mf1-container mf1-max-width-lg">
        <div className="header__logo">
          <Link to="/">
            <img
              height="60px"
              width="auto"
              src={cloudinaryImageUrl}
              alt="Logo"
            />
          </Link>
        </div>
        <Button
          type="text"
          className="header__trigger js-header__trigger"
          aria-label="Toggle menu"
          aria-expanded={isNavVisible}
          onClick={toggleNav}
          style={{
            border: "none",
            boxShadow: "none",
          }} /* Removes border and shadow */
        >
          {isNavVisible ? <CloseOutlined /> : <MenuOutlined />}
        </Button>
        <nav
          className={`header__nav js-header__nav ${
            isNavVisible ? "header__nav--is-visible" : ""
          }`}
          id="header-nav"
          role="navigation"
          aria-label="Main"
        >
          <div className="header__nav-inner">
            <div className="header__label">Main menu</div>
            <ul className="header__list">
              <li className="header__item">
                <Link to="/" style={{ textDecoration: "none" }}>
                  <Button type="text">Home</Button>
                </Link>
              </li>
              <li className="header__item">
                <li className="header__item">
                  <Dropdown overlay={<Menu items={updatedItems} />}>
                    <Button type="text">
                      <Space>
                        Services
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </li>
              </li>
              <li className="header__item">
                {isAuthenticated ? (
                  <Space>
                    <Button type="text" onClick={showModal}>
                      Logout
                    </Button>
                    <Link to="/user-account">
                      <UserOutlined
                        style={{ fontSize: "18px", color: "#1890ff" }}
                      />
                    </Link>
                    <Link to="/messages">
                      <MailOutlined
                        style={{ fontSize: "18px", color: "#1890ff" }}
                      />
                    </Link>
                  </Space>
                ) : (
                  <Button type="text" onClick={handleLogin}>
                    Login
                  </Button>
                )}
              </li>
              <li
                className="header__item header__item--divider"
                aria-hidden="true"
              ></li>
              <li className="header__item">
                <Link to="/shop">
                  <Button type="text">Shop</Button>
                </Link>
              </li>
              <li className="header__item">
                <Link to="/paw-finder">
                  <Button type="primary">Paw Finder</Button>
                </Link>
              </li>
              <li className="header__item">
                <EnvironmentFilled />
                <Button type="text">{location}</Button>
              </li>
              <li className="header__item">
                <Badge count={totalItems} offset={[10, 0]}>
                  <Link to="/cart">
                    <ShoppingCartOutlined
                      style={{ fontSize: "24px", color: "#1890ff" }}
                    />
                  </Link>
                </Badge>
              </li>
              <li className="header__item">
                <Switch
                  checkedChildren={<BulbOutlined />}
                  unCheckedChildren={<MoonOutlined />}
                  checked={isDarkMode}
                  onChange={toggleDarkMode}
                />
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <Modal
        title="Logout Confirmation"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to logout?</p>
      </Modal>
    </header>
  );
};

export default Header;
