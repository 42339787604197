import React, { useEffect, useState } from 'react';
import { Card, List, Avatar, Button, Modal, Input, message as antdMessage } from 'antd';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react'; // Import the Auth0 hook
import './CustomerCommunication.css';

const { TextArea } = Input;

function CustomerCommunication() {
  const { user, isAuthenticated } = useAuth0(); // Use Auth0 to get the user information
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newMessage, setNewMessage] = useState(''); // State for new message input

  useEffect(() => {
    if (isAuthenticated && user) {
      fetchConversations(user.sub); // Use the Auth0 user ID (user.sub) as the providerId
    }
  }, [isAuthenticated, user]);

  const fetchConversations = async (providerId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/api/conversation/conversations/${providerId}`);
      setConversations(response.data);
    } catch (error) {
      console.error('Failed to load conversations:', error);
    }
  };

  const showModal = (conversation) => {
    setSelectedConversation(conversation);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedConversation(null);
    setNewMessage(''); // Clear the message input on close
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim()) {
      antdMessage.warning('Please enter a message.');
      return;
    }

    try {
      const updatedConversation = {
        ...selectedConversation,
        messages: [
          ...selectedConversation.messages,
          {
            senderId: user.sub,
            message: newMessage,
            timestamp: new Date().toISOString(),
            isRead: false,
          },
        ],
      };

      // Update the conversation in the backend
      await axios.post(`${process.env.REACT_APP_BACKEND_URI}/api/conversation/conversations/${selectedConversation._id}/messages`, {
        senderId: user.sub,
        message: newMessage,
      });

      // Update the local state with the new message
      setConversations(conversations.map(conv =>
        conv._id === selectedConversation._id ? updatedConversation : conv
      ));
      setSelectedConversation(updatedConversation);
      setNewMessage(''); // Clear the input field
      antdMessage.success('Message sent successfully.');
    } catch (error) {
      console.error('Failed to send message:', error);
      antdMessage.error('Failed to send message.');
    }
  };

  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
    <Card title="Messages" className="customer-communication-card">
      <List
        itemLayout="horizontal"
        dataSource={conversations}
        renderItem={conversation => {
          const lastMessage = conversation.messages[conversation.messages.length - 1];
          const otherParticipant = conversation.participants.find(id => id !== user.sub); // Get the other participant
          return (
            <List.Item
              actions={[<Button type="link" key="reply" onClick={() => showModal(conversation)}>Reply</Button>]}
            >
              <List.Item.Meta
                avatar={<Avatar src={`https://example.com/${otherParticipant}.jpg`} />}
                title={`Conversation with ${otherParticipant}`}
                description={lastMessage.message}
              />
            </List.Item>
          );
        }}
      />
      {selectedConversation && (
        <Modal
          title="Conversation History"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          width={600}
        >
          <div className="conversation-history">
            {selectedConversation.messages.map((msg, index) => (
              <div key={index} className={`message ${msg.senderId === user.sub ? 'sent' : 'received'}`}>
                <div className="message-content">{msg.message}</div>
                <div className="message-timestamp">{new Date(msg.timestamp).toLocaleString()}</div>
              </div>
            ))}
          </div>
          <TextArea
            rows={4}
            value={newMessage}
            onChange={e => setNewMessage(e.target.value)}
            placeholder="Type your reply..."
          />
          <Button type="primary" onClick={handleSendMessage} style={{ marginTop: '10px' }}>
            Send
          </Button>
        </Modal>
      )}
    </Card>
    </section>
  );
}

export default CustomerCommunication;
