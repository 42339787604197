import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';


import Spacer from '../components/Spacer/Spacer';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';
import FAQComponent from '../components/FAQs/FAQs';
const FAQsPage = () => {
  return (
    <div>
      <Header />
      <Spacer/>
      <FAQComponent />
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default FAQsPage;
