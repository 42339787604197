import React from 'react';
import { Card, Col, Row, Button,Typography } from 'antd';
import { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';


const { Title, Text } = Typography;

interface PetSittingService {
  id: number;
  title: string;
  description: string;
  price: number;
  imageUrl: string;
}

const PetSittingPage: React.FC = () => {
  const [services, setServices] = useState<PetSittingService[]>([]);

  // Mock API call to fetch pet sitting services
  useEffect(() => {
    const fetchServices = async () => {
      const mockServices: PetSittingService[] = [
        {
          id: 1,
          title: 'Dog Sitting',
          description: 'Full day dog sitting service in your home.',
          price: 50,
          imageUrl: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1725947949/2C17F10B-BB5B-490F-9CF9-F29ED556A858_z8uare.jpg',
        },
        {
          id: 2,
          title: 'Cat Sitting',
          description: 'Daily visits to check on and care for your cat.',
          price: 30,
          imageUrl: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1725947949/8D9F01EF-A924-4AE0-A6E9-7C3B8CD70F7D_afs1w4.jpg',
        },
        {
          id: 3,
          title: 'Small Pet Sitting',
          description: 'Care for rabbits, hamsters, and other small pets.',
          price: 20,
          imageUrl: 'https://res.cloudinary.com/daq4ywu5y/image/upload/v1725947949/780F805C-B923-4355-9353-A8BB85E4A728_qeslty.jpg',
        },
      ];
      setServices(mockServices);
    };

    fetchServices();
  }, []);

  return (
    <div>
      <Header />
      <section className="ta0-container ta0-max-width-adaptive-lg">
      <div style={{ padding: '20px' }}>
        <Title>Pet Sitting Services</Title>
        <Row gutter={[16, 16]}>
          {services.map((service) => (
            <Col key={service.id} xs={24} sm={12} lg={8}>
              <Card
                hoverable
                cover={<img alt={service.title} src={service.imageUrl} />}
              >
                <h3>{service.title}</h3>
                <p><Text>{service.description}</Text></p>
                <p><Text strong>Price: ${service.price}</Text></p>
                <Button type="primary">Book Now</Button>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      </section>
      <Footer /> 
    </div>
  );
};

export default PetSittingPage;
