import React from 'react';
import GroomPet from '../../components/GroomPet/GroomPet';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { useBookingContext } from '../../context/BookingContext';  // Import BookingContext

const GroomingPage: React.FC = () => {
  const { updateBookingDetails } = useBookingContext();  // Access updateBookingDetails from the context
  const navigate = useNavigate();  // Use navigate for redirection

  // Handle when the user selects a date and time in the GroomPet component
  const handleChoosePetClick = (date: any, time: any) => {
    // Store the date and time in the booking context
    updateBookingDetails('date', date ? date.toDate() : null);
    updateBookingDetails('time', time ? time.toDate() : null);

    // Navigate to the PetChoicePage
    navigate('/choose-pet', {
      state: { service: 'Grooming' },  // Only passing service
    });
  };

  return (
    <div>
      <Header />
      <div style={{ padding: '20px' }}>
        <GroomPet onChoosePetClick={handleChoosePetClick} />  
      </div>
      <Footer />
    </div>
  );
};

export default GroomingPage;
