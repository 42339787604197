import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Pagination, Modal, Button } from 'antd';
import './JobOpportunities.css';

const { Title, Paragraph } = Typography;

interface Job {
  _id: string;
  jobTitle: string;
  location: {
    city: string;
    stateOrProvince: string;
    country: string;
  };
  shortDescription: string;
  detailedDescription: string;
  jobRequirements: string;
  estimatedPayRange: {
    min: number;
    max: number;
  };
  dateOfPosting: string;
  numberOfPositions: number;
}

const itemsPerPage = 6;

const JobOpportunities: React.FC = () => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null); // State for selected job
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false); // Modal visibility

  // Fetch job opportunities from the backend
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/careers/jobs`); // Assuming this is your API endpoint
        const data = await response.json();
        setJobs(data);
        setLoading(false);
      } catch (err) {
        console.error('Failed to fetch jobs:', err);
      }
    };
    fetchJobs();
  }, []);

  const handleChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleJobClick = (job: Job) => {
    setSelectedJob(job);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedJob(null);
  };

  const currentJobs = jobs.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <div className="job-opportunities-container">
      <div className="cd-container cd-max-width-adaptive-lg">
        <Title level={2} className="main-title">Current Job Opportunities</Title>
        
        <Row gutter={[16, 16]}>
          {loading ? (
            <div>Loading...</div>
          ) : (
            currentJobs.map((job) => (
              <Col key={job._id} xs={24} sm={12} lg={8}>
                <Card className="job-card" onClick={() => handleJobClick(job)}>
                  <Title level={4}>{job.jobTitle}</Title>
                  <Paragraph>{job.location.city}, {job.location.stateOrProvince}, {job.location.country}</Paragraph>
                  <Paragraph>{job.shortDescription}</Paragraph>
                </Card>
              </Col>
            ))
          )}
        </Row>
        
        <Pagination
          current={currentPage}
          total={jobs.length}
          pageSize={itemsPerPage}
          onChange={handleChange}
          className="pagination"
        />

        {/* Modal for Job Details */}
        {selectedJob && (
          <Modal
            title={selectedJob.jobTitle}
            visible={isModalVisible}
            onCancel={handleModalClose}
            footer={[
              <Button key="apply" type="primary" onClick={() => alert('Apply Now clicked!')}>
                Apply Now
              </Button>,
              <Button key="cancel" onClick={handleModalClose}>
                Cancel
              </Button>,
            ]}
          >
            <Paragraph><strong>Location:</strong> {selectedJob.location.city}, {selectedJob.location.stateOrProvince}, {selectedJob.location.country}</Paragraph>
            <Paragraph><strong>Job Description:</strong> {selectedJob.detailedDescription}</Paragraph>
            <Paragraph><strong>Requirements:</strong> {selectedJob.jobRequirements}</Paragraph>
            <Paragraph><strong>Pay Range:</strong> ${selectedJob.estimatedPayRange.min} - ${selectedJob.estimatedPayRange.max}</Paragraph>
            <Paragraph><strong>Posted On:</strong> {new Date(selectedJob.dateOfPosting).toLocaleDateString()}</Paragraph>
            <Paragraph><strong>Number of Positions:</strong> {selectedJob.numberOfPositions}</Paragraph>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default JobOpportunities;
