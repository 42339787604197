import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/Header/Header';
import PetChoiceBanner from '../components/PetChoiceBanner/PetChoiceBanner';
import Footer from '../components/Footer/Footer';
import PetsGrid from '../components/PetsGrid/PetsGrid';
import Spacer from '../components/Spacer/Spacer';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';

// Define the shape of the state passed via the location
interface LocationState {
  service?: string;
}

const PetChoicePage: React.FC = () => {
  const location = useLocation(); 
  const { service } = location.state as LocationState || {}; 

  return (
    <div>
      <Header />
      <PetsGrid service={service || ''} /> {/* No need to pass bookingData */}
      <Spacer />
      <PetChoiceBanner />
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default PetChoicePage;
