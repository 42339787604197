import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import BlogArticles from '../components/BlogArticles/BlogArticles';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';

const BlogPage: React.FC = () => {
  return (
    <div>
      <Header />
      <BlogArticles />
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default BlogPage;
