import React, { createContext, useContext, useEffect, useState, useCallback, ReactNode } from "react";
import { useAuth0 } from "@auth0/auth0-react";

type CartItemsType = Record<string, number>;

interface ShopContextType {
  products: any[];
  cartItems: CartItemsType;
  selectedProduct: any | null;
  setSelectedProduct: (product: any | null) => void;
  addToCart: (userId: string, itemId: string, selectedVariation: any, quantity: number) => Promise<any>;
  removeFromCart: (itemId: string) => void;
  getTotalCartAmount: () => number;
  getTotalCartItems: () => Promise<number>;
  isAuthenticated: boolean;
  user: any;
  isLoading: boolean;
  userId: string | null;
  fetchCartItems: () => Promise<any>;
}

export const ShopContext = createContext<ShopContextType | null>(null);

export const useShopContext = () => {
  const context = useContext(ShopContext);
  if (!context) {
    throw new Error("useShopContext must be used within a ShopContextProvider");
  }
  return context;
};

interface ShopContextProviderProps {
  children: ReactNode;
}

const ShopContextProvider: React.FC<ShopContextProviderProps> = (props) => {
  const [products, setProducts] = useState<any[]>([]);
  const [cartItems, setCartItems] = useState<CartItemsType>({});
  const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const { isAuthenticated, isLoading, user } = useAuth0();

  useEffect(() => {
    if (isAuthenticated && user && user.sub) {
      setUserId(user.sub);
      fetchProducts();
    }
  }, [isAuthenticated, user]);
  

  const fetchProducts = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/products`);
      if (!response.ok) throw new Error("Failed to fetch products");
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const getTotalCartItems = useCallback(async () => {
    if (!userId) {
      console.error("No userId found. Cannot fetch cart.");
      return 0;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/cart/find/${userId}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) throw new Error("Failed to fetch cart");
      const cartData = await response.json();
      let totalItem = 0;
      if (cartData?.products?.length) {
        cartData.products.forEach((item: any) => {
          totalItem += item.quantity;
        });
      }
      return totalItem;
    } catch (error) {
      console.error("Error fetching cart:", error);
      return 0;
    }
  }, [userId]);

  const fetchCartItems = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/cart/find/${userId}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) throw new Error("Failed to fetch cart");
      return await response.json();
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  };

  const addToCart = async (userId: string, itemId: string, selectedVariation: any, quantity: number) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/cart/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("auth-token") || "",
        },
        body: JSON.stringify({ userId, cartItem: itemId, quantity, variation: selectedVariation }),
      });
      if (!response.ok) throw new Error("Failed to add item to cart");
      return await response.json();
    } catch (error) {
      console.error("Error adding item to cart:", error);
      throw error;
    }
  };

  const getTotalCartAmount = () => {
    let totalAmount = 0;
    for (const item in cartItems) {
      if (cartItems[item] > 0) {
        const itemInfo = products.find((product) => product.id === Number(item));
        if (itemInfo) {
          totalAmount += cartItems[item] * itemInfo.new_price;
        }
      }
    }
    return totalAmount;
  };

  const removeFromCart = (itemId: string) => {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }));
    if (userId) {
      fetch(`${process.env.REACT_APP_BACKEND_URI}/api/cart/${userId}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("auth-token") || "",
        },
        body: JSON.stringify({ itemId }),
      }).catch((error) => console.error("Error removing item from cart:", error));
    }
  };

  const contextValue: ShopContextType = {
    products,
    cartItems,
    selectedProduct,
    setSelectedProduct,
    addToCart,
    removeFromCart,
    getTotalCartAmount,
    getTotalCartItems,
    isAuthenticated,
    user,
    isLoading,
    userId,
    fetchCartItems,
  };

  return <ShopContext.Provider value={contextValue}>{props.children}</ShopContext.Provider>;
};

export default ShopContextProvider;
