import React, { createContext, useContext, useEffect, useState, ReactNode, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";

interface Booking {
  id: string;
  serviceType: string;
  petName: string;
  date: string;
  time: string;
  location: string;
  provider: string;
  price: number;
  paymentStatus: string;
  payoutStatus: string;
}

interface BookingDetails {
  [key: string]: any; // Flexible structure to store dynamic booking details
}

interface BookingContextType {
  bookings: Booking[];
  bookingDetails: BookingDetails; // Stores the current booking data
  userId: string | null;
  fetchBookings: () => Promise<void>;
  addBooking: (newBooking: Booking) => Promise<void>;
  removeBooking: (bookingId: string) => Promise<void>;
  updateBookingDetails: (field: string, value: any) => void; // Function to update booking details
  clearBookingDetails: () => void; // Function to reset booking details
  getTotalBookingPrice: () => number;
  isAuthenticated: boolean;
  user: any;
  isLoading: boolean;
}

export const BookingContext = createContext<BookingContextType | null>(null);

export const useBookingContext = () => {
  const context = useContext(BookingContext);
  if (!context) {
    throw new Error("useBookingContext must be used within a BookingContextProvider");
  }
  return context;
};

interface BookingContextProviderProps {
  children: ReactNode;
}

const BookingContextProvider: React.FC<BookingContextProviderProps> = (props) => {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [bookingDetails, setBookingDetails] = useState<BookingDetails>({});
  const [userId, setUserId] = useState<string | null>(null);
  const { isAuthenticated, isLoading, user } = useAuth0();

  

  const fetchBookings = useCallback(async () => {
    if (!userId) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/bookings/${userId}`);
      if (!response.ok) throw new Error("Failed to fetch bookings");
      const data = await response.json();
      setBookings(data);
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  }, [userId]);

  useEffect(() => {
    if (isAuthenticated && user && user.sub) {
      setUserId(user.sub);
      fetchBookings();
    }
  }, [isAuthenticated, user, fetchBookings]);
  const addBooking = async (newBooking: Booking) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/bookings`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newBooking),
      });
      if (!response.ok) throw new Error("Failed to add booking");
      const data = await response.json();
      setBookings((prev) => [...prev, data]);
    } catch (error) {
      console.error("Error adding booking:", error);
    }
  };

  const removeBooking = async (bookingId: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/bookings/${bookingId}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) throw new Error("Failed to remove booking");
      setBookings((prev) => prev.filter((booking) => booking.id !== bookingId));
    } catch (error) {
      console.error("Error removing booking:", error);
    }
  };


  // Update booking details dynamically based on the field and value passed
  const updateBookingDetails = (field: string, value: any) => {
    setBookingDetails((prevDetails) => ({ ...prevDetails, [field]: value }));
  };

  // Clear all booking details (e.g., when the booking is completed or canceled)
  const clearBookingDetails = () => {
    setBookingDetails({});
  };

  const getTotalBookingPrice = () => {
    return bookings.reduce((total, booking) => total + booking.price, 0);
  };

  


  const contextValue: BookingContextType = {
    bookings,
    bookingDetails,
    userId,
    fetchBookings,
    addBooking,
    removeBooking,
    updateBookingDetails,
    clearBookingDetails,
    getTotalBookingPrice,
    isAuthenticated,
    user,
    isLoading,
    
    };

  return <BookingContext.Provider value={contextValue}>{props.children}</BookingContext.Provider>;
};

export default BookingContextProvider;
