import React from 'react';
import './FeatureV2.css';
import '../../assets/styles/global.css';
import { Button, Typography } from 'antd';
import { Link } from'react-router-dom'; 
import Spacer from '../Spacer/Spacer';

const { Text,Title, Paragraph } = Typography;
const FeatureV2 = () => {
  return (
    <section className="feature-v2 cd-position-relative cd-z-index-1 cd-margin-y-2xl">
      <div className="cd-container cd-max-width-adaptive-lg">
        <Paragraph className="feature-v2__label">Pet Supplies Delivery</Paragraph>
        <div className="feature-v2__grid">
          <div className="feature-v2__grid-item-1">
            <Title level={2} className="text-capitalize">Check Out the Latest Pet Supplies, Accessories, Merch, and More!</Title>
          </div>
          <div className="feature-v2__grid-item-2">
            <div className="feature-v2__content">
              <Text>
              Discover our newest collection of pet essentials and trendy accessories, perfect for your furry friends. Shop now to find everything from stylish pet apparel to must-have supplies and exclusive merchandise. Don’t miss out on these exciting new arrivals!</Text>
              <Text>
              Some of the items in our store are fulfilled through Amazon to provide you with a wider selection and faster shipping options. These products are clearly marked on their respective pages for your convenience. You’ll enjoy the same trusted service and quality standards.
              </Text>
              <Spacer/>
              <Paragraph>

                <Button type='text'><Link  to="/blog">Read more →</Link></Button>
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureV2;
