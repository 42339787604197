import React from "react";
import { Link } from "react-router-dom";
import "./SecondHero.css";
import "../../assets/styles/global.css";
import { Button } from "antd";
import { Typography } from "antd";

const { Text, Title } = Typography;

const SecondHero = () => {
  return (
    <section className="hero">
      <div className="cd-container cd-max-width-adaptive-sm">
        <div className="hero__label cd-margin-bottom-2xs">Paw Pros</div>

        <h1 className="cd-margin-bottom-xs">
          <Title>Pet Training</Title>
        </h1>

        <p className="cd-line-height-lg cd-margin-bottom-sm">
          <Text strong>Comprehensive Pet Care: </Text>
          <Text> Offering attentive care including training, exercise, and playtime tailored to your pet's needs</Text>
        </p>

        <p className="cd-line-height-lg cd-margin-bottom-sm">
          <Text strong>Safe and Stimulating Environment: </Text>
          <Text> Providing a secure and engaging atmosphere where pets can socialize and learn comfortably</Text>
        </p>

        <div className="hero__cta">
          <Link to="/train-pet">
            <Button type="primary">Book Training Session</Button>
          </Link>
          <Link to="/services" className="cd-link cd-link--contrast-higher">
            <Button type="text">
              <Text strong>Other Services</Text>
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default SecondHero;
