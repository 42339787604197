import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography } from 'antd';
import axios from 'axios';

const { Title, Paragraph, Text } = Typography;

// Helper function to format service names
const toCamelCase = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase()
    )
    .replace(/\s+/g, '');
};

const PartnerDisplay = ({ selectedService, onProviderSelect }) => {
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const serviceKey = toCamelCase(selectedService); // Convert service to camelCase
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/api/certifiedPartner/certified-partners/service/${serviceKey}`);
        setPartners(response.data);
      } catch (error) {
        console.error('Error fetching certified partners:', error.message);
      }
    };

    if (selectedService) {
      fetchPartners();
    }
  }, [selectedService]);

  const handleSelectPartner = (partner) => {
    setSelectedPartner(partner);
    onProviderSelect(partner); // Notify parent component of the selected partner
  };

  return (
    <section className="ta0-container ta0-max-width-adaptive-lg">
      <Row gutter={[16, 16]}>
        {partners.map((partner, index) => (
          <Col key={index} xs={24} sm={12} md={8} lg={6}>
            <Card
              hoverable
              className={selectedPartner && selectedPartner._id === partner._id ? 'selected-card' : ''}
              onClick={() => handleSelectPartner(partner)}
            >
              <img src={partner.logoUrl} alt={partner.companyName} style={{ width: '100%' }} />
              <div>
                <Title level={3}>{partner.companyName}</Title>
                <Paragraph>Contact: {partner.contactPerson}</Paragraph>
                <Paragraph>Email: {partner.email}</Paragraph>
                <Paragraph>Location: {partner.location}</Paragraph>
                <Text strong>Starting at Price:</Text>
                <Paragraph>${partner.startingPrice || 'N/A'}</Paragraph>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </section>
  );
};

export default PartnerDisplay;
