import React, { useState } from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import ProductDisplay from '../components/ProductDisplay/ProductDisplay';
import Spacer from '../components/Spacer/Spacer';
import RelatedProducts from '../components/RelatedProducts/RelatedProducts';
import DialogflowMessenger from '../components/DialogFlowMessenger/DialogFlowMessenger';

const ProductDisplayPage = () => {
  const [category, setCategory] = useState(null);

  return (
    <div>
      <Header />
      <Spacer />
      <ProductDisplay setCategory={setCategory} />
      <Spacer />
      {category && <RelatedProducts category={category} />}
      <DialogflowMessenger />
      <Footer />
    </div>
  );
};

export default ProductDisplayPage;
