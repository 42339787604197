import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import PetShoot from '../../components/PetShoot/PetShoot';
import { useNavigate } from 'react-router-dom';
import { useBookingContext } from '../../context/BookingContext'; // Import the BookingContext

const PetPhotographyPage: React.FC = () => {
  const { updateBookingDetails } = useBookingContext();  // Access updateBookingDetails from the context
  const navigate = useNavigate();  // Use navigate for redirection

  const handleChoosePetClick = (
    date: any,
    time: any,
    location: string | null
  ) => {
    // Store the booking data in the context
    updateBookingDetails('date', date ? date.toDate() : null);
    updateBookingDetails('time', time ? time.toDate() : null);
    updateBookingDetails('location', location);

    // Navigate to the PetChoicePage
    navigate('/choose-pet', {
      state: { service: 'Pet Photography' },  // Passing the service type
    });
  };

  return (
    <div>
      <Header />
      <div style={{ padding: '20px' }}>
        <PetShoot onChoosePetClick={handleChoosePetClick} />  
      </div>
      <Footer />
    </div>
  );
};

export default PetPhotographyPage;
