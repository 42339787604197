// DetailsList.jsx

import React from 'react';
import "../../assets/styles/global.css";
import "./DetailsList.css";
import {Link} from "react-router-dom";
import { Typography } from 'antd';
const { Paragraph , Text } = Typography;
const DetailsList = () => {
  return (
    <dl className="details-list details-list--rows">
      <div className="details-list__item di3-padding-y-md">
        <Paragraph className="di3-font-bold di3-margin-bottom-2xs">Address</Paragraph>
        <Text className="di3-line-height-md">
          Nepean, Ottawa<br />
          K2G  <br />
          Ontario, Canada
        </Text>
      </div>

      <div className="details-list__item di3-padding-y-md">
        <Text className="di3-font-bold di3-margin-bottom-2xs">Email</Text>
        <dd>
        <Text strong>
          <Link to="mailto:webmaster@example.com">info@pawpros.com</Link>
        </Text>
        </dd>
      </div>

      <div className="details-list__item di3-padding-y-md">
        <dt className="di3-font-bold di3-margin-bottom-2xs"><Paragraph>Phone</Paragraph></dt>
        <dd className="di3-line-height-md">
          <Text strong><Link to="tel:+44 7656 6455">+1-613-555-5555</Link></Text>
        </dd>
      </div>
    </dl>
  );
};

export default DetailsList;
