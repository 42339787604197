import React, { useState } from 'react';
import { DatePicker, TimePicker, Button, Row, Col, Typography, Radio, Select, InputNumber, Form } from 'antd';
import { Dayjs } from 'dayjs'; // Use Dayjs for date handling
import './TrainPet.css'; // Custom styles for TrainPet

const { Title } = Typography;
const { Option } = Select;

interface TrainPetProps {
  onChoosePetClick: (bookingData: any) => void; // Callback to pass booking data
}

const TrainPet: React.FC<TrainPetProps> = ({ onChoosePetClick }) => {
  const [trainingType, setTrainingType] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(null);
  const [dayOfWeek, setDayOfWeek] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [pickupDate, setPickupDate] = useState<Dayjs | null>(null);
  const [pickupTime, setPickupTime] = useState<Dayjs | null>(null);
  const [recurrence, setRecurrence] = useState<number | null>(null);
  const [recurrenceDates, setRecurrenceDates] = useState<string[]>([]);

  const handleTrainingTypeChange = (e: any) => {
    setTrainingType(e.target.value);
  };

  const handleRecurrenceChange = (value: number | null) => {
    setRecurrence(value);
    if (value) {
      generateRecurrenceDates(value);
    }
  };

  const generateRecurrenceDates = (recurrence: number) => {
    if (!dayOfWeek || !startDate) return;

    const start = startDate.clone();
    const dates = [];
    for (let i = 0; i < recurrence; i++) {
      const nextDate = start.add(i, 'weeks').day(parseInt(dayOfWeek));
      dates.push(nextDate.format('YYYY-MM-DD'));
    }
    setRecurrenceDates(dates);
  };

  const handleChoosePetClick = () => {
    // Prepare booking data depending on the training type
    const bookingData = {
      trainingType,
      selectedDate,
      selectedTime,
      startDate,
      dayOfWeek,
      recurrence,
      pickupDate,
      pickupTime,
      recurrenceDates,
    };
    onChoosePetClick(bookingData); // Pass booking data to the parent component
  };

  const isButtonDisabled = !(
    (trainingType === 'one-time' && selectedDate && selectedTime) ||
    (trainingType === 'recurring' && startDate && dayOfWeek && recurrence && selectedTime) ||
    (trainingType === 'time-period' && startDate && selectedTime && pickupDate && pickupTime)
  );

  return (
    <div className="train-pet-container">
      <Title level={3} className="train-pet-title">
        Schedule Pet Training Session
      </Title>

      <Form layout="vertical">
        <Title level={4}>Select Training Type</Title>
        <Radio.Group onChange={handleTrainingTypeChange} value={trainingType}>
          <Radio value="one-time">One-Time</Radio>
          <Radio value="recurring">Recurring Schedule</Radio>
          <Radio value="time-period">Over a Time Period</Radio>
        </Radio.Group>

        {trainingType === 'one-time' && (
          <Row gutter={16} style={{ marginTop: '20px' }}>
            <Col span={12}>
              <Form.Item label="Date">
                <DatePicker onChange={(date) => setSelectedDate(date)} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Time">
                <TimePicker use12Hours format="h:mm a" onChange={(time) => setSelectedTime(time)} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        )}

        {trainingType === 'recurring' && (
          <>
            <Row gutter={16} style={{ marginTop: '20px' }}>
              <Col span={12}>
                <Form.Item label="Starting Date">
                  <DatePicker onChange={(date) => setStartDate(date)} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Day of the Week">
                  <Select onChange={(value) => setDayOfWeek(value)} style={{ width: '100%' }}>
                    <Option value="0">Sunday</Option>
                    <Option value="1">Monday</Option>
                    <Option value="2">Tuesday</Option>
                    <Option value="3">Wednesday</Option>
                    <Option value="4">Thursday</Option>
                    <Option value="5">Friday</Option>
                    <Option value="6">Saturday</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '20px' }}>
              <Col span={12}>
                <Form.Item label="Time">
                  <TimePicker use12Hours format="h:mm a" onChange={(time) => setSelectedTime(time)} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Number of Recurrences">
                  <InputNumber min={1} max={10} onChange={handleRecurrenceChange} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            {recurrenceDates.length > 0 && (
              <div>
                <Title level={4}>Recurring Dates</Title>
                <ul className="train-pet-list">
                  {recurrenceDates.map((date, index) => (
                    <li key={index} className="train-pet-list-item">{date}</li>
                  ))}
                </ul>
              </div>
            )}
          </>
        )}

        {trainingType === 'time-period' && (
          <>
            <Row gutter={16} style={{ marginTop: '20px' }}>
              <Col span={12}>
                <Form.Item label="Drop-off Date">
                  <DatePicker onChange={(date) => setStartDate(date)} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Drop-off Time">
                  <TimePicker use12Hours format="h:mm a" onChange={(time) => setSelectedTime(time)} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '20px' }}>
              <Col span={12}>
                <Form.Item label="Pick-up Date">
                  <DatePicker onChange={(date) => setPickupDate(date)} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Pick-up Time">
                  <TimePicker use12Hours format="h:mm a" onChange={(time) => setPickupTime(time)} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        <Button
          type="primary"
          block
          size="large"
          disabled={isButtonDisabled}
          onClick={handleChoosePetClick}
          style={{ marginTop: '20px' }}
        >
          Choose Pet
        </Button>
      </Form>
    </div>
  );
};

export default TrainPet;
