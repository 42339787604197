import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import PetTransport from '../../components/PetTransport/PetTransport'; // Import the component

const PetTransportationPage: React.FC = () => {
  return (
    <div className="pet-transportation-page">
      <Header />
      <PetTransport />
      <Footer />
    </div>
  );
};

export default PetTransportationPage;
